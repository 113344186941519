import { useEffect, useContext } from 'react';
import appInsights from '../components/shared/appInsights';
import { UserContext } from '../components/shared/useUser';
import { podClient } from '../app/services/api/podClient';

const usePageTimeTracker = (componentName: string) => {
  const { selectedStore, user } = useContext(UserContext);

  useEffect(() => {
    const startTimestamp = Date.now();

    const trackPageTime = async () => {
      if (selectedStore?.storeNumber && user?.username) {
        const podConfigResponse = await podClient.getStoreConfiguration(
          `${selectedStore.storeNumber}`,
          selectedStore.storeNumber,
        );

        const endTimestamp = Date.now();

        appInsights.trackEvent({
          name: 'PageTimeTracking',
          properties: {
            component: componentName,
            username: user.username,
            startTimestamp: new Date(startTimestamp).toISOString(),
            endTimestamp: new Date(endTimestamp).toISOString(),
            duration: endTimestamp - startTimestamp,
            storeNumber: selectedStore.storeNumber,
            isPodEnabled: podConfigResponse.isPodEnabled,
          },
        });
      }
    };

    return () => {
      trackPageTime();
    };
  }, [selectedStore?.storeNumber, user?.username, componentName]);
};

export default usePageTimeTracker;