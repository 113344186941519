interface EnvironmentConfig {
  apiBaseUrl: string;
  ewsCompanyId: string;
  ewsApiKey: string;
  auth0BaseUrl: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0dConnectionName: string;
  auth0AuthoriseBaseUrl: string;
  auth0LogoutBaseUrl: string;
}
export interface AppInsightsInfo {
  applicationId: string;
  instrumentationKey: string;
}

const commonConfig = {
  auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  apiBaseUrl: '/api',
};

const sharedTestConfig: EnvironmentConfig = {
  ...commonConfig,
  ewsCompanyId: '12878',
  ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
  auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
  auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
  auth0dConnectionName: 'Azure-AD-Test',
  auth0AuthoriseBaseUrl: 'https://easywebstore-test.eu.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://easywebstore-test.eu.auth0.com/v2/logout',
};

const uatLacEnvironment: EnvironmentConfig = {
  ...sharedTestConfig,
  ewsCompanyId: '13479',
  ewsApiKey: 'e2850a69-35d2-4b01-91bf-461749d7c02a',
  auth0BaseUrl: 'https://orderly-inventory-us.us.auth0.com/oauth',
  auth0ClientId: 'Ytd5WFuna0qJortL1kFU96qzrzfN4GH6',
  auth0AuthoriseBaseUrl: 'https://orderly-inventory-us.us.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://orderly-inventory-us.us.auth0.com/v2/logout',
};

const prodEnvironment: EnvironmentConfig = {
  ...commonConfig,
  ewsCompanyId: '13190',
  ewsApiKey: '86da15a9-19be-4380-bc3e-ef718ad20230',
  auth0BaseUrl: 'https://easywebstore-prod.eu.auth0.com/oauth',
  auth0ClientId: '8wPKByOptTCVoSTGCsMr6XfrdDl1uhbt',
  auth0dConnectionName: 'Azure-AD-Prod',
  auth0AuthoriseBaseUrl: 'https://easywebstore-prod.eu.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://easywebstore-prod.eu.auth0.com/v2/logout',
};

const environmentMapping: { [hostname: string]: EnvironmentConfig } = {
  'localhost': sharedTestConfig,
  'sscm-test-emea-web.azurewebsites.net': sharedTestConfig,
  'sscm-test-emea-web-v2.azurewebsites.net': sharedTestConfig,
  'orderly-sscm-uat-emea-app.azurewebsites.net': sharedTestConfig,
  'app-uat.orderly.io': sharedTestConfig,
  'sds-uat.orderly.io': sharedTestConfig,
  'sds.orderly.io': prodEnvironment,
  'sbux-sscm-prod-emea-app.azurewebsites.net': prodEnvironment,
  'orderly-sscm-uat-lac-app-ctekfjb7g2d5dcf7.westus2-01.azurewebsites.net': uatLacEnvironment,
  'orderly-sscm-uat-lac.orderly.io': uatLacEnvironment,
  'sbux-sscm-uat-lac-app-gpdbbyfkctc6g9ez.westus2-01.azurewebsites.net': uatLacEnvironment,
  'sds-starbucks-uat-lac.orderly.io': uatLacEnvironment,
  'app.orderly.io': prodEnvironment,
};
const appInsightsInsKeyMapping: {[hostname:string]:AppInsightsInfo}={
  'localhost': { instrumentationKey:'5ccd9011-336f-4692-b141-2a63b80f93f1', applicationId: '7ece1fb7-5e50-484a-a75e-eba166236fac' },
  'sscm-test-emea-web.azurewebsites.net': { instrumentationKey: '5ccd9011-336f-4692-b141-2a63b80f93f1', applicationId: '7ece1fb7-5e50-484a-a75e-eba166236fac' },
  'sscm-test-emea-web-v2.azurewebsites.net': { instrumentationKey: '5ccd9011-336f-4692-b141-2a63b80f93f1', applicationId:'7ece1fb7-5e50-484a-a75e-eba166236fac' },
  'orderly-sscm-uat-emea-app.azurewebsites.net': { instrumentationKey:'2c428800-e9de-45a0-b0ec-5a4d20b54896', applicationId:'9d19bf9b-4b2b-4944-972d-f39fb5eed62d' },
  'app-uat.orderly.io': { instrumentationKey: '2c428800-e9de-45a0-b0ec-5a4d20b54896', applicationId: '9d19bf9b-4b2b-4944-972d-f39fb5eed62d' },
  'sds-uat.orderly.io': { instrumentationKey: 'c083add6-ed25-421d-9a8d-cbbefdcd90e5', applicationId:'8f962c67-1524-4822-be52-a5d61ba07456' },
  'sds.orderly.io': { instrumentationKey: '028d8bf7-da33-489d-af5b-a9481c1b34b3', applicationId:'75330a88-1c3c-4db6-b1a0-bdc4b8106a9e' },
  'sbux-sscm-prod-emea-app.azurewebsites.net': { instrumentationKey:'028d8bf7-da33-489d-af5b-a9481c1b34b3', applicationId:'75330a88-1c3c-4db6-b1a0-bdc4b8106a9e' },
  'orderly-sscm-uat-lac-app-ctekfjb7g2d5dcf7.westus2-01.azurewebsites.net': { instrumentationKey:'a7bebf41-a7a7-4e70-bada-7adf0df93506', applicationId:'d5f8b513-1cc3-449d-a6e6-e85f92dff68f' },
  'orderly-sscm-uat-lac.orderly.io': { instrumentationKey: 'a7bebf41-a7a7-4e70-bada-7adf0df93506', applicationId: 'd5f8b513-1cc3-449d-a6e6-e85f92dff68f' },
  'sbux-sscm-uat-lac-app-gpdbbyfkctc6g9ez.westus2-01.azurewebsites.net': { instrumentationKey:'f5c1c365-8cb6-411d-a070-65d7aaebbc26', applicationId:'67e6daa4-4a01-439e-bfbb-de851eb37e56' },
  'sds-starbucks-uat-lac.orderly.io': { instrumentationKey: 'f5c1c365-8cb6-411d-a070-65d7aaebbc26', applicationId: '67e6daa4-4a01-439e-bfbb-de851eb37e56' },
  'app.orderly.io': { instrumentationKey: '322ee9f4-f3a5-477b-9877-2d5766e5df7d', applicationId:'d73e2734-bb44-4d99-a85d-5861ce9f48ef' },
};
export const getApiConfig = (): EnvironmentConfig => {
  const hostname = window.location.hostname;
  return environmentMapping[hostname] ?? sharedTestConfig;
};  
export const getAppInsightsInfo = (): AppInsightsInfo => {
  const hostname = window.location.hostname;
  return appInsightsInsKeyMapping[hostname];
};