import { useContext, useEffect, useState } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { IconButton, Grid, FormControl, Select, MenuItem, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, OutlinedInput, InputAdornment, SvgIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../../../theme';
import useCurrencyCodeConfiguration from '../../../../../hooks/configurations/useCurrencyCodeConfiguration';
import { getCurrencySymbol, roundToSignificantDigit } from '../../../../../utils';

export const CreateTransactionsItemsTable = () => {
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const { hasPermissionToChangePrice,
    quantityIsValid,
    uomIsValid,
    handleCostChange,
    handleQuantityChange,
    handleReasonChange,
    handleUomChange,
    deleteButtonHandler,
    reasonsToShow,
    transactionType,
    itemsToCreate,
    itemUoms,
    itemPossibleUOMs,
    itemQuantities,
    itemCosts,
    itemReasons,
    isDisableActions,
    handleCostFeildSelected,
    handleCostFeildUnselected,
    t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;
  const [defaultValueSet, setDefaultValueSet] = useState(false);

  useEffect(() => {
    
    setDefaultValueSet(true); 

    getCurrencyCodeConfiguration();

  }, []);

  const bodyStyle = {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.custom.gray[200],
    px: '12px',
  };
  
  return (
    <>
      {itemsToCreate.length > 0 && <Grid item
        xs={12}>
        <TableContainer>
          <Table
            size="small"
            aria-label="a table"
            sx={{
              borderBottom: '1px solid',
              borderTop: '1px solid',
              borderColor: theme.palette.custom.gray[200],
              width: '100%',
            }}
          >
            <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('itemTable.sku')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('itemTable.itemDescription')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('itemTable.uom')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('itemTable.quantity')}
                </TableCell>
                {transactionType?.direction == 'receive' && <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('itemTable.price')}
                </TableCell>}
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                  {t('itemTable.reason')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}
                  align="left">
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsToCreate.map((row) => (
                <TableRow
                  key={row.id ?? -1}
                  sx={{ backgroundColor: theme.palette.custom.gray[50], '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    {row.itemNumber}
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    {row.description}
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <FormControl fullWidth
                      size='small'>
                      <Select
                        labelId="transaction-uom-label"
                        id="transaction-uom-select"
                        error={!uomIsValid(row.id)}
                        disabled={isDisableActions}
                        value={defaultValueSet ? row.uom : itemUoms.find(x => x.id === row.id)?.uom ?? '' }
                        onChange={(event) => {
                          setDefaultValueSet(false); 
                          handleUomChange(row.id ?? -1)(event); 
                        }}                      >
                        <MenuItem value=''>
                          <em>{t('none')}</em>
                        </MenuItem>
                        {itemPossibleUOMs.find(x => x.id == row.id)?.uoms.map((uom) => {
                          return <MenuItem key={uom.code}
                            value={uom.code}>{uom.code}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <FormControl
                      size='small'
                      sx={{ width: '80px' }}>
                      <TextField id="outlined-basic"
                        type='number'
                        size='small'
                        error={!quantityIsValid(row.id)}
                        disabled={isDisableActions}
                        value={itemQuantities.find(x => x.id == row.id)?.quantity}
                        onChange={handleQuantityChange(row.id ?? -1)}
                        inputProps={{ step: 1 }} />
                    </FormControl>
                  </TableCell>
                  {transactionType?.direction == 'receive' && <TableCell scope="row"
                    sx={bodyStyle}>
                    <FormControl
                      size='small'
                      sx={{ width: '120px' }}>
                      <OutlinedInput id="outlined-basic"
                        size='small'
                        type='number'
                        disabled={!hasPermissionToChangePrice || isDisableActions}
                        value={roundToSignificantDigit(itemCosts.find(x => x.id == row.id)?.cost ?? 0)}
                        onSelect={handleCostFeildSelected}
                        onBlur={handleCostFeildUnselected}
                        onChange={handleCostChange(row.id ?? -1)}
                        startAdornment={<InputAdornment position="start">{currencyCode && getCurrencySymbol(currencyCode)}</InputAdornment>} />
                    </FormControl>
                  </TableCell>}
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <FormControl fullWidth
                      size='small'>
                      <Select
                        labelId="transaction-reasons-label"
                        id="transaction-reasons-select"
                        disabled={isDisableActions}
                        value={(itemReasons.length > 0 && itemReasons.find(x => x.id === row.id)?.reason?.id?.toString()) || ''}
                        onChange={handleReasonChange(row.id ?? -1)}
                      >
                      
                        {reasonsToShow().map((reason) => {
                          return <MenuItem key={reason.id}
                            value={reason.id}>{reason.reason}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell scope="row"
                    sx={bodyStyle}>
                    <IconButton
                      size='small'
                      disabled={isDisableActions}
                      onClick={(event) => deleteButtonHandler(event, row.id ?? -1)}>
                      <SvgIcon color='error'><CloseIcon /></SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>}
    </>
  );
};