import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Grid, SvgIcon, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { GetTransfersResponse } from '../../../app/services/api/generated';
import { useNavigate } from 'react-router-dom';
import { formatDateString } from '../../../utils';
import { ArrowLeft, ArrowRight, Check, X } from '../../../assets';
import { FC, useEffect, useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import { StockTransfersTab } from '../enums/StockTransfersTab';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';

type Props = {
  list: GetTransfersResponse;
  setPageZero: number;
  activeTab: StockTransfersTab;
  loadData: (sort: string, direction: string, skip: number, take: number) => void;
}

const StockTransfersTempTable: FC<Props> = ({ list, setPageZero, activeTab, loadData }) => {
  const { t } = useTranslation('stockTransfers');
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [data, setData] = useState(list.transfers);
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const rowsPerPage = 10;
  const maxPages = list?.totalCount
    ? Math.max(1, Math.ceil(list.totalCount / rowsPerPage))
    : 1;
  //Header sort
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('transferDate');
  const [loading, setLoading] = React.useState(false);

  const isAsc = order === 'asc';

  const handleSort = (property: string) => {
    const order = isAsc ? 'desc' : 'asc';
    setOrder(order);
    setOrderBy(property);
    loadData(property, order, page * rowsPerPage, rowsPerPage);
  };

  useEffect(() => {
    setData(list.transfers);
    setLoading(false);
    getCurrencyCodeConfiguration();
  }, [list]);

  useEffect(()=>{
    setPage(0);
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    loadData(orderBy, order, page * rowsPerPage, rowsPerPage);
  }, [page]);

  useEffect(() => {
    setPage(0);
  }, [setPageZero]);
  
  return (
    <> {loading ? <LoadingWheel/> : (
      data &&
        <>
          <TableContainer>
            <Table>
              <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'id'}
                      direction={order}
                      onClick={() => handleSort('id')}
                    >
                      {t('table.header.transfer_number')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'storeName'}
                      direction={order}
                      onClick={() => handleSort('storeName')}
                    >
                      {t('table.header.store')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'numberOfItems'}
                      direction={order}
                      onClick={() => handleSort('numberOfItems')}
                    >
                      {t('table.header.number_items')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'requestedValue'}
                      direction={order}
                      onClick={() => handleSort('requestedValue')}
                    >
                      {t('table.header.req_value')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'receivedValue'}
                      direction={order}
                      onClick={() => handleSort('receivedValue')}
                    >
                      {t('table.header.received_value')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'transferType'}
                      direction={order}
                      onClick={() => handleSort('transferType')}
                    >
                      {t('table.header.type')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'transferDate'}
                      direction={order}
                      onClick={() => handleSort('transferDate')}
                    >
                      {t('table.header.requested_date')}
                    </TableSortLabel>
                  </TableCell>
                  {
                    activeTab === StockTransfersTab.ARCHIVED &&
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'acceptedDate'}
                      direction={order}
                      onClick={() => handleSort('acceptedDate')}
                    >
                      {t('table.header.accepted_date')}
                    </TableSortLabel>
                  </TableCell>
                  }            
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    onClick={() => navigate(`/store/stock-transfer/${row.id}`)}
                  >
                    <TableCell>                 
                      {activeTab === StockTransfersTab.ARCHIVED && row.isDeleted &&
                      <Grid container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                          <Tooltip title={t('table.deletedTooltip')}>
                            <SvgIcon sx={{ mr: '5px' }}> <X color={theme.palette.error[500]} /> </SvgIcon>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          {row.id}
                        </Grid>
                      </Grid> 
                      }
                      {activeTab === StockTransfersTab.ARCHIVED && !row.isDeleted &&
                      <Grid container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                          <Tooltip title={t('table.approvedTooltip')}>
                            <SvgIcon sx={{ mr: '5px' }}> <Check color={theme.palette.primary[500]} /> </SvgIcon>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          {row.id}
                        </Grid>
                      </Grid>
                      }
                      {activeTab === StockTransfersTab.ACTIVE && row.id}
                    </TableCell>
                    <TableCell>
                      {row.storeName}
                    </TableCell>
                    <TableCell>
                      {row.numberOfItems}
                    </TableCell>
                    <TableCell>
                      {<RoundAndFormatValue value={row.requestedValue ?? 0}
                        currencyCode={currencyCode} />}
                    </TableCell>
                    <TableCell>
                      {row.receivedValue !== null &&
                      <RoundAndFormatValue value={row.receivedValue  ?? 0}
                        currencyCode={currencyCode} />}
                    </TableCell>
                    <TableCell>  
                      {row.transferType === 'Incoming' &&
                      <Grid container
                        wrap="nowrap"
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                          <Tooltip title={t('table.incomingTooltip')}>
                            <SvgIcon color='success'
                              sx={{ mr: '5px' }}> <ArrowLeft /> </SvgIcon>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          {t('table.incomingTooltip')}
                        </Grid>
                      </Grid>                           
                      }
                      {row.transferType === 'Outgoing' &&
                      <Grid container
                        wrap="nowrap"
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                          <Tooltip title={t('table.outgoingTooltip')}>
                            <SvgIcon color='warning'
                              sx={{ mr: '5px' }}> <ArrowRight /> </SvgIcon>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          {t('table.outgoingTooltip')}
                        </Grid>
                      </Grid> 
                      }            
                    </TableCell>
                    <TableCell>
                      {row.transferDate && formatDateString(row.transferDate)}
                    </TableCell>
                    {activeTab === StockTransfersTab.ARCHIVED &&
                    <TableCell>
                      {row.acceptedDate && formatDateString(row.acceptedDate)}
                    </TableCell>
                    }                
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {list?.totalCount === 0 ? (
            <Typography variant="textSM">
              {t('table.no_transfers')}
            </Typography>
          ) : (
            <CustomPagination
              page={page}
              setPage={setPage}
              maxPages={maxPages}
              breakpointForChangeDisplay={120000}
            />
          )}
        </>
    )}
    </>
  );
};

export default StockTransfersTempTable;
