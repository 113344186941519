/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { ReceiptCheck, SwitchHorizontal01, ClipboardCheck, ShoppingCart01, File06 } from '../../../assets';
import { Card, CardHeader, CardContent, List, ListItem, ListItemText, Typography, Divider, SvgIcon, Alert, Chip, Box, useMediaQuery } from '@mui/material';
import { theme } from '../../../theme';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { getFormattedDate, truncateText } from '../../../utils';
import { InventoryTask } from '../../../app/services/api/generated';
import { TaskType } from '../../../enums/TaskType';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import CustomPagination from '../../../components/forms/Pagination';
import { client, Order } from '../../../app/services/api/orderManagementClient';
import { useNavigate } from 'react-router-dom';
import useLogError from '../../../hooks/useLogError';
import { Pill } from '../../../components/shared/Pill';
import { alseaLACFranchises } from '../../../enums/FranchiseNames';
import { UserContext } from '../../../components/shared/useUser';

const DailyTasks = (props: { storeNumber: string | undefined }) => {
  const { selectedStore } = useContext(UserContext);

  const [page, setPage] = React.useState(0);
  const [visibleTasks, setVisibleTasks] = useState<(Order | InventoryTask)[]>([]);
  const [orderData, setOrderData] = useState<Order[]>([]);
  const [partTaskData, setPartTaskData] = useState<InventoryTask[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOrderError, setIsOrderError] = useState<boolean>(false);
  const [isPartTaskError, setIsPartTaskError] = useState<boolean>(false);
  const { logError } = useLogError();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const isAlseaLACFranchise = alseaLACFranchises.includes(selectedStore?.franchiseName ?? '');

  const { getTasks } = useNswagClient();

  const rowsPerPage = 5;

  const tasksData: (Order | InventoryTask)[] = [...orderData, ...partTaskData];

  const itemDisplay = (index: number, id: number, primaryText: string, secondaryText: React.ReactNode, handleReviewClick: (key: string | undefined, resourceId: number | undefined) => () => void, icon: JSX.Element, taskKey: string) => {
    return <React.Fragment key={`item-${index}`}>
      <ListItem sx={{ alignItems: 'flex-start center', p: '16px 0', height: '72px', cursor: 'pointer' }}>
        <div
          style={{
            width: '38px',
            height: '38px',
            border: `1px solid ${theme.palette.custom.gray[200]}`,
            borderRadius: '5px',
            marginRight: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
  
          }}
        >
          {icon}
        </div>
        <ListItemText
          onClick={handleReviewClick(taskKey, id)}
          sx={{ mt: 0, mr: 5 }}
          primary={<Typography variant="textSM"
            mt='0px'>{primaryText}</Typography>}
          secondary={<>
            {secondaryText}
          </>} />
        <Typography variant="textSM"
          fontWeight={600}
          textAlign={'center'}
          sx={{
            '&:hover': {
              color: 'grey',
            },
          }}
          onClick={handleReviewClick(taskKey, id)}>
          {t('dailyTasks.review')}
        </Typography>
      </ListItem>
      <Divider variant="inset"
        component="li"
        sx={{
          borderBottom: `1px solid ${theme.palette.custom.gray[200]}`,
          ml: 0,
        }} />
    </React.Fragment>;
  };
  
  const orderItem = (showSupplierName: boolean, index: number, order: Order, handleReviewClick: (key: string | undefined, resourceId: number | undefined) => () => void, isMobile: boolean) => {
    let primaryText;
    if (showSupplierName && order.supplierName!==null) {
      primaryText = truncateText(t('dailyTasks.order') + ': ' + t('dailyTasks.review') + ' ' + order.orderType + '-' + order.supplierName + ' ' + t('dailyTasks.orderPriorToCutoff') + ' - ', isMobile ? 28 : 100);
    }
    else{
      primaryText = truncateText(t('dailyTasks.order') + ': ' + t('dailyTasks.review') + ' ' + order.orderType + ' ' + t('dailyTasks.orderPriorToCutoff'), isMobile ? 28 : 50);
    }
    const cuttOffTIme = order.cutOffTime ?? '';
    
    const secondaryText = <>{t('due') + ': ' + order.cutOffDate + ' ' + cuttOffTIme}</>;
    const icon = <SvgIcon sx={{ p: 0, height: '100%' }}><ShoppingCart01 /></SvgIcon>;
  
    return itemDisplay(index, order.id, primaryText, secondaryText, handleReviewClick, icon, 'order');
  };

  useEffect(() => {
    setLoading(true);

    const filters = {
      orderNumber: '',
      item: '',
      orderType: -1,
      status: -1,
      deliveryDateFrom: new Date(Date.now() - 604800000).toISOString(),
      deliveryDateTo: new Date(Date.now() + 604800000).toISOString(),
      storeNumber: props.storeNumber,
      pageNumber: 1,
      pageSize: 1000000,
      sort: 'OrderNumber',
      direction: 'desc',
    };
    const promiseOrders = client.getOrders(filters);
    const promiseTasks = getTasks(props.storeNumber);

    promiseOrders.then((ordersResult) => {
      const orders = filterOrders(ordersResult.data.items);
      setOrderData(orders);
    }).catch((error) => {
      logError('Error fetching orders:' + error);
      setIsOrderError(true);
    }).finally(() => {
      setLoading(false);
    });

    promiseTasks.then((tasksResult) => {
      const tasks = tasksResult?.data?.tasks ?? [];
      setPartTaskData(tasks);
    }).catch((error) => {
      logError('Error fetching tasks:' + error);
      setIsPartTaskError(true);
    }).finally(() => {
      setLoading(false);
    });

  }, [props.storeNumber]);

  useEffect(() => {
    setVisibleTasks(tasksData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? []);
  }, [page, partTaskData, orderData]);

  const maxPages = () => Math.ceil(tasksData.length / rowsPerPage);

  const filterOrders = (orders: Order[]) => {
    const dateNow = new Date();
    const startOfDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0);
    const endOfDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 23, 59, 59);

    return orders.filter((order) => {
      const orderDate = getDateFromDateAndTime(order.cutOffDate, order.cutOffTime);
      return startOfDay <= orderDate && orderDate <= endOfDay && (order.statusId === 0 || order.statusId === 1);
    });
  };

  const getDateFromDateAndTime = (cutOffDate: string, cutOffTime: string) => {
    const [day, month, year] = cutOffDate.split('-').map(Number);
    const [hour, minute] = cutOffTime ? cutOffTime.split(':').map(Number) : [0, 0];
   
    let adjustedDay = day;
    if (!cutOffTime) {
      adjustedDay -= 1;
    }
    return new Date(year, month - 1, adjustedDay, hour, minute, 0);
  };

  const getTasksText = () => {
    const taskLabel = tasksData.length == 1 ? t('dailyTasks.task') : t('dailyTasks.tasks');
    return `${tasksData.length.toString()} ${taskLabel}`;
  };

  const handleReviewClick = (key: string | undefined, resourceId: number | undefined) => () => {
    switch (key) {
    case TaskType.Transfer:
      navigate('/store/stock-transfer/' + String(resourceId));
      break;
    case TaskType.StockCount:
      navigate('/store/perform-stock-count/' + String(resourceId));
      break;
    case TaskType.PurchaseOrder:
      navigate('/create-po-receipt/' + String(resourceId));
      break;
    case 'order':
      navigate('/store/order/' + String(resourceId));
      break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function instanceOfInventoryTask(object: any): object is InventoryTask {
    return 'taskType' in object;
  }

  const renderCardContent = () => {
    if (loading) {
      return <LoadingWheel />;
    }

    if (isOrderError && isPartTaskError) {
      return <Alert severity='error'>{t('dailyTasks.errorFetchingTasks')}</Alert>;
    }

    if (visibleTasks.length > 0) {
      return (
        <List sx={{ width: '100%', bgcolor: theme.palette.common.white }}>
          {visibleTasks.map((task, index) => (
            instanceOfInventoryTask(task) ? taskItem(isAlseaLACFranchise, index, task, handleReviewClick) : orderItem(isAlseaLACFranchise, index, task, handleReviewClick, isMobile)
          ))}
        </List>
      );
    }
    return (
      <Typography variant="textSM">
        {t('noAvailableTasks')}
      </Typography>
    );
  };

  const taskItem = (showSupplierName: boolean, index: number, task: InventoryTask, handleReviewClick: (key: string | undefined, resourceId: number | undefined) => () => void) => {
    const getTaskIcon = (key: string | undefined) => {
      switch (key) {
      case TaskType.Transfer:
        return <SvgIcon sx={{ p: 0, height: '100%' }}><SwitchHorizontal01 /></SvgIcon>;
      case TaskType.StockCount:
        return <SvgIcon sx={{ p: 0, height: '100%' }}><ClipboardCheck /></SvgIcon>;
      case TaskType.PurchaseOrder:
        return <SvgIcon sx={{ p: 0, height: '100%' }}><ReceiptCheck /></SvgIcon>;
      default:
        return <SvgIcon sx={{ p: 0, height: '100%' }}><File06 /></SvgIcon>;
      }
    };
    let primaryText: string;
    if (task.taskType?.key == TaskType.PurchaseOrder) {
      // The response of the Purchase Orders comes with the order number and order type, we don't need the order number so we used this to only get the order type of the task 
      const orderTypeMatch = task.description?.match(/Order Type:\s*([^,]*)/);
      const orderType = orderTypeMatch ? orderTypeMatch[1].trim() : '';
      // Extract the Order Vendor from the description
      const vendorMatch = task.description?.match(/Order Vendor:\s*(.*?)(,|$)/);
      const vendor = vendorMatch ? vendorMatch[1] : null;

      // Format the primaryText as "Purchase Order: Receive <Order Type> - <Vendor>"
      const taskTypeValue = task.taskType?.value ?? '';
      if(showSupplierName && vendor!==null){
        primaryText = truncateText(
          `${taskTypeValue}: ${t('dailyTasks.receive')} ${orderType} - ${vendor}`,
          isMobile ? 25 : 100);

      }
      else{
        primaryText = truncateText(`${taskTypeValue}: ${t('dailyTasks.receive')} ${orderType}`, isMobile ? 25 : 50);
      }
      
    }

    if (task.taskType?.key == TaskType.Transfer) {
      //We used this to only take the title and not display the date as on current IM system
      const formattedDescription = task.description?.replace(/^\d{2}\/\d{2}\/\d{4}\s*/, '');
      const formattedText = `${task.taskType?.value}: ${formattedDescription}`;
      primaryText = truncateText(formattedText, isMobile ? 25 : 50);
    }
    if (task.taskType?.key == TaskType.StockCount) {
      primaryText = truncateText(task.taskType?.value + ': ' + task.description, isMobile ? 25 : 50);
    }

    let color: 'error' | 'warning' | 'info' | 'primary' | 'success';
    if (task.percentageComplete === 25) {
      color = 'warning';
    } else if (task.percentageComplete === 50) {
      color = 'info';
    } else if (task.percentageComplete === 75) {
      color = 'primary';
    } else {
      color = 'error';
    }

    const subText = <>
      {<Box sx={{ mr: 2 }}>
        {t('due')} :{getFormattedDate(new Date(task.startDate ?? 0))}
      </Box>
      }
      {task.taskType?.key === TaskType.StockCount &&
        <Chip label={`${task.percentageComplete}% ${t('complete')}`}
          size="small"
          color={color}
          variant='outlined' />}
    </>;
    const icon = getTaskIcon(task.taskType?.key);

    return itemDisplay(index, task.resourceId ?? -1, primaryText!, subText, handleReviewClick, icon, task.taskType?.key ?? '');
  };

  return (
    <Card sx={{
      background: theme.palette.common.white,
      borderRadius: '12px',
      border: `1px solid ${theme.palette.custom.gray[200]}`,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    }}>
      <CardHeader
        sx={{
          p: '24px',
          pb: '16px',
          textAlign: 'left',
        }}
        title={
          <Typography
            variant='textLG'
            sx={{
              fontWeight: 700,
              color: theme.palette.custom.gray[900],
            }}
          >
            {t('dailyTasks.yourTasks')}
          </Typography>
        }
        subheader={
          <Pill text={getTasksText()} />
        }
        subheaderTypographyProps={{ display: 'inline-flex' }}
      />

      <CardContent sx={{ px: '24px', py: '0px' }}>
        {renderCardContent()}
      </CardContent>

      {visibleTasks.length > 0 && (
        <div style={{ padding: '7px 8px' }}>
          <CustomPagination
            page={page}
            setPage={setPage}
            maxPages={maxPages()}
            breakpointForChangeDisplay={120000}
          ></CustomPagination>
        </div>
      )}

      {isOrderError && <Alert sx={{ mx: '12px', mb: '12px' }}
        severity='warning'>{t('dailyTasks.errorOnOrdersButNotTasks')}</Alert>}

      {isPartTaskError && <Alert sx={{ mx: '12px', mb: '12px' }}
        severity='warning'>{t('dailyTasks.errorOnTasksButNotOrders')}</Alert>}
    </Card>
  );
};

export default DailyTasks;


