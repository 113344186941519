import { Grid, FormControl, TextField, Button, SvgIcon } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from '../../../theme';
import { SearchSm } from '../../../assets';
import { useContext } from 'react';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';
import { InvoiceTable } from './InvoiceTable';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';

export const InvoiceBox = () => {
  
  const { t, invoiceNumber, handleInvoiceNumberChange, setEndDate, orderNumber, handleClearAllClick, handleOrderNumberChange, startDate, endDate, loadData, setStartDate } = useContext(InvoicesContext) as CreateInvoicesValues;

  return <Box sx={{
    border: '1px solid',
    borderColor: theme.palette.custom.gray[300],
    py: '0px',
    textAlign: 'left',
  }} >
    <Grid container
      spacing={5}
      sx={{
        py: '12px',
        px: '8px',
      }} >
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <TextField label={t('invoiceNumber')}
            value={invoiceNumber}
            onChange={handleInvoiceNumberChange}/>
        </FormControl>
      </Grid>
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <TextField label={t('orderNumber')}
            value={orderNumber}
            onChange={handleOrderNumberChange}/>
        </FormControl>
      </Grid>
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <DatePickerLocalized
            useCalendar = {false} 
            label={t('startDate')}
            value={startDate}
            translations={{
              clearButtonLabel: t('calendar.clearButtonLabel'),
              todayButtonLabel: t('calendar.todayButtonLabel'),
              cancelButtonLabel: t('calendar.cancelButtonLabel'),
              okButtonLabel: t('calendar.okButtonLabel'), 
              placeholder: t('calendar.placeholder'),
            }}
            onChange={(newValue) => {
              if (newValue === '') {
                setStartDate('');  
              } else if (newValue) { 
                setStartDate(newValue);  
              }   
            }}  
            shouldDisableDate={(date) => { 
              const maxDate = endDate !== '' ? endDate : true;
              return date > maxDate;
            }}
          />  
        </FormControl>
      </Grid>
      <Grid item
        md={3}
        xs={6}>
        <FormControl fullWidth>
          <DatePickerLocalized
            useCalendar = {false} 
            label={t('endDate')}
            value={endDate}
            translations={{
              clearButtonLabel: t('calendar.clearButtonLabel'),
              todayButtonLabel: t('calendar.todayButtonLabel'),
              cancelButtonLabel: t('calendar.cancelButtonLabel'),
              okButtonLabel: t('calendar.okButtonLabel'), 
              placeholder: t('calendar.placeholder'),
            }}
            onChange={(newValue) => {
              if (newValue === '') {
                setEndDate('');  
              } else if (newValue) { 
                setEndDate(newValue);  
              }   
            }}  
            shouldDisableDate={(date) => { 
              const minDate = startDate !== '' ? startDate : true;
              return date < minDate;
            }}
          />  
        </FormControl>
      </Grid>
      <Grid item
        xs={6}>
        <Button
          fullWidth
          variant="secondary"
          size="lg"
          onClick={handleClearAllClick}
        >
          {t('clearAll')}
        </Button>
      </Grid>
      <Grid item
        xs={6}>
        <Button
          fullWidth
          variant="primary"
          size="lg"
          startIcon={<SvgIcon> <SearchSm/> </SvgIcon>}
          onClick={loadData}
        >
          {t('search')}
        </Button>
      </Grid>
    </Grid>
    <InvoiceTable></InvoiceTable>
  </Box>;
};