import React, { useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import {
  Button, Grid, TextField, MenuItem, TableContainer, Table,
  TableHead, TableBody, TableRow, TableCell, Typography, Snackbar,
  SvgIcon, InputAdornment, Autocomplete, Divider, Alert, Box, useMediaQuery, FormControlLabel, Switch,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { MagicWand01, SearchSm } from '../../../assets';
import { useNavigate, useBlocker } from 'react-router-dom';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { base64ToBlob, standardizeDateFormat } from '../../../utils';
import { isAutoShipOrder, OrderType as OrderTypeEnum, OrderTypeTranslationMap } from '../../../enums/OrderType';
import { client, Category, OrderType, OrderForm, OrderFormUpdate, Supplier, OrderFormItem, OrderFormItemUpdate, OrderFormItemUpdateDate, OrderFormCategory } from '../../../app/services/api/orderManagementClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';
import ReloadPageModal from '../../../components/shared/dialogs/ReloadPageModal';
import { Action } from '../../../enums/Action';
import PageTitle from '../../../components/shared/PageTitle';
import PageArea from '../../../components/shared/PageArea';
import BackButton from '../../../components/shared/actions/BackButton';
import { PredictedDemandModel, podClient } from './../../../app/services/api/podClient';
import { useTranslation } from 'react-i18next';
import './OrderForm.css';
import useNswagClient from './../../../hooks/api/useNswagClient';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import { starbucksFranchises } from '../../../enums/FranchiseNames';
import { isOrderCategoryParent, OrdersCategoriesParentsMap, isOrderCategory, OrderCategoriesTranslationMap } from '../../../enums/OrderCategories';
import useLogError from '../../../hooks/useLogError';
import { QuantityWarningDialog } from './../../../components/shared/orders/QuantityWarningDialog';
import OrderFormRow from './OrderFormRow';
import usePageTimeTracker from '../../../hooks/usePageTimeTracker';

const OrderFormPage = () => {

  const navigate = useNavigate();
  usePageTimeTracker('OrderFormPage');

  const { selectedStore } = useContext(UserContext);
  const { t } = useTranslation('orderForm');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);
  const INACTIVITY_TIMEOUT = 10000; // 10 seconds
  const isSavingRef = useRef(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState(true);
  const [navigateToIndex, setNavigateToIndex] = useState<boolean>(false);

  const [productSearch, setProductSearch] = useState<string>('');
  const [orderType, setOrderType] = useState<number>(-1);
  const [supplier, setSupplier] = useState<string>('');
  const [category, setCategory] = useState<number>(-1);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('Name');

  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [showPredictedQuantities, setShowPredictedQuantities] = useState<boolean>(true);
  const [predictedDemands, setPredictedDemands] = useState<PredictedDemandModel[]>([]);
  const [orderForm, setOrderForm] = useState<OrderForm>();
  const { getConversions } = useNswagClient();
  const [isPodEnabled, setIsPodEnabled] = useState<boolean>(false);
  const [isExitAndSaveClicked, setIsExitAndSaveClicked] = useState<boolean>(false);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThan1174px = useMediaQuery('(max-width: 1174px)');

  const [showQuantityWarningDialog, setShowQuantityWarningDialog] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');

  const { logError } = useLogError();
  const showImage = !isSmallerThan1174px || isXsScreen;

  const showMessageWhenNoCost = starbucksFranchises.includes(selectedStore?.franchiseName ?? '');
  const [unsavedChangesModal, setUnsavedChangesModal] = useState<{
        open: boolean;
        action: Action;
        actionValue: string;
    }>({
      open: false,
      action: Action.None,
      actionValue: '',
    });

  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [orderFormUpdates, setOrderFormUpdates] = useState<OrderFormUpdate>({
    storeNumber: '',
    orderTypeId: -1,
    supplierNumber: '',
    items: [],
    dates: [],
  });

  const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'error' | 'warning' | 'info' | 'success';
    }>({
      open: false,
      message: '',
      severity: 'info',
    });
  const blocker = useBlocker(() => orderFormUpdates.items.length > 0 && !isExitAndSaveClicked);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, [getCurrencyCodeConfiguration]);

  useEffect(() => {
    loadData();
    loadPodStoreConfiguration();
  }, [selectedStore]);

  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (orderFormUpdates.items.length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [orderFormUpdates.items]);

  useEffect(() => {
    if (navigateToIndex) {
      navigate('/store/orders-history');
      if (blocker?.state === 'blocked') {
        blocker.proceed();
      }
      setNavigateToIndex(false);
    }
  }, [navigateToIndex, blocker, navigate]);

  // If POD is enabled, fetch predictions
  useEffect(() => {
    if (isPodEnabled && orderForm?.availableDates && orderForm.availableDates.length > 0) {
      loadPredictedDemands();
    }
  }, [isPodEnabled, orderForm?.availableDates]);

  useEffect(() => {
    if (inactivityTimer) clearTimeout(inactivityTimer);

    if (orderFormUpdates.items.length === 0) {
      return;
    }

    const timer = setTimeout(() => {
      if (orderFormUpdates.items.length > 0) {
        autosaveItem();
      }
    }, INACTIVITY_TIMEOUT);
    setInactivityTimer(timer);

    return () => clearTimeout(timer);
  }, [orderFormUpdates.items, INACTIVITY_TIMEOUT]);

  // Autosave 
  const autosaveItem = async () => {
    if (orderFormUpdates.items.length === 0) return;
    if (isSavingRef.current) return;
    isSavingRef.current = true;

    const itemsToSave = [...orderFormUpdates.items];

    const updates = { ...orderFormUpdates, orderTypeId: orderType };

    try {
      setSnackbar({
        open: true,
        message: t('autosaving'),
        severity: 'info',
      });

      const response = await client.saveOrderForm(selectedStore?.storeNumber, updates);

      if (response.success) {
        updateOrderFormQuantities(itemsToSave);

        setOrderFormUpdates(prev => ({
          ...prev,
          items: prev.items.filter(
            item => !itemsToSave.some(savedItem => savedItem.id === item.id),
          ),
        }));

        setSnackbar({
          open: true,
          message: t('changesSaved'),
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: response.message || t('autosaveFailed'),
          severity: 'error',
        });
        setShowQuantityWarningDialog(true);
        setWarningMessage(response.message);
        fixQuantities();
      }
    } catch (error) {
      logError(error);
      setSnackbar({
        open: true,
        message: t('autosaveFailed'),
        severity: 'error',
      });
    } finally {
      isSavingRef.current = false;
    }
  };

  const updateOrderFormQuantities = (savedItems: OrderFormItemUpdate[]) => {
    setOrderForm((prevOrderForm) => {
      if (!prevOrderForm) return prevOrderForm;

      let changedAnyCategory = false;
      const updatedCategories = prevOrderForm.categories.map((category) => {
        let changedItems = false;
        const updatedItems = category.items.map((item) => {
          const savedItem = savedItems.find((si) => si.id === item.id);
          if (!savedItem) return item;

          const newQtyArr = item.quantity.map((oldQty, index) => {
            const date = prevOrderForm.availableDates[index].deliveryDate;
            const savedDate = savedItem.dates.find((sd) => sd.date === date);
            return savedDate ? savedDate.quantity : oldQty;
          });

          if (newQtyArr.some((nq, i) => nq !== item.quantity[i])) {
            changedItems = true;
            return { ...item, quantity: newQtyArr };
          }
          return item;
        });

        if (changedItems) {
          changedAnyCategory = true;
          return { ...category, items: updatedItems };
        }
        return category;
      });

      if (!changedAnyCategory) return prevOrderForm;
      return { ...prevOrderForm, categories: updatedCategories };
    });
  };

  const loadData = async () => {
    setLoading(true);
    await fetchOrderTypes();
    setLoading(false);
  };

  const fetchOrderTypes = async () => {
    try {
      const orderTypesResponse = await client.getOrderTypes(selectedStore?.storeNumber);
      if (orderTypesResponse.success && orderTypesResponse.data.length > 0) {
        const nonAutoshipOrderTypes = orderTypesResponse.data.filter(x => !isAutoShipOrder(x.id));
        setOrderTypes(nonAutoshipOrderTypes);
        const defaultOrderType = nonAutoshipOrderTypes.length > 0 ? nonAutoshipOrderTypes[0].id : -1;
        setOrderType(defaultOrderType);
        await fetchSuppliers();
        await fetchCategories(defaultOrderType, supplier);
        await fetchOrderForm(defaultOrderType, category, orderBy, order, productSearch, supplier, true);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const fetchCategories = async (selectedOrderType: number, supplier: string) => {
    try {
      const categories = await client.getCategories(selectedStore?.storeNumber, selectedOrderType, supplier);
      setCategories(categories.data);
    } catch (error) {
      setCategories([]);
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const fetchSuppliers = async () => {
    try {
      const suppliers = await client.getSuppliers(selectedStore?.storeNumber);
      setSuppliers(suppliers.data);
    } catch (error) {
      setSuppliers([]);
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const fetchOrderForm = async (selectedOrderType: number,
    categoryId: number,
    orderBy: string,
    order: string,
    item: string,
    supplier: string,
    resetUpdates: boolean) => {
    try {
      const response = await client.getOrderForm(selectedStore?.storeNumber, selectedOrderType, categoryId, orderBy, order, item, supplier);
      response.data.availableDates = response.data.availableDates.map(item => {
        return {
          ...item,
          deliveryDate: standardizeDateFormat(item.deliveryDate),
        };
      });
      setOrderForm(response.data);
      if (resetUpdates) {
        setOrderFormUpdates({
          storeNumber: selectedStore?.storeNumber ?? '',
          orderTypeId: orderType || -1,
          supplierNumber: selectedOrderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
          items: [],
          dates: response.data.availableDates.map(item => item.deliveryDate),
        });
      }
    } catch (error) {
      setOrderForm(undefined);
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const loadPodStoreConfiguration = async () => {
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const response = await podClient.getStoreConfiguration(selectedStore.franchiseName, selectedStore.storeNumber);
        setIsPodEnabled(response.isPodEnabled);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchOrderError'), 'error');
    }
  };

  const loadPredictedDemands = async () => {
    setLoading(true);
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber && orderForm?.availableDates?.length) {
        const predictions = await podClient.getPredictions(
          selectedStore.franchiseName,
          selectedStore.storeNumber,
          orderForm.availableDates.map((x) => x.deliveryDate),
        );
        await loadUomConversions(predictions);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchOrderError'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const loadUomConversions = async (predictions: PredictedDemandModel[]) => {
    const itemNumbers = predictions.map(x => x.itemNumber);
    const items = orderForm?.categories.flatMap(x => x.items).filter(x => itemNumbers.includes(x.sku));

    const response = await getConversions(undefined, undefined, undefined, undefined, selectedStore?.storeNumber);
    const conversions = response.conversions?.filter(conversion => {
      const item = items?.find(i => i.sku === conversion?.itemId);
      return item && item.primaryUom === conversion?.fromUom && item.orderableUom === conversion.toUom;
    });

    predictions.forEach((x) => {
      const conversion = conversions?.find(c => c?.itemId === x.itemNumber);
      const conversionNumber = conversion?.conversionNumber ?? 1;

      x.predictedDemandItems = x.predictedDemandItems.map(({ quantity, ...rest }) => ({
        ...rest,
        quantity: Math.ceil(quantity / conversionNumber),
      }));
    });

    setPredictedDemands(predictions);
  };

  const handleSearch = async () => {
    setLoading(true);
    await fetchCategories(orderType, orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '');
    await fetchOrderForm(orderType, category, orderBy, order, productSearch, supplier, true);
    setLoading(false);
  };

  const handleSave = async () => {
    if (isSavingRef.current) return;
    isSavingRef.current = true;

    const itemsToSave = [...orderFormUpdates.items];

    const updates: OrderFormUpdate = {
      ...orderFormUpdates,
      orderTypeId: orderType,
    };
    updatePredictedQuantities(orderFormUpdates.items);

    try {
      setLoading(true);

      const response = await client.saveOrderForm(selectedStore?.storeNumber, updates);
      setLoading(false);
      if (response.success) {
        handleSnackbarOpen(t('saveSuccess'), 'success');

        setOrderFormUpdates(prev => ({
          ...prev,
          items: prev.items.filter(
            item => !itemsToSave.some(savedItem => savedItem.id === item.id),
          ),
        }));

        // fetchOrderForm resets the updates
        await fetchOrderForm(orderType, category, orderBy, order, productSearch, supplier, true);
      } else {
        setShowQuantityWarningDialog(true);
        setWarningMessage(response.message);
        fixQuantities();
      }
    } catch (error) {
      setLoading(false);
    } finally {
      isSavingRef.current = false;
      setLoading(false);
    }
  };

  const handleExitAndSave = async () => {
    setIsExitAndSaveClicked(true);
    if (!loading && orderFormUpdates.items.length > 0) {
      await handleSave();
      setNavigateToIndex(true);
    }
  };

  const updatePredictedQuantities = (items: OrderFormItemUpdate[]) => {
    predictedDemands.forEach(x => {
      const index = items.findIndex(i => i.sku === x.itemNumber);
      const originalItem = orderForm?.categories.flatMap(category => category.items).find(item => item.sku === x.itemNumber);
      const currentItem = items.find(i => i.id === originalItem?.id);

      const newDates = orderForm?.availableDates.map((date, idx) => {

        const existingItem = currentItem?.dates.find(i => {
          const itemDate = i.date.split('T')[0];
          return itemDate === date.deliveryDate;
        });

        if (!existingItem) return undefined;

        const predictedDemandItem = x.predictedDemandItems[idx];
        const predictedQuantity = predictedDemandItem.hasPrediction ? predictedDemandItem.quantity : undefined;

        const newDate: OrderFormItemUpdateDate = {
          date: date.deliveryDate,
          quantity: existingItem.quantity,
          predictedQuantity: predictedQuantity,
        };
        return newDate;
      }).filter(x => x !== undefined) as OrderFormItemUpdateDate[];

      if (index < 0) {
        return;
      }
      else {
        items[index].dates = newDates ?? [];
      }
    });
  };

  const handleClearFilters = async () => {
    setProductSearch('');
    setCategory(-1);
    setOrderBy('Name');
    setOrder('asc');
    setLoading(true);
    await fetchOrderForm(orderType, -1, 'Name', 'asc', '', supplier, true);
    setLoading(false);
  };

  const handleOrderTypeChange = async (changedOrderType: string) => {
    const newOrderType = Number(changedOrderType);
    if (unsavedChangesModal?.open === false && orderFormUpdates.items.length > 0 && orderType !== newOrderType) {
      setUnsavedChangesModal({ open: true, action: Action.ChangeOrderType, actionValue: newOrderType.toString() });
      return;
    }
    setOrderType(newOrderType);
    setLoading(true);
    const supplier = suppliers && suppliers.length > 0 ? suppliers[0].number : '';
    setSupplier(supplier);
    await fetchCategories(newOrderType, newOrderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '');
    setOrderFormUpdates({
      storeNumber: selectedStore?.storeNumber ?? '',
      orderTypeId: newOrderType,
      supplierNumber: newOrderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
      items: [],
      dates: [],
    });
    await fetchOrderForm(newOrderType, -1, 'Name', 'asc', '', supplier, true);
    setLoading(false);
  };

  const canSaveOrder = (): boolean => {
    return !loading && !isAutoShipOrder(orderType);
  };

  const handleSingleQuantityChange = async (
    newQuantity: number,
    sku: string,
    itemId: number,
    dateIndex: number,
  ) => {
    try {
      const date = orderForm?.availableDates[dateIndex]?.deliveryDate;
      if (!date) return;

      //Check if the item exists in orderFormUpdates
      const existingItemIndex = orderFormUpdates.items.findIndex(item => item.id === itemId);
      const existingItem = existingItemIndex !== -1 ? orderFormUpdates.items[existingItemIndex] : null;

      const singleItemUpdate = {
        id: itemId,
        sku,
        dates: [{ date, quantity: newQuantity }],
      };

      const updates: OrderFormUpdate = {
        storeNumber: selectedStore?.storeNumber ?? '',
        orderTypeId: orderType,
        supplierNumber:
        orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
        items: [singleItemUpdate],
        dates: [date],
      };

      const response = await client.saveOrderForm(
        selectedStore?.storeNumber,
        updates,
      );

      if (response.success) {
        // If the save was successful and the item exists in orderFormUpdates, remove it
        if (existingItem) {
          setOrderFormUpdates(prev => ({
            ...prev,
            items: prev.items.filter(item => item.id !== itemId),
          }));
        }

      } else {
        // Handle the error response by setting quantity to minQuantity
        setSnackbar({
          open: true,
          message: response.message || t('autosaveFailed'),
          severity: 'error',
        });
        setShowQuantityWarningDialog(true);
        setWarningMessage(response.message);

        // Find the item to get minQuantity
        const item = orderForm?.categories
          .flatMap((category) => category.items)
          .find((itm) => itm.id === itemId);

        if (item) {
          let correctedQuantity = newQuantity;

          // Determine if the error is due to minQuantity or maxQuantity
          if (item.minQuantity != null && newQuantity < item.minQuantity) {
            correctedQuantity = item.minQuantity;
          } else if (item.maxQuantity != null && newQuantity > item.maxQuantity) {
            correctedQuantity = item.maxQuantity;
          }

          // update the orderForm to reflect the corrected quantity
          setOrderForm((prevOrderForm) => {
            if (!prevOrderForm) return prevOrderForm;

            const updatedCategories = prevOrderForm.categories.map((cat) => ({
              ...cat,
              items: cat.items.map((it) => {
                if (it.id === itemId) {
                  const newQtyArr = [...it.quantity];
                  newQtyArr[dateIndex] = correctedQuantity;
                  return { ...it, quantity: newQtyArr };
                }
                return it;
              }),
            }));

            return { ...prevOrderForm, categories: updatedCategories };
          });
        } else {
          console.error(
            'Item not found or minQuantity is undefined. Cannot adjust quantity.',
          );
        }
      }
    } catch (error) {
      logError(error);
      setSnackbar({
        open: true,
        message: t('autosaveFailed'),
        severity: 'error',
      });
    }
  };

  const handleSort = async (direction: string) => {
    const isAsc = direction === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
  };

  const handleOrderBy = async (property: string) => {
    setOrderBy(property);
  };

  const handleDownloadOrderForm = async () => {
    setLoading(true);
    try {
      const result = await client.downloadOrderForm(selectedStore?.storeNumber, orderType, orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '');
      if (result && result.success) {
        const blob = base64ToBlob(result.data.fileData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = result.data.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      handleSnackbarOpen(t('downloadFailed') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      await uploadFile(file);
    } else {
      handleSnackbarOpen(t('noFileSelected'), 'warning');
    }
  };

  const uploadFile = async (file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64 = e.target?.result?.toString().split(',')[1] ?? '';
        const fileRequest = {
          fileData: base64,
          fileName: file.name,
        };
        setLoading(true);
        const response = await client.uploadOrderForm(
          selectedStore?.storeNumber,
          orderType,
          orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
          fileRequest,
        );
        if (response?.success) {
          handleSnackbarOpen(t('uploadSuccess'), 'success');
          await fetchOrderForm(orderType, category, orderBy, order, productSearch, supplier, true);
          setLoading(false);
        } else {
          handleSnackbarOpen(t('uploadFailed'), 'error');
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };
      reader.onerror = (error) => {
        handleSnackbarOpen(t('fileReadError'), 'error');
        setLoading(false);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      handleSnackbarOpen(t('fileUploadError') + ' ' + error, 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSupplierChange = async (number: string) => {
    setSupplier(number);
    setLoading(true);
    await fetchCategories(orderType, number);
    await fetchOrderForm(orderType, -1, 'Name', 'asc', '', number, true);
    setLoading(false);
  };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  const handleLeavePageModalConfirm = async () => {
    await handleSave();
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const handleLeavePageModalCancel = () => {
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const handleUnsavedChangesModalClose = () => {
    setUnsavedChangesModal({ open: false, action: Action.None, actionValue: '' });
  };

  const handleUnsavedChangesModalConfirm = (action?: Action, actionValue?: string) => {
    if (action) {
      switch (action) {
      case Action.ChangeOrderType:
        if (actionValue) {
          setUnsavedChangesModal({ open: false, action: Action.None, actionValue: '' });
          handleOrderTypeChange(actionValue);
        }
        break;
      default:
        setUnsavedChangesModal({ open: false, action: Action.None, actionValue: '' });
      }
    }
  };

  const handleShowPredictedQuantitiesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPredictedQuantities(event.target.checked);
  };

  const handleMagicWandClicked = (predictedQuantity: number, item: OrderFormItem, index: number) => {
    handleSingleQuantityChange(predictedQuantity, item.sku, item.id, index);
  };

  const matchPredictedQuantities = async () => {
    try {
      let updatedItems: OrderFormItemUpdate[] = [];

      setOrderFormUpdates(prevState => {
        const itemsCopy = JSON.parse(JSON.stringify(prevState.items)) as OrderFormItemUpdate[];

        predictedDemands.forEach(x => {
          const index = itemsCopy.findIndex(i => i.sku === x.itemNumber);
          const originalItem = orderForm?.categories.flatMap(category => category.items).find(item => item.sku === x.itemNumber);
          const currentItem = itemsCopy.find(i => i.id === originalItem?.id);

          const newDates = orderForm?.availableDates.map((date, idx) => {
            const existingItem = currentItem?.dates.find((sd) => sd.date === date.deliveryDate);
            const currentValue = existingItem?.quantity ?? originalItem?.quantity[idx];
            const predictedDemandItem = x.predictedDemandItems[idx];
            const quantity = predictedDemandItem.hasPrediction ? predictedDemandItem.quantity : currentValue;

            const newDate: OrderFormItemUpdateDate = {
              date: date.deliveryDate,
              quantity: quantity ?? 0,
            };
            return newDate;
          });

          if (index < 0 && originalItem) {
            const newItem: OrderFormItemUpdate = {
              id: originalItem.id,
              sku: x.itemNumber,
              dates: newDates ?? [],
            };
            itemsCopy.push(newItem);
          } else if (index >= 0) {
            itemsCopy[index].dates = newDates ?? [];
          }
        });

        updatedItems = itemsCopy;
        return { ...prevState, items: itemsCopy };
      });

      const updates: OrderFormUpdate = {
        storeNumber: selectedStore?.storeNumber ?? '',
        orderTypeId: orderType,
        supplierNumber: orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
        items: updatedItems,
        dates: orderForm?.availableDates.map(x => x.deliveryDate) ?? [],
      };

      setLoading(true);
      const response = await client.saveOrderForm(selectedStore?.storeNumber, updates);
    
      if (response.success) {
        handleSnackbarOpen(t('saveSuccess'), 'success');
      } else {
        setShowQuantityWarningDialog(true);
        setWarningMessage(response.message);
        fixQuantities();
      }

      setRefreshKey(prev => prev + 1);
    } catch (error) {
      logError(error);
      handleSnackbarOpen(t('saveFailed'), 'error');
    } finally {
      setLoading(false);
    }
  };


  const quantityError = (item: OrderFormItem, currentValue: string): string | null => {
    const quantity = Number(currentValue);

    if (quantity === 0) {
      return null;
    }

    if ((item.maxQuantity != null) && quantity > item.maxQuantity) {
      return `${t('maxQuantityAllowedIs')}: ${item.maxQuantity}`;
    }

    if ((item.minQuantity != null) && quantity < item.minQuantity)
      return `${t('minQuantityAllowedIs')}: ${item.minQuantity}`;

    return null;
  };

  const isQuantityError = (item: OrderFormItem, currentValue: string): boolean => {
    return quantityError(item, currentValue) != null;
  };

  const getCurrentValue = (item: OrderFormItem, index: number) => {
    const initialQuantity = item.quantity[index].toString();
    const currentValue = orderFormUpdates.items.find(updateItem => updateItem.id === item.id)?.dates.find(d => d.date === orderForm?.availableDates.map(x => x.deliveryDate)[index])?.quantity.toString() ?? initialQuantity ?? '0';
    return currentValue;
  };

  const anyErrors = (): boolean => {
    return orderForm?.availableDates.some((date, index) => checkForErrors(index)) ?? false;
  };

  const checkForErrors = (index: number): boolean => {
    return orderForm?.categories.some(category => checkCategoryErrors(category, index)) ?? false;
  };

  const checkCategoryErrors = (category: OrderFormCategory, index: number): boolean => {
    return category.items.some(item => isQuantityError(item, getCurrentValue(item, index)));
  };

  const fixQuantities = () => {
    setOrderFormUpdates((prev) => {
      const updatedItems = prev.items.map((item) => {
        const adjustedDates = adjustItemQuantities(item);
        return { ...item, dates: adjustedDates };
      });

      return { ...prev, items: updatedItems };
    });
  };

  const adjustItemQuantities = (item: OrderFormItemUpdate) => {
    const matchingItem = findMatchingItem(item.id);
    const maxQuant = matchingItem?.maxQuantity;
    const minQuant = matchingItem?.minQuantity;

    return item.dates.map((date) => adjustDateQuantity(date, maxQuant, minQuant));
  };

  const findMatchingItem = (itemId: number) => {
    const allItems = orderForm?.categories.flatMap(x => x.items);
    return allItems?.find(item => item.id === itemId);
  };

  const adjustDateQuantity = (date: OrderFormItemUpdateDate, maxQuant: number | undefined, minQuant: number | undefined) => {
    let newQuantity = date.quantity;

    if (newQuantity != 0) {
      if (maxQuant && newQuantity > maxQuant) newQuantity = maxQuant;
      if (minQuant && newQuantity < minQuant) newQuantity = minQuant;
    }

    return { ...date, quantity: newQuantity };
  };

  const autocompleteOptions = [{ id: -1, name: 'All', parentName: '' }, ...(Array.isArray(categories) ? categories : [])];

  const getOptionLabel = (option: { parentName?: string; name: string }): string => {
    const parentName = option.parentName;
    const categoryName = option.name;

    const translatedParent = parentName && isOrderCategoryParent(parentName)
      ? t(OrdersCategoriesParentsMap[parentName as keyof typeof OrdersCategoriesParentsMap])
      : parentName;

    const translatedCategory = isOrderCategory(categoryName)
      ? t(OrderCategoriesTranslationMap[categoryName as keyof typeof OrderCategoriesTranslationMap])
      : categoryName;

    if (parentName) {
      return translatedParent
        ? `${translatedParent} > ${translatedCategory}`
        : `${parentName} > ${translatedCategory}`;
    } else {
      return translatedCategory;
    }
  };

  // Render
  return (
    <PageArea>
      <Grid item
        mb={10}
        sx={{ textAlign: 'left' }}>
        <BackButton handleClick={handleExitAndSave}
          title={t('exitAndSave')}
          isLink={false} />
      </Grid>

      <Grid container>
        <Grid item
          container
          justifyContent={'space-between'}
          alignItems={'end'}
          xs={12}
          md={12}>
          <Grid item
            xs={12}
            md={10}>
            <PageTitle>{t('title')}</PageTitle>
            <Typography variant="textMD" 
              sx={{ fontSize: '16px', display:'block' }}>{t('subtitle')}</Typography>
          </Grid>
          <Grid item
            xs={12}
            md={2}>
            <Button
              variant="primary"
              size="lg"
              disabled={loading || orderFormUpdates.items.length === 0 || anyErrors()}
              fullWidth
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          mt={5}
          xs={12}
          sx={{
            border: '1px solid',
            borderColor: theme.palette.custom.gray[300],
          }}
        >
          <Grid
            item
            container
            alignItems={'center'}
            xs={12}
            md={12}
            spacing={4}
            sx={{ padding: 4 }}
          >
            <Grid item
              xs={12}
              md={orderType === OrderTypeEnum.DirectStoreDelivery ? 2 : 4}>
              <TextField
                fullWidth
                size="small"
                label={t('orderType')}
                value={orderType}
                disabled={loading}
                required
                onChange={(e) => handleOrderTypeChange(e.target.value)}
                select
              >
                {orderTypes?.map((type) => (
                  <MenuItem key={type.id}
                    value={type.id}>
                    {t(OrderTypeTranslationMap[type.name as keyof typeof OrderTypeTranslationMap]) || type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {orderType === OrderTypeEnum.DirectStoreDelivery &&
                            <Grid item
                              md={2}
                              xs={12}>
                              <TextField
                                fullWidth
                                size='small'
                                label={t('supplier')}
                                disabled={loading}
                                value={supplier}
                                required
                                onChange={(e) => handleSupplierChange(e.target.value)}
                                select
                              >
                                {suppliers.map((type) => (
                                  <MenuItem key={type.id}
                                    value={type.number}>
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
            }
            <Grid item
              xs={12}
              md={2}>
              <TextField
                fullWidth
                size="small"
                label={t('orderBy')}
                disabled={loading}
                value={orderBy}
                onChange={(e) => handleOrderBy(e.target.value)}
                required
                select
              >
                <MenuItem value="Name">{t('productName')}</MenuItem>
              </TextField>
            </Grid>

            <Grid item
              xs={12}
              md={2}>
              <TextField
                fullWidth
                size='small'
                label={t('direction')}
                disabled={loading}
                value={order}
                onChange={(e) => handleSort(e.target.value)}
                required
                select
              >
                <MenuItem value="asc">{t('ascending')}</MenuItem>
                <MenuItem value="desc">{t('descending')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                fullWidth
                variant="secondary"
                disabled={loading}
                size="lg"
                onClick={handleClearFilters}
              >
                {t('clearFilters')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                fullWidth
                variant="secondary"
                disabled={loading || !canSaveOrder()}
                startIcon={<DownloadIcon />}
                size="lg"
                onClick={handleDownloadOrderForm}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {t('downloadOrderForm')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={4}>
              <TextField
                fullWidth
                size="small"
                disabled={loading}
                placeholder={t('searchByItemNameOrSKU')}
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <SearchSm />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item
              xs={12}
              md={4}>
              <Autocomplete
                size="small"
                options={autocompleteOptions}
                disabled={loading}
                getOptionLabel={getOptionLabel}
                value={categories.find((cat) => cat.id === category) ?? autocompleteOptions[0]}
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue.id : -1);
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('category')}
                    placeholder={t('choose')} />
                )}
              />
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                fullWidth
                variant="primary"
                disabled={loading}
                size="lg"
                startIcon={<SearchSm />}
                onClick={handleSearch}
              >
                {t('search')}
              </Button>
            </Grid>

            <Grid item
              xs={12}
              md={2}>
              <Button
                fullWidth
                variant="secondary"
                disabled={loading || !canSaveOrder()}
                startIcon={<UploadIcon />}
                size="lg"
                onClick={handleUploadButtonClick}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {t('uploadOrderForm')}
              </Button>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept=".xlsx, .xls"
              />
            </Grid>

            {isPodEnabled && (
              <>
                <Grid item
                  xs={12}
                  md={4}
                  p={5}>
                  <FormControlLabel
                    control={<Switch checked={showPredictedQuantities}
                      onChange={handleShowPredictedQuantitiesChange} />}
                    label={t('showPredictedQuantities')}
                  />
                </Grid>

                {showPredictedQuantities && (
                  <Grid item
                    xs={12}
                    md={4}
                    p={5}>
                    <Button
                      fullWidth
                      variant="primary"
                      disabled={loading}
                      startIcon={<MagicWand01 />}
                      size="lg"
                      onClick={matchPredictedQuantities}
                    >
                      {t('matchPredictedQuantities')}
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>

          <Grid item
            xs={12}
            md={12}
            mt={5}
            mb={5}>
            <Divider />
          </Grid>

          <Grid item
            xs={12}
            py={5}>
            {loading ? (
              <LoadingWheel />
            ) : (
              <TableContainer component="div">
                <Table
                  sx={{ tableLayout: 'fixed', minWidth: 900, overflow: 'hidden' }}
                  size="small"
                  aria-label="a table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: showImage ? '40%' : '5%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('productInformation')}
                      </TableCell>
                      {orderForm?.availableDates?.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            width: showImage ? '8.5%' : '12.5%',
                            fontWeight: 'bold',
                            padding: { xs: '2px', sm: '4px', md: '6px' },
                            whiteSpace: 'normal',
                            wordBreak: !showImage ? 'break-word' : '',
                            overflow: 'hidden',
                          }}
                          align="center"
                        >
                          <Box>
                            <Typography component="span"
                              sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                              {t('deliveryDate')}
                            </Typography>
                            <Typography component="span"
                              display="block"
                              sx={{ fontSize: '12px' }}>
                              {item.deliveryDate}
                            </Typography>
                            <Typography component="span"
                              sx={{ fontWeight: 'bold', mt: 1, fontSize: '12px' }}>
                              {t('cutOff')}
                            </Typography>
                            <Typography component="span"
                              sx={{ fontSize: '12px' }}>
                              {item.cutOffTime}
                            </Typography>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!orderForm && orderForm?.categories?.map((category) => (
                      <React.Fragment key={`category-${category.id}`}>
                        <TableRow>
                          <TableCell
                            colSpan={orderForm?.availableDates.length + 1}
                            style={{ backgroundColor: theme.palette.primary.main }}
                          >
                            <Typography variant="textSM"
                              color="white">
                              {isOrderCategory(category.name)
                                ? t(OrderCategoriesTranslationMap[category.name as keyof typeof OrderCategoriesTranslationMap])
                                : category.name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {category?.items?.map((item) => {
                          const predicted = predictedDemands.find((pd) => pd.itemNumber === item.sku);
                          const rowHasPrediction = Boolean(
                            predicted?.predictedDemandItems?.some((d) => d.hasPrediction),
                          );

                          const rowKey =
                                    rowHasPrediction && showPredictedQuantities
                                      ? `categoryItem-${category.id}-${item.id}-${refreshKey}`
                                      : `categoryItem-${category.id}-${item.id}`;

                          return (
                            <OrderFormRow
                              key={rowKey}
                              categoryId={category.id}
                              item={item}
                              dates={orderForm.availableDates}
                              orderFormUpdates={orderFormUpdates}
                              handleSingleQuantityChange={handleSingleQuantityChange}
                              autosaveItem={autosaveItem}
                              canSaveOrder={canSaveOrder()}
                              isQuantityError={isQuantityError}
                              quantityError={quantityError}
                              showMessageWhenNoCost={showMessageWhenNoCost}
                              currencyCode={currencyCode ?? ''}
                              t={t}
                              isLargeScreen={isLargeScreen}
                              predictedDemands={predictedDemands}
                              showPredictedQuantities={showPredictedQuantities}
                              handleMagicWandClicked={handleMagicWandClicked}
                            />
                          );
                        })}


                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>

        <Grid item
          container
          justifyContent={'space-between'}
          alignItems={'end'}
          xs={12}
          md={12}>
          <Grid item
            xs={12}
            md={2}
            mt={'10px'}>
            <Button
              variant="primary"
              size="lg"
              disabled={loading || orderFormUpdates.items.length === 0 || anyErrors() || !canSaveOrder()}
              fullWidth
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={snackbar.message === t('autosaving') ? null : 2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <LeavePageModal
        open={blocker.state === 'blocked' && orderFormUpdates.items.length > 0 && !isExitAndSaveClicked}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onCancel={handleLeavePageModalCancel}
        onConfirmUrl="/store/orders-history"
        leaveWarningContent={t('saveOnLeaveWarningContent')}
        leaveWarningTitle={t('saveOnLeaveWarningTitle')}
      />

      <ReloadPageModal
        open={unsavedChangesModal?.open ?? false}
        onConfirm={() => handleUnsavedChangesModalConfirm(unsavedChangesModal?.action, unsavedChangesModal?.actionValue)}
        onClose={handleUnsavedChangesModalClose}
      />

      <QuantityWarningDialog
        setShowQuantityWarningDialog={setShowQuantityWarningDialog}
        message={warningMessage}
        showDialog={showQuantityWarningDialog}
      />
    </PageArea>
  );
};

export default OrderFormPage;