import { Box, Button, Grid, SvgIcon } from '@mui/material';
import TransactionsBox from './components/TransactionsBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useContext, useEffect, useState } from 'react';
import { ProductTransactionSearch, Store, SuccessResponse_1OfList_1OfVoidedTransaction } from '../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { TransactionRecord } from './types/TransactionRecord';
import { ReasonRecord } from './types/ReasonRecord';
import { StoreRecord } from './types/StoreRecord';
import { TypesRecord } from './types/TypesRecord';
import { Plus } from '../../assets';
import { UserContext } from '../../components/shared/useUser';
import SubmitModal from './components/TransactionsCreateModal';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { ConfigurationName } from '../../enums/ConfigurationName';
import { adjustDateByDays } from '../../utils';

const StockCountSchedule = () => {
  const { getTransactions, getTransactionTypes, getTransactionReasons, getVoidedProducts } = useNswagClient();
  const { t } = useTranslation('common');
  const { hasPermissionTo, selectedStore, isAdmin, user } = useContext(UserContext);

  const [transactions, setTransactions] = useState<TransactionRecord[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<TypesRecord[]>([]);
  const [transactionReasons, setTransactionReasons] = useState<ReasonRecord[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [stores, setStores] = useState<StoreRecord[]>([]);
  const [startDate, setStartDate] = useState<Date>(() => adjustDateByDays(new Date(), -7));
  const [endDate, setEndDate] = useState<Date>(() => adjustDateByDays(new Date(), 1));
  const [searchTerm, setSearchTerm] = useState('');
  const [type, setType] = useState('');
  const [reason, setReason] = useState('');
  const [sourceID, setSourceID] = useState('');
  const [source, setSource] = useState('');
  const [fieldToSort, setFieldToSort] = useState('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const [dropdownStore, setDropdownStore] = useState(selectedStore?.storeNumber ?? '00005');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const hasPermissionToCreateNew = hasPermissionTo(['TransactionWrite']) || isAdmin();
  const { getConfiguration } = useNswagClient();


  useEffect(() => {
    loadData();
  }, [selectedStore]);

  const changePage = (newPage: number) => {
    setPage(newPage);

    setIsReloading(true);

    const loadedTransactions = loadTransactions(dropdownStore, newPage);
    
    Promise.all([loadedTransactions])
      .catch((error) => { console.log(error); })
      .finally(() => {
        setIsReloading(false);
      });
  };

  const loadTransactions = async (storeNum?: string, newpage?: number): Promise<void> => {
    const storeNumToUse = storeNum ?? dropdownStore;
    const skip = 10 * (newpage ?? page);
    const search = searchTerm == '' ? undefined : searchTerm;
    const typeToUse = type == '' ||  type == 'All' ? undefined : type;
    const reasonToUse = reason == ''||  type == 'All'  ? undefined : reason;
    const sourceToUse = sourceID == '' ? undefined : sourceID;
    const sourceIDToUse = source == '' ? undefined : source;
    const canVoidTransactions = hasPermissionTo(['TransactionAdmin']) || hasPermissionTo(['TransactionWrite']); 
    const transactionsPerStore = await getTransactions(storeNumToUse, startDate.toISOString(), endDate.toISOString(), search, undefined, typeToUse, reasonToUse, fieldToSort, sortDirection, skip, 10, sourceIDToUse, sourceToUse)
      .then(
        (result) => {
          setTotalCount(result.data?.totalCount ?? 0);
          if (result?.data?.data) {
            return mapTransactions(result.data.data);
          }
        },
      );

    const allTransactions = transactionsPerStore?.flat();
    setTransactions(allTransactions ?? []);
    
    if(canVoidTransactions){
      await isVoided(allTransactions?? []); 
    }
  };
  
  const mapTransactions = (data: ProductTransactionSearch[] | undefined): TransactionRecord[] => {
    if (data) {
      const ragDetails = data.map((transaction) => {
        return {
          id: transaction.transactionId,
          SkU: transaction.productItemNumber,
          itemName: transaction.productItemDescription,
          transactionType: transaction.type,
          transactioDate: transaction.transactionTime ? new Date(transaction.transactionTime) : undefined,
          quantity: String(transaction.productQuantity) + ' (' + transaction.productUom + ')',
          store: transaction.storeNumber,
          reason: transaction.reason,
          source: transaction.source,
          sourceID: transaction.sourceTransactionId,
          actualCost: transaction.costPrice,
          note: transaction.productNote,
          transactionDay: transaction.transactionDay,
          productID: transaction.productId,
          materials: transaction.materials,
          isVoided: transaction.isVoided,
        };
      });
      return ragDetails;
    }
    return [];
  };

  const isVoided = async (transactions: TransactionRecord[]) => {

    const transactionData = transactions.map(transaction => ({
      transactionDay: transaction.transactionDay,
      id: transaction.productID,
    }));

    await getVoidedProducts(transactionData)
      .then((result: SuccessResponse_1OfList_1OfVoidedTransaction) => {
        const updatedTransactions = transactions.map((transaction) => {
          const matchingResult = result?.data?.find(
            (res) => res.transactionId === transaction.id && res.productId === transaction.productID,
          );
          if (matchingResult) {
            return {
              ...transaction,
              isVoided: matchingResult.isVoided,
            };
          }
          return transaction;
        });
        setTransactions(updatedTransactions);
      })
      .catch((error) => { console.log(error); });
  };
  const loadTransactionTypes = async (storeNum?: string): Promise<TypesRecord[]> => {
    try {
      const storeNumToUse = storeNum ?? dropdownStore;
  
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const configResponse : any = await getConfiguration(ConfigurationName.ReturnToVendorEnabled, storeNumToUse, selectedStore?.franchiseName ?? '');
  
      const element = await getTransactionTypes(storeNumToUse);
      let types: TypesRecord[] = [];
      element.data?.filter(x => !x.type?.includes('Transfer')).forEach(x => {
        if (x.type) {
          const type = {
            id: '',
            displayName: t(`transactions.types.${x.type}`),
            type: x.type ?? '',
            franchiseID: x.franchiseId ?? -1,
            direction: x.direction ?? '',
          };
          types = types.concat(type);
        }
      });
  
      const transfer: TypesRecord = { id: '', displayName: t('transactions.types.Transfer'),type: 'Transfer', franchiseID: -1, direction: '', enabled: undefined, enabledDuplicateValidation: undefined };
      const stockAdjustments: TypesRecord = { id: '',displayName: t('transactions.types.Stock Adjustment'), type: 'Stock Adjustment', franchiseID: -1, direction: '', enabled: undefined, enabledDuplicateValidation: undefined };
      const returnToStock: TypesRecord = { id: '',displayName:t('transactions.types.Return to Stock'), type: 'Return to Stock', franchiseID: -1, direction: '', enabled: undefined, enabledDuplicateValidation: undefined };
      const madeInStore: TypesRecord = { id: '',displayName:t('transactions.types.Made in Store'), type: 'Made in Store', franchiseID: -1, direction: '', enabled: undefined, enabledDuplicateValidation: undefined };
      const returnToVendor: TypesRecord = { id: '',displayName:t('transactions.types.Return to Vendor'), type: 'Return to Vendor', franchiseID: -1, direction: '', enabled: undefined, enabledDuplicateValidation: undefined };
  
      types = types.concat(stockAdjustments, returnToStock, transfer);

      if(configResponse.data && configResponse.data.value === 'true'){
        types = types.concat(returnToVendor);
      }
      const hasMadeInStorePerm = hasPermissionTo(['MadeInStoreRead']);
      if (hasMadeInStorePerm) {
        types = types.concat(madeInStore);
      }
  
      types.forEach((rag, index) => rag.id = (index + 1).toString());
      setTransactionTypes(types);
      return types;
    } catch (error) {
      console.error('Error:', error);
    }
    return [];
  };
  
  

  const loadTransactionReasons = async (storeNum?: string): Promise<void> => {
    const storeNumToUse = storeNum ?? dropdownStore;
    const transactionReasons: ReasonRecord[] = await getTransactionReasons(undefined, storeNumToUse).then(
      (element) => {
        let reasons: ReasonRecord[] = [];
        element.data?.reasons?.forEach(x => {
          if (x.name) {
            const reason = {
              id: x.id ?? -1,
              reason: x.name ?? '',
              franchiseID: x.franchiseId ?? -1,
            };
            reasons = reasons.concat(reason);
          }
        });

        return reasons;
      },
    );

    setTransactionReasons(transactionReasons);
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      mapStores(user?.stores ?? []);

      const loadedTransactions = loadTransactions();
      const loadedTransactionTypes = loadTransactionTypes();
      const loadedTransactionReasons = loadTransactionReasons();
  
      await Promise.all([
        loadedTransactions,
        loadedTransactionTypes,
        loadedTransactionReasons,
      ]);
  
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading data:', error);
      setIsLoading(false);
    }
  };
  

  const mapStores = (stores: Store[]): StoreRecord[] => { 
    if (stores) {
      const mappedStores = stores.map(store => {
        return {
          id: store.storeNumber ?? '',
          displayName: store.storeName ?? '',
          franchiseID: store.franchiseId ?? -1,
        };
        
      });

      setStores(mappedStores);
      return mappedStores;
    }
    return [];
  };

  const reloadTransactions = async (storeNum?: string) => {
    setIsReloading(true);
    setPage(0);

    const loadedTransactions = loadTransactions(storeNum, 0);

    Promise.all([loadedTransactions])
      .catch((error) => { console.log(error); })
      .finally(() => {
        setIsReloading(false);
      });
  };

  const reloadDropDowns = async (storeNum?: string) => {
    setIsLoading(true);

    const loadedTransactionTypes = loadTransactionTypes(storeNum);
    const loadedTransactionReasons = loadTransactionReasons(storeNum);

    Promise.all([loadedTransactionTypes, loadedTransactionReasons])
      .catch((error) => { console.log(error); })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelModal = () => {
    setOpenModal(false);
    reloadTransactions();
  };

  const handleConfirmModal = async () => {
    setOpenModal(true);
  };
  
  return ( 
    <PageArea>
      <Grid container
        sx={{ pb: '8px' }}>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <Box
            sx={{
              fontWeight: 'bold',
            }}>
            <PageTitle>{t('transactions.title')}</PageTitle>
          </Box>
        </Grid>
        <Grid item
          xs={8}></Grid>
        {hasPermissionToCreateNew && <Grid item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button size='lg'
            sx={{ ml: 4 }}
            startIcon={<SvgIcon><Plus></Plus></SvgIcon>}
            onClick={() => handleConfirmModal()}>{t('createNew')}</Button>
        </Grid>}
      </Grid>
      {<TransactionsBox schedules={transactions}
        loadData={reloadTransactions}
        reloadDropDowns={reloadDropDowns}
        isLoading={isLoading}
        isReloading={isReloading}
        stores={stores}
        transactionTypes={transactionTypes}
        reasons={transactionReasons}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        dropdownStore={dropdownStore}
        setDropdownStore={setDropdownStore}
        page={page}
        setPage={changePage}
        totalCount={totalCount}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        type={type}
        setType={setType}
        reason={reason}
        setReason={setReason}
        sourceID={sourceID}
        setSourceID={setSourceID}
        source={source}
        setSource={setSource}
        fieldToSort={fieldToSort}
        setFieldToSort={setFieldToSort}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}></TransactionsBox>}
      <SubmitModal openModal={openModal}
        onCancel={handleCancelModal}></SubmitModal>
    </PageArea>
  );
};

export default StockCountSchedule;