import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Button, Grid, TextField, Checkbox, TableContainer, Table,
  TableHead, TableBody, TableRow, TableCell, Typography, TableSortLabel,
  SvgIcon, InputAdornment, Switch, Autocomplete, Divider, Snackbar, Alert, FormControlLabel, useMediaQuery, Box, Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateField } from '@mui/x-date-pickers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { MagicWand01, Plus, SearchSm } from './../../../assets';
import { OrderKind } from '../../../enums/OrderKind';
import { isAutoShipOrder, OrderType as OrderTypeEnum, OrderTypeTranslationMap } from '../../../enums/OrderType';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { client, SimpleCategory, DeliverySchedule, OrderDetails, OrderItemDetails, DeleteOrderItemsRequest, Item } from '../../../app/services/api/orderManagementClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import OrderStatusChip from '../../../components/shared/orders/OrderStatusChip';
import CopyOrderModal from '../../../components/shared/orders/CopyOrderModal';
import { ConfirmationDialog } from '../../../components/shared/dialogs/ConfirmationDialog';
import { OrderStatus } from '../../../enums/OrderStatus';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import { applyRoundAndFormat } from '../../../utils';
import PageTitle from '../../../components/shared/PageTitle';
import PageArea from '../../../components/shared/PageArea';
import BackButton from '../../../components/shared/actions/BackButton';
import { useTranslation } from 'react-i18next';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';
import TextWithOverflowTooltip from '../../../components/shared/TextWithOverflowTooltip';
import { starbucksFranchises } from '../../../enums/FranchiseNames';
import { isOrderCategory, OrderCategoriesTranslationMap } from '../../../enums/OrderCategories';
import { PredictedDemandModel, podClient } from './../../../app/services/api/podClient';
import useNswagClient from './../../../hooks/api/useNswagClient';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';
import './OrderDetails.css';
import { QuantityWarningDialog } from './../../../components/shared/orders/QuantityWarningDialog';

interface DialogState {
  open: boolean;
  title: string;
  message: string;
  confirmAction: () => void;
}

const OrderDetailsPage = () => {
  dayjs.extend(duration);
  const navigate = useNavigate();
  const { selectedStore } = useContext(UserContext);
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const { t } = useTranslation('orderDetails');
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [loading, setLoading] = useState(true);
  const [orderType, setOrderType] = useState<string>('');
  const [orderTypeId, setOrderTypeId] = useState<number>(0);
  const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>(null);
  const [cutOffDate, setCutOffDate] = useState<Dayjs | null>(null);
  const [orderKind, setOrderKind] = useState(OrderKind.Emergency);

  const [productSearch, setProductSearch] = useState('');
  const [category, setCategory] = useState<number>(-1);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('Name');

  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>();
  const [filteredItems, setFilteredItems] = useState<OrderItemDetails[]>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [categories, setCategories] = useState<SimpleCategory[]>([]);
  const [deliverySchedules, setDeliverySchedules] = useState<DeliverySchedule[]>([]);

  const [itemQuantities, setItemQuantities] = useState<{ [key: number]: number }>({});
  const [isCopyOrderModalOpen, setIsCopyOrderModalOpen] = useState(false);
  const [isPodEnabled, setIsPodEnabled] = useState(false);

  const { getConversions } = useNswagClient();
  const [items, setItems] = useState<Item[]>([]);
  const [showPredictedQuantity, setShowPredictedQuantity] = useState<boolean>(true);
  const [predictedDemands, setPredictedDemands] = useState<PredictedDemandModel[]>([]);
  const [modifiedItems, setModifiedItems] = useState<Set<number>>(new Set());
  const [lastFocusedItemId, setLastFocusedItemId] = useState<number | null>(null);
  const [showAutosaveMessage, setShowAutosaveMessage] = useState<boolean>(false);
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);

  const INACTIVITY_TIMEOUT = 60000; // 1 min on milliseconds

  const [navigateToIndex, setNavigateToIndex] = useState<boolean>(false);
  const [isExitAndSaveClicked, setIsExitAndSaveClicked] = useState<boolean>(false);

  const [showQuantityWarningDialog, setShowQuantityWarningDialog] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    title: '',
    message: '',
    confirmAction: () => { },
  });

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const { id } = useParams();

  const isStarBucksRetail = starbucksFranchises.includes(selectedStore?.franchiseName ?? '');
  const showMessageWhenNoCost = isStarBucksRetail && orderDetails?.status != 'Order Confirmed';

  const blocker = useBlocker(() => modifiedItems.size > 0 && !isExitAndSaveClicked);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    loadData();
    loadPodStoreConfiguration();
  }, [selectedStore, id]);

  useEffect(() => {
    if (orderDetails) {
      const categoriesById: Record<number, SimpleCategory> = {};
      orderDetails.items.forEach((item) => {
        if (!categoriesById[item.categoryId]) {
          categoriesById[item.categoryId] = { id: item.categoryId, name: item.category };
        }
      });
      setCategories(Object.values(categoriesById));
    }
  }, [orderDetails]);

  useEffect(() => {
    if (isPodEnabled && deliveryDate && items.length > 0) {
      loadPredictedDemands();
    }
    
  }, [isPodEnabled, deliveryDate, items]);

  useEffect(() => {
    if (predictedDemands.length > 0 && orderDetails && orderDetails.status === OrderStatus.Editable) {
      let newItems = [...orderDetails.items];

      if (showPredictedQuantity) {
        const itemsToAdd = getAditionalItemsWithPredictions();

        newItems.push(...itemsToAdd);
      }
      else {
        newItems = newItems.filter(x => x.id > 0);
      }

      setOrderDetails((prev) => {
        return {
          ...prev!,
          items: newItems,
        };
      });

      setFilteredItems(newItems);
    }
  }, [predictedDemands, showPredictedQuantity]);

  useEffect(() => {
    if (modifiedItems.size > 0 ) {
      handleAutosaveMessage(false);
      startInactivityTimer();
    }
  }, [itemQuantities]);


  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (modifiedItems.size > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [modifiedItems]);

  useEffect(() => {
    if (navigateToIndex) {
      navigate('/store/order');
      if (blocker?.state === 'blocked') {
        blocker.proceed();
      }
      setNavigateToIndex(false);
    }
  }, [navigateToIndex, blocker]);

  const handleLeavePageModalCancel = () => {
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  const handleLeavePageModalConfirm = () => {
    handleSaveChanges();
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const startInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }

    const timer = setTimeout(async () => {
      if (modifiedItems.size > 0) {
        modifiedItems.forEach(itemId => {
          autosaveItem(itemId);
        });
      }
    }, INACTIVITY_TIMEOUT);

    setInactivityTimer(timer);
  };

  const handleAutosaveMessage = (changesSaved: boolean) => {
    setShowAutosaveMessage(changesSaved);
  };

  const handleItemFocus = (itemId: number) => {
    if (lastFocusedItemId !== null && lastFocusedItemId !== itemId && modifiedItems.has(lastFocusedItemId)) {

      autosaveItem(lastFocusedItemId);
    }
    setLastFocusedItemId(itemId);
  };

  const autosaveItem = async (itemId: number) => {
    if (!orderDetails) return;

    const updatedItems = orderDetails.items
      .map(item => ({
        id: item.itemId,
        quantity: itemQuantities[item.itemId] ?? item.quantity,
      }));

    const updatedOrder = {
      id: orderDetails.id,
      items: updatedItems,
    };

    try {
      const result = await client.updateOrder(selectedStore?.storeNumber, updatedOrder);
      if (result.success) {
        handleAutosaveMessage(true);
        setModifiedItems(prev => {
          const newModifiedItems = new Set(prev);
          newModifiedItems.delete(itemId);
          return newModifiedItems;
        });
      }
    } catch (error) {
      handleSnackbarOpen(t('updateError') + ' ' + error, 'error');
    }
  };

  const loadData = async () => {
    setLoading(true);
    await fetchOrderDetails();
    setLoading(false);
  };

  const updateOrderEditStatus = (order: OrderDetails) => {
    if (isAutoShipOrder(order.orderTypeId)) {
      return OrderStatus.Autoshipment;
    } 
    return order.status;
  };

  const fetchDeliverySchedules = async (selectedOrderType: number | undefined, supplierNumber: string) => {
    try {
      const deliverySchedulesResponse = await client.getDeliverySchedules(selectedStore?.storeNumber, selectedOrderType, supplierNumber);
      setDeliverySchedules(deliverySchedulesResponse.data || []);
    } catch (error) {
      handleSnackbarOpen(t('loadingErrorMessage') + ' ' + error, 'error');
    }
  };
  const [orderConfirmed, setOrderConfirmed] = useState<boolean>();
  const fetchOrderDetails = async () => {
    try {
      const result = await client.getOrder(selectedStore?.storeNumber, parseInt(id ?? ''));
      result.data.status = updateOrderEditStatus(result.data);
      setOrderDetails(result.data);
      setFilteredItems(result.data.items);
      if (result.data.status == 'Order Confirmed') {
        setOrderConfirmed(true);
      } else { setOrderConfirmed(false); }
      setOrderType(result.data.orderType);
      setOrderTypeId(result.data.orderTypeId);
      setDeliveryDate(dayjs(result.data.deliveryDate, 'DD-MM-YYYY'));
      setCutOffDate(dayjs(result.data.cutOffDate, 'DD-MM-YYYY'));
      setOrderKind(result.data.emergencyOrder ? OrderKind.Emergency : OrderKind.Forecast);
      await fetchDeliverySchedules(result.data.orderTypeId, result.data.supplierNumber);

      const itemsResponse = await client.getItems({
        item: '',
        orderType: result.data.orderTypeId,
        storeNumber: selectedStore?.storeNumber,
        categoryId: -1,
        supplier: result.data.supplierNumber,
        pageNumber: 0,
        pageSize: 9999,
        sort: 'Name',
        direction: 'asc',
      });

      setItems(itemsResponse.data.items);
    } catch (error) {
      handleSnackbarOpen(t('fetchingErrorMessage') + ' ' + error, 'error');
    }
  };

  const disableUnavailableDates = (date: Dayjs) => {
    const today = dayjs();
    const dayjsDate = dayjs(date);

    if (isAutoShipOrder(orderTypeId)) {
      return true;
    }

    if (orderKind === OrderKind.Emergency) {
      return dayjsDate.isBefore(today, 'day') || dayjsDate.isSame(today, 'day');
    }
    return !deliverySchedules.some((schedule) =>
      dayjsDate.isSame(dayjs(schedule.deliveryDate, 'DD-MM-YYYY'), 'day'),
    );
  };

  const handleDeliveryDateChange = async (newDate: Dayjs | null) => {
    if (!newDate || orderKind === OrderKind.Emergency) {
      handleSnackbarOpen(t('deliveryChangeWarning'), 'warning');
      return;
    }
    const formattedDate = dayjs(newDate).format('DD-MM-YYYY');
    try {
      const orderResponse = await client.getOrderByDeliveryDate(selectedStore?.storeNumber, orderDetails?.orderTypeId ?? -1, formattedDate, orderDetails?.supplierNumber ?? '');
      if (orderResponse?.data) {
        navigate(`/store/order/${orderResponse.data.id}`);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchingErrorMessage') + ' ' + error, 'error');
    }
  };

  const loadPodStoreConfiguration = async () => {
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const response = await podClient.getStoreConfiguration(selectedStore.franchiseName, selectedStore.storeNumber);
        setIsPodEnabled(response.isPodEnabled);
      }
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData'), 'error');
    }
  };

  const loadPredictedDemands = async () => {
    setLoading(true);
    try {
      const parsedDate = dayjs(deliveryDate, 'DD-MM-YYYY');
      const dates = [parsedDate.format('DD-MM-YYYY')];
      const nextDate = deliverySchedules.find(x => parsedDate.isBefore(dayjs(x.deliveryDate, 'DD-MM-YYYY')));

      if (nextDate) {
        dates.push(nextDate.deliveryDate);
      }
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const predictions = await podClient.getPredictionsForItems(selectedStore.franchiseName, selectedStore.storeNumber, dates,  items.map(x => x.sku));
        await loadUomConversions(predictions);
      }
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const loadUomConversions = async (predictions: PredictedDemandModel[]) => {
    const itemNumbers = predictions.map(x => x.itemNumber);
    const availableItems = items.filter(x => itemNumbers.includes(x.sku));
    const response = await getConversions(undefined, undefined, undefined, undefined, selectedStore?.storeNumber);

    const conversions = response.conversions?.filter(conversion => {
      const item = availableItems?.find(i => i.sku === conversion?.itemId);
      return item && item.primaryUom === conversion?.fromUom && item.orderableUom === conversion.toUom;
    });

    predictions.forEach((x) => {
      const conversion = conversions?.find(c => c?.itemId === x.itemNumber);
      const conversionNumber = conversion?.conversionNumber ?? 1;

      x.predictedDemandItems = x.predictedDemandItems.map(({ quantity, ...rest }) => ({
        ...rest,
        quantity: Math.ceil(quantity / conversionNumber),
      }));
    });

    setPredictedDemands(predictions);
  };

  const handleSearch = () => {
    if (orderDetails) {
      const filtered = orderDetails.items.filter(item => {
        const matchesSearchText = productSearch === '' || item.sku.toLowerCase().includes(productSearch.toLowerCase()) || item.name.toLowerCase().includes(productSearch.toLowerCase());
        const matchesCategory = category === -1 || item.categoryId === category;
        return matchesSearchText && matchesCategory;
      });
      setFilteredItems(filtered);
    }
  };

  const handleDeleteOrderItem = async (orderId: number, orderItemId: number) => {
    if (!orderId || !orderItemId) return;
    setLoading(true);
    try {
      const result = await client.deleteOrderItem(selectedStore?.storeNumber, orderId.toString(), orderItemId.toString());
      if (result.success) {
        handleSnackbarOpen(t('deleteSuccess'), 'success');
        await fetchOrderDetails();
      } else {
        handleSnackbarOpen(t('deleteItemFail'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('deleteItemFail') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (itemId: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedItemIds((prev) => [...prev, itemId]);
    } else {
      setSelectedItemIds((prev) => prev.filter((id) => id !== itemId));
    }
  };

  const handleDeleteSelectedItems = () => {
    if (!orderDetails || selectedItemIds.length === 0) return;
    const selectedSKUs = orderDetails.items
      .filter(item => selectedItemIds.includes(item.id))
      .map(item => item.sku)
      .join(', ');
    setDialogState({
      open: true,
      title: t('deleteModalTitle'),
      message: `${t('deleteMultiModalMessage')}: ${selectedSKUs}?`,
      confirmAction: () => handleConfirmMultipleDeletion(),
    });
  };

  const handleConfirmMultipleDeletion = async () => {
    if (!orderDetails) return;
    setLoading(true);
    try {
      const request: DeleteOrderItemsRequest = {
        orderId: orderDetails.id,
        items: selectedItemIds,
      };
      const result = await client.deleteOrderItems(selectedStore?.storeNumber, request);
      if (result.success) {
        handleSnackbarOpen(t('multipleDeleteSuccess'), 'success');
        setSelectedItemIds([]);
        await fetchOrderDetails();
      } else {
        handleSnackbarOpen(t('multipleDeleteError'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('multipleDeleteError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
      setDialogState({ ...dialogState, open: false });
    }
  };

  const handleConfirmDeletion = async (itemId: number, itemNumber: number) => {
    if (itemId !== null) {
      if (itemQuantities[itemNumber]) {
        setItemQuantities((prev) => {
          const newQuantities = JSON.parse(JSON.stringify(prev)) as Record<number, number>;
          delete newQuantities[itemNumber];

          return newQuantities;
        });
      }
      await handleDeleteOrderItem(Number(id), itemId);
      setDialogState({ ...dialogState, open: false });
    }
  };

  const handleOpenDialogForDeletion = (itemId: number, itemNumber: number) => {
    setDialogState({
      open: true,
      title: t('deleteModalTitle'),
      message: t('deleteModalMessage'),
      confirmAction: () => handleConfirmDeletion(itemId, itemNumber),
    });
  };

  const handleConfirmAction = async () => {
    if (dialogState.confirmAction) {
      await dialogState.confirmAction();
    }
    setDialogState({ ...dialogState, open: false });
  };

  const handleCancelDialog = () => {
    setDialogState({ ...dialogState, open: false });
  };

  const clearFilters = () => {
    setProductSearch('');
    setCategory(-1);
    if (orderDetails) {
      setFilteredItems(orderDetails.items);
    }
  };

  const handleSort = async (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSubmitOrder = async () => {
    if (!selectedStore || !orderDetails) {
      handleSnackbarOpen(t('submitError'), 'error');
      return;
    }
    setLoading(true);
    const result = await client.submitOrder(selectedStore.storeNumber, orderDetails.id.toString(), orderDetails);
    if (result.success) {
      handleSnackbarOpen(t('submitSuccess'), 'success');
      await fetchOrderDetails();
    } else {
      handleSnackbarOpen(t('submitFail'), 'error');
    }
    setLoading(false);
  };

  const handleQuantityChange = (itemId: number, newQuantity: number) => {
    if (itemId && !modifiedItems.has(itemId)) {
      setModifiedItems(prev => new Set(prev.add(itemId)));
    }
    setItemQuantities(prevQuantities => ({
      ...prevQuantities,
      [itemId]: newQuantity,
    }));

  };

  const handleSaveChanges = async () => {

    if (!orderDetails) return;

    setLoading(true);

    const updatedItems = orderDetails.items.filter(x => x.id > 0 || x.id === 0 && itemQuantities[x.itemId] > 0).map(item => ({
      id: item.itemId,
      quantity: itemQuantities[item.itemId] ?? item.quantity,
      predictedQuantity: predictedDemands.find(x => x.itemNumber === item.sku)?.predictedDemandItems[0].quantity,
    }));

    const updatedOrder = {
      id: orderDetails.id,
      items: updatedItems,
    };

    try {
      const result = await client.updateOrder(selectedStore?.storeNumber, updatedOrder);
      if (result.success) {
        setModifiedItems(new Set());
        handleSnackbarOpen(t('updateSuccess'), 'success');
        await fetchOrderDetails();

      } else {
        setWarningMessage(result.message);
        setShowQuantityWarningDialog(true);
        fixQuantity();
        handleSnackbarOpen(t('updateError'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('updateError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleOrderCopyConfirm = async (orderId: number) => {
    setIsCopyOrderModalOpen(false);
    setLoading(true);
    try {
      const result = await client.copyOrder(selectedStore?.storeNumber, orderDetails?.id.toString(), orderId.toString());
      if (result.success) {
        handleSnackbarOpen(t('copySuccess'), 'success');
        await fetchOrderDetails();
      } else {
        handleSnackbarOpen(t('copyError'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('copyError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOrder = async () => {
    if (!selectedStore || !orderDetails) {
      handleSnackbarOpen(t('deleteError'), 'error');
      return;
    }
    setLoading(true);
    const result = await client.deleteOrder(selectedStore.storeNumber, orderDetails.id.toString());
    if (result.success) {
      navigate('/store/orders-history');
    } else {
      handleSnackbarOpen(t('deleteFail'), 'error');
    }
    setLoading(false);
  };

  const handleExitAndSave = async () => {
    setIsExitAndSaveClicked(true);
    if (!loading) {
      if (orderDetails?.status === OrderStatus.Editable) {
        await handleSaveChanges();
      }
      navigate('/store/orders-history');
    }
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event: unknown) => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getTotalFormattedPrice = (item: OrderItemDetails, orderConfirmed: boolean, currencyCode: string | undefined) => {
    const totalPrice = orderConfirmed ? (item.price * item.confirmedQuantity) : (item.price * item.quantity);
    return applyRoundAndFormat(totalPrice, currencyCode);
  };

  const handleShowPredictedQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPredictedQuantity(event.target.checked);
  };

  const matchPredictedQuantities = () => {
    setItemQuantities((prev) => {
      const newQuantities = JSON.parse(JSON.stringify(prev)) as Record<number, number>;
      orderDetails?.items.forEach((item) => {
        const predictedItem = predictedDemands.find(p => p.itemNumber === item?.sku);
        const predictedDemandItem = predictedItem?.predictedDemandItems?.at(0);

        if (!predictedDemandItem?.hasPrediction) {
          return;
        }

        newQuantities[item.itemId] = predictedDemandItem.quantity;
      });

      return newQuantities;
    });
  };

  const getAditionalItemsWithPredictions = () => {
    const podItems = predictedDemands.map(p => p.itemNumber);
    const orderItems = orderDetails?.items.map(p => p.sku) ?? [];
    const itemsToAdd = items.filter(x => podItems.includes(x.sku) && !orderItems.includes(x.sku)).map(x => {
      const orderItem: OrderItemDetails = {
        id: 0,
        itemId: x.id,
        sku: x.sku,
        name: x.name,
        primaryUom: x.primaryUom,
        orderableUom: x.orderableUom,
        price: x.price,
        categoryId: x.categoryId,
        category: x.category,
        stockOnHand: x.stockOnHand,
        stockOnOrder: x.stockOnOrder,
        quantity: 0,
        confirmedQuantity: 0,
        shippedQuantity: 0,
        imageId: x.imageId,
        thumbnailUrl: x.thumbnailUrl,
        packSize: x.packSize,
        maxQuantity: x.maxQuantity,
        minQuantity: x.minQuantity,
      };

      return orderItem;
    });

    return itemsToAdd;
  };

  const autocompleteOptions = [{ id: -1, name: 'All', parentName: '' }, ...(Array.isArray(categories) ? categories : [])];

  const canSubmitOrder = !loading && orderKind === OrderKind.Emergency && orderDetails?.status === OrderStatus.Editable && orderDetails.items.length > 0;

  const quantityError = (item: OrderItemDetails): string | null => {
    const quantity = itemQuantities[item.itemId] ?? item.quantity;

    if (quantity === 0 && item.id === 0) return null;

    if ((item.maxQuantity != null) && quantity > item.maxQuantity)
      return `${t('maxQuantityAllowedIs')}: ${item.maxQuantity}`;

    if ((item.minQuantity != null) && quantity < item.minQuantity)
      return `${t('minQuantityAllowedIs')}: ${item.minQuantity}`;

    return null;
  };

  const isQuantityError = (item: OrderItemDetails): boolean => {
    return quantityError(item) != null;
  };

  const anyErrors = (): boolean => {
    return filteredItems.some(x => isQuantityError(x));
  };

  const fixQuantity = () => {
    //shallow copy for state mang purposes
    const itemQuantities2 = { ...itemQuantities };
    for (const key in itemQuantities2) {
      const value = itemQuantities2[key];
      const maxQuant = orderDetails?.items.find(x => x.itemId.toString() == key)?.maxQuantity;
      const minQuant = orderDetails?.items.find(x => x.itemId.toString() == key)?.minQuantity;

      if (maxQuant && value > maxQuant)
        itemQuantities2[key] = maxQuant;
      if (minQuant && value < minQuant)
        itemQuantities2[key] = minQuant;
    }
    setItemQuantities(itemQuantities2);
  };

  return (
    <PageArea>
      <Grid item
        xs={12}
        mb={10}
        sx={{ textAlign: 'left' }}>
        <BackButton
          handleClick={handleExitAndSave}
          title={t('exitAndSave')}
          isLink={false}
        />
      </Grid>
      <Grid container>
        <Grid item
          container
          xs={12}>
          <Grid item
            container
            spacing={5}
            alignItems={'end'} >
            <Grid item
              xs={12}
              md={6}>
              <PageTitle>{t('order')} {id}</PageTitle>
              <Grid container
                alignItems={'center'}>
                <Grid item>
                  <Typography mr={2}
                    sx={{ fontSize: '18px' }}>
                    {t('status')}
                  </Typography>
                </Grid>
                <Grid item>
                  <OrderStatusChip orderStatus={orderDetails?.status ?? ''}
                    size='medium' />
                </Grid>
              </Grid>
              {orderKind === OrderKind.Forecast && (
                <Typography mt={5}
                  sx={{ fontSize: '18px' }}>{t('timeToOrderCutoff')} <strong>{orderDetails?.cutOffTimeLeft}</strong></Typography>
              )}
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="bad"
                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                size="lg"
                fullWidth
                onClick={handleDeleteOrder}
                startIcon={<DeleteIcon />}>
                {t('deleteOrder')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="primary"
                disabled={loading || orderDetails?.status !== OrderStatus.Editable || anyErrors()}
                size="lg"
                fullWidth
                startIcon={<SaveIcon />}
                onClick={handleSaveChanges}
              >
                {t('save')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="primary"
                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                onClick={() => navigate(`/store/quick-order/${id}`)}
                size="lg"
                fullWidth
                startIcon={<Plus />}>
                {t('addItems')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              sx={{ textAlign: 'right', mr: '8px' }}>
              <Typography variant='textLG'
                sx={{
                  visibility: showAutosaveMessage ? 'visible' : 'hidden',
                }}
              >
                {t('changesSaved')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item
          container
          xs={12}
          mt={5}
          sx={{
            border: '1px solid',
            borderColor: theme.palette.custom.gray[300],
          }}>
          <Grid container
            sx={{
              padding: '20px',
            }}
            spacing={2}>
            <Grid item
              xl={3}
              md={isMobile ? 12 : 7}
              xs={isMobile ? 12 : 6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 0 }} >
              <Box>
                <DatePickerLocalized
                  label=''
                  useCalendar={true}
                  valueForDateCalendar={deliveryDate}
                  onChangeDateCalendar={handleDeliveryDateChange}
                  shouldDisableDateCalendar={disableUnavailableDates}
                  disabled={loading || orderKind === OrderKind.Emergency}
                  disableHighlightToday={true}
                />
              </Box>

            </Grid>
            <Grid item
              xl={9}
              md={isMobile ? 12 : 5}
              xs={isMobile ? 12 : 6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 0 }}
            >
              <Grid container
                spacing={2}>
                <Grid item
                  xl={9}
                  md={12}
                  xs={12}>
                  <Box>
                    <Grid container
                      spacing={2}>
                      <Grid item
                        xl={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && !isMobile ? 4 : 6}
                        md={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && !isMobile ? 6 : 12}
                        xs={12}
                        p={3}>
                        <TextField
                          fullWidth
                          size="small"
                          label={t('orderType')}
                          disabled
                          value={t(OrderTypeTranslationMap[orderType as keyof typeof OrderTypeTranslationMap]) || orderType}
                        />
                      </Grid>
                      {orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && (
                        <Grid item
                          xl={4}
                          md={!isMobile ? 6 : 12}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            label={t('supplier')}
                            disabled
                            value={orderDetails?.supplierName}
                          />
                        </Grid>
                      )}
                      <Grid item
                        xl={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery ? 4 : 6}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography component="div">
                          <Grid component="label"
                            container
                            alignItems="center"
                            spacing={1}>
                            <Grid item>{t('emergencyOrder')}</Grid>
                            <Grid item>
                              <Switch
                                checked={orderKind === OrderKind.Emergency}
                                name="orderKindSwitch"
                                readOnly
                                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                              />
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <DateField
                          label={t('deliveryDate')}
                          disablePast={true}
                          disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                          readOnly
                          value={deliveryDate}
                        />
                      </Grid>
                      <Grid item
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <DateField
                          label={t('cutOffDate')}
                          disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                          readOnly
                          value={cutOffDate}
                        />
                      </Grid>
                      {
                        isPodEnabled && orderDetails?.status === OrderStatus.Editable &&
                        <>
                          <Grid item
                            xs={6}>
                            <FormControlLabel control={
                              <Switch checked={showPredictedQuantity}
                                onChange={handleShowPredictedQuantityChange} />
                            }
                            label={t('showPredictedQuantities')} />
                          </Grid>
                          {
                            showPredictedQuantity &&
                            <Grid item
                              xs={6}>
                              <Button fullWidth
                                variant="primary"
                                disabled={loading}
                                size="lg"
                                startIcon={<MagicWand01 />}
                                onClick={matchPredictedQuantities}>
                                {t('matchPredictedQuantities')}
                              </Button>
                            </Grid>
                          }
                        </>
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid item
                  xl={3}
                  md={12}
                  xs={12}>
                  <Box>
                    <Grid container
                      spacing={2}>
                      <Grid item
                        xs={6}
                        p={3}>
                        <Button
                          variant="primary"
                          disabled={loading}
                          size="lg"
                          fullWidth
                          startIcon={<SearchSm />}
                        >
                          {t('viewAudit')}
                        </Button>
                      </Grid>
                      <Grid item
                        xs={6}>
                        <Button
                          variant="primary"
                          disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                          size="lg"
                          fullWidth
                          startIcon={<ContentCopyIcon />}
                          onClick={() => setIsCopyOrderModalOpen(true)}
                        >
                          {t('copyOrder')}
                        </Button>
                      </Grid>
                      <Grid item
                        xs={12}>
                        <Button
                          variant="primary"
                          disabled={!canSubmitOrder}
                          onClick={handleSubmitOrder}
                          size="lg"
                          fullWidth
                        >
                          {t('submitOrder')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item
            xs={12}>
            <Divider />
          </Grid>
          <Grid item
            container
            sx={{
              px: 4,
              py: 6,
            }}
            xs={12}
            spacing={5}>
            <Grid item
              xs={12}
              md={3}>
              <TextField
                fullWidth
                size='small'
                placeholder={t('searchByItemNameOrSKU')}
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon><SearchSm /></SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item
              xs={12}
              md={3}>
              <Autocomplete
                size='small'
                options={autocompleteOptions}
                getOptionLabel={(option) => isOrderCategory(option.name) ? t(OrderCategoriesTranslationMap[option.name as keyof typeof OrderCategoriesTranslationMap]) : option.name}
                value={categories.find(cat => cat.id === category) ?? autocompleteOptions[0]}
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue.id : -1);
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('category')}
                    placeholder={t('choose')} />
                )}
              />
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="bad"
                disabled={loading || selectedItemIds.length === 0 || orderDetails?.status !== OrderStatus.Editable}
                size="lg"
                fullWidth
                onClick={handleDeleteSelectedItems}
                startIcon={<DeleteIcon />}
              >
                {t('deleteSelected')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button fullWidth
                variant="secondary"
                disabled={loading}
                onClick={clearFilters}
                size="lg">
                {t('clearFilters')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button fullWidth
                variant="primary"
                disabled={loading}
                onClick={handleSearch}
                size="lg"
                startIcon={<SearchSm />}>
                {t('search')}
              </Button>
            </Grid>
          </Grid>
          <Grid item
            xs={12}>
            {loading ? (
              <LoadingWheel />
            ) : (
              <TableContainer
                component={'div'}
                sx={{
                  overflowX: 'auto',
                  maxWidth: '100%',
                }}
              >
                <Table
                  size="small"
                  aria-label="a table"
                  sx={{
                    minWidth: '700px',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '60%' }}>
                        {t('itemDetails')}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        <TableSortLabel
                          active={orderBy === 'Ordered'}
                          direction={order}
                          onClick={() => handleSort('Ordered')}
                          sx={{
                            transform: 'translate(15%)',
                          }}
                        >
                          {t('ordered')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '9%' }}>
                        <TableSortLabel
                          active={orderBy === 'Confirmed'}
                          direction={order}
                          onClick={() => handleSort('Confirmed')}
                          sx={{
                            transform: 'translate(15%)',
                          }}
                        >
                          {t('confirmed')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center"
                        sx={{ verticalAlign: 'middle', fontWeight: 'bold', width: '9%' }}>
                        <TableSortLabel
                          active={orderBy === 'Shipped'}
                          direction={order}
                          onClick={() => handleSort('Shipped')}
                          sx={{
                            transform: 'translate(20%)',
                          }}
                        >
                          {t('shipped')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '9%' }}>
                        <TableSortLabel
                          active={orderBy === 'Cost'}
                          direction={order}
                          onClick={() => handleSort('Cost')}
                          sx={{
                            transform: 'translate(10%)',
                          }}
                        >
                          {t('cost')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '9%' }}>
                        {t('actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredItems.map((item) => {
                      const quantity = itemQuantities[item.itemId] || item.quantity;
                      const predictedItem = predictedDemands.find(x => x.itemNumber === item.sku);
                      const predictedDemandItem = predictedItem?.predictedDemandItems?.at(0);
                      const showPrediction = showPredictedQuantity && predictedDemandItem?.hasPrediction;
                      const predictedQuantity = predictedDemandItem?.quantity;
                      const borderColor = showPrediction && predictedQuantity === quantity ? theme.palette.success[400] : '';
                      const maxOrderQuantity = predictedItem?.predictionInfo?.maxOrderQuantity;
                      const safetyStock = predictedItem?.predictionInfo?.safetyStock;
                      const predictedDemandQuantity = predictedDemandItem?.predictedDemandQuantity;
                    
                      return (
                        <TableRow key={item.itemId}
                          hover
                          onFocus={() => handleItemFocus(item.itemId)}>
                          <TableCell sx={{ width: '60%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Grid container
                              alignItems={'center'}
                              spacing={1}
                              wrap="nowrap">
                              <Grid item
                                xs="auto">
                                <Checkbox
                                  checked={selectedItemIds.includes(item.id)}
                                  onChange={(event) =>
                                    handleCheckboxChange(item.id, event.target.checked)
                                  }
                                  disabled={
                                    loading || orderDetails?.status !== OrderStatus.Editable
                                  }
                                />
                              </Grid>
                              <Grid item
                                xs="auto">
                                <img
                                  style={{
                                    objectFit: item.thumbnailUrl ? 'contain' : 'cover',
                                    width: '150px',
                                    height: isLargeScreen ? '75px' : '150px',
                                    borderRadius: '8px',
                                    border: '1px solid #ddd',
                                    backgroundColor: 'white',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    display: 'block',
                                    margin: '0',
                                    padding: '0',
                                  }}
                                  alt="item-thumbnail"
                                  src={
                                    item.thumbnailUrl
                                      ? item.thumbnailUrl
                                      : 'https://placehold.co/150x150'
                                  }
                                  loading="lazy"
                                />
                              </Grid>
                              <Grid item
                                sx={{ minWidth: 0, paddingLeft: '10px !important', flex: 1 }}>
                                {isLargeScreen ? (
                                  <Grid container
                                    spacing={2}>
                                    <Grid item
                                      xs={6}>
                                      <TextWithOverflowTooltip text={item.name} />
                                      <TextWithOverflowTooltip text={item.sku} />

                                      <TextWithOverflowTooltip text={isOrderCategory(item.category)
                                        ? t(OrderCategoriesTranslationMap[item.category as keyof typeof OrderCategoriesTranslationMap])
                                        : item.category} />
                                      {(showMessageWhenNoCost || item.price === 0) ?
                                        <TextWithOverflowTooltip text='' />
                                        :
                                        <TextWithOverflowTooltip text={applyRoundAndFormat(item.price, currencyCode)} />
                                      }
                                    </Grid>
                                    <Grid item
                                      xs={6}>
                                      <TextWithOverflowTooltip text={`${t('orderableUOM')} ${item.orderableUom} (${item.packSize}${item.primaryUom})`} />
                                      <TextWithOverflowTooltip text={`${t('primaryUOM')} ${item.primaryUom}`} />
                                      <Tooltip title={`${t('stockOnHand')}: ${item.stockOnHand}`}
                                        arrow>
                                        <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                          {t('stockOnHandABR')} {item.stockOnHand}
                                        </Box>
                                      </Tooltip>
                                      <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                                        arrow>
                                        <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                          {t('StockOnOrderABR')} {item.stockOnOrder}
                                        </Box>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <>
                                    {item.name}  <br />
                                    {item.sku}   <br />
                                    {isOrderCategory(item.category)
                                      ? t(OrderCategoriesTranslationMap[item.category as keyof typeof OrderCategoriesTranslationMap])
                                      : item.category} <br />
                                    {(showMessageWhenNoCost || item.price === 0) ?
                                      '' :
                                      applyRoundAndFormat(item.price, currencyCode)
                                    }
                                    <br />
                                    {t('orderableUOM')} {item.orderableUom} ({item.packSize}{item.primaryUom}) <br />
                                    {t('primaryUOM')} {item.primaryUom}  <br />
                                    <Tooltip title={`${t('stockOnHand')}: ${item.stockOnHand}`}
                                      arrow>
                                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                        {t('stockOnHandABR')} {item.stockOnHand}
                                      </Box>
                                    </Tooltip>
                                    <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                                      arrow>
                                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                        {t('StockOnOrderABR')} {item.stockOnOrder}
                                      </Box>
                                    </Tooltip>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Box className="product-quantity-container"
                              sx={{ display: 'flex', position: 'relative', minWidth: 100 }}>
                              <TextField
                                label={(showPrediction && orderDetails?.status === OrderStatus.Editable) ? t('predictedQuantity', { value: predictedQuantity }) : null}
                                error={isQuantityError(item)}
                                helperText={quantityError(item)}
                                size="small"
                                type="number"
                                autoComplete='off'
                                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                                onChange={(e) => handleQuantityChange(item.itemId, Math.abs(parseInt(e.target.value || '0')))}
                                value={quantity}
                                sx={{
                                  width: '100%',
                                  input: {
                                    textAlign: 'center',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: borderColor,
                                    },
                                  },

                                  '& .MuiInputLabel-root': {
                                    color: borderColor,
                                  },
                                }}
                              ></TextField> 
                              {
                                showPrediction && orderDetails?.status === OrderStatus.Editable &&
                                <Tooltip title={
                                  <span>
                                    {
                                      predictedDemandQuantity != null && (
                                        <>
                                          {t('predictedDemandQuantity', { value: predictedDemandQuantity, uom: item.primaryUom })}
                                          <br />
                                        </>
                                      )
                                    }
                                    {
                                      maxOrderQuantity != null && (
                                        <>
                                          {t('maxOrderQuantity', { value: maxOrderQuantity, uom: item.primaryUom })}
                                          <br />
                                        </>
                                      )
                                    }
                                    {safetyStock != null && t('safetyStock', { value: safetyStock, uom: item.primaryUom })}
                                  </span>
                                }>
                                  <IconButton onClick={() => handleQuantityChange(item.itemId, Number(predictedQuantity))}>
                                    <MagicWand01
                                      color={predictedQuantity === quantity ? theme.palette.success[700] : theme.palette.custom.gray[400] }/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </Box>
                          </TableCell>
                          <TableCell align="center"
                            sx={{ verticalAlign: 'middle' }}>{item.confirmedQuantity}</TableCell>
                          <TableCell align="center"
                            sx={{ verticalAlign: 'middle' }}>{item.shippedQuantity}</TableCell>
                          <TableCell align="center"
                            sx={{ verticalAlign: 'middle' }}>
                            {getTotalFormattedPrice(item, orderConfirmed!, currencyCode)}
                          </TableCell>
                          <TableCell align="center"
                            sx={{ verticalAlign: 'middle' }}>
                            <Tooltip title={item.id === 0 ? t('cannotRemoveItem') : ''}>
                              <span>
                                <IconButton
                                  color="error"
                                  disabled={loading || orderDetails?.status !== OrderStatus.Editable || item.id === 0}
                                  onClick={() => handleOpenDialogForDeletion(item.id, item.itemId)}
                                >
                                  <DeleteIcon  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    },
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
      <QuantityWarningDialog
        setShowQuantityWarningDialog={setShowQuantityWarningDialog}
        message={warningMessage}
        showDialog={showQuantityWarningDialog} />
      <ConfirmationDialog
        open={dialogState.open}
        icon={<DeleteIcon />}
        title={dialogState.title}
        message={dialogState.message}
        confirmButtonText={t('confirm')}
        cancelButtonText={t('cancel')}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelDialog}
      />
      <CopyOrderModal
        currentOrderId={orderDetails?.id ?? 0}
        orderTypeId={orderDetails?.orderTypeId ?? 0}
        open={isCopyOrderModalOpen}
        onClose={() => setIsCopyOrderModalOpen(false)}
        onConfirm={handleOrderCopyConfirm}
      />
      <Snackbar open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <LeavePageModal
        open={blocker.state === 'blocked' && modifiedItems.size > 0}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onCancel={handleLeavePageModalCancel}
        onConfirmUrl='/store/order'
        leaveWarningContent={t('saveOnLeaveWarningContent')}
        leaveWarningTitle={t('saveOnLeaveWarningTitle')}
      />
    </PageArea >
  );
};

export default OrderDetailsPage;
