import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {  Button, Grid, TextField, Typography, FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, TableCell, TableRow, TableContainer, Table, TableHead, TableBody, TableSortLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Alert, SvgIcon, Box, useMediaQuery, Paper } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { theme } from '../../../theme';
import { getFormattedDate } from '../../../utils';
import { useTranslation } from 'react-i18next';
import CustomPagination from '../../../components/forms/Pagination';
import {  useNavigate  } from 'react-router-dom';
import { StockCheckStatus , StockCheck } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import { StockCheckStatus as StockCheckStatusEnum } from '../../../enums/StockCheckStatus';
import { ActiveTab } from '../../../enums/ActiveTab';
import { Trash01, SearchSm } from  '../../../assets';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';

interface Status {
  index: number | string,
  description: string,
  active?: boolean | null,
}

interface TabState {
  activeTab: ActiveTab;
}

const StockCountPage : React.FC<TabState> = ({ activeTab })=> {
  const { t } = useTranslation('common');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<StockCheck[] >([]);
  const [count, setCount] = useState<number | undefined>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<StockCheckStatus>(0);
  const { getStockCheck } = useNswagClient();
  const { deleteStockCheck } = useNswagClient();
  const navigate = useNavigate();
  const [currentRowId, setCurrentRowId] = useState<number>(0);
  const [showAlert, setShowAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string | Date | number>();

  const allStatuses: Status[] = [
    { index: 0, description: t('stockCountZonesDdlAll') },
    { index: 3, description: 'Pending', active: true },
    { index: 1, description: 'Counting', active: true },
    { index: 4, description: 'Pending Approval', active: true },
    { index: 2, description: 'Completed', active: false },
  ];
  
  useEffect(() => {
    getData();
  }, [ page, currentTab, reset]);

  useEffect(() => {
    setLoading(true);
    if(activeTab === ActiveTab.Inactive){
      setSelectedStatus(2);
      setCurrentTab(true);
    }
    if(activeTab ===  ActiveTab.Active) {
      setSelectedStatus(0);
      setCurrentTab(false);
    }
    setPage(0);
  },[activeTab]);
 
  const getData = () => {
    const storeNumber = selectedStore?.storeNumber;
    const skip = rowsPerPage * page;
    const status = selectedStatus !== 0 ? selectedStatus : undefined;
    getStockCheck(storeNumber, searchTerm, selectedDate, status, skip, rowsPerPage).then((result) => {
      if (result) {  
        const finaldata  = result.data?.data;
        if(finaldata){
          setData(finaldata);
          setLoading(false);
        }
        setCount(result.data?.totalCount);
      } else {
        console.error('Invalid API response:', result);
      }})
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const searchStock = () => {
    if(page === 0){
      getData();
    } else{
      setPage(0);
    }
  };
  
  const onDeleteConfirm = async ( event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number ) =>{
    await deleteStockCheck(id);
    setOpen(false);
    getData();
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  const getStatus = (status: StockCheckStatus | undefined): string => {
    if (status === undefined) {
      return '';
    }
    const filteredStatus = allStatuses.filter(e => e.index == status);
    return filteredStatus.length === 0 ? '' : filteredStatus[0].description;
  };

  const comparator = (property: keyof StockCheck) => (a: StockCheck, b: StockCheck): number => {
    const isAsc = orderBy === property && order === 'asc';
    if (typeof a[property] === 'string' || a[property] instanceof Date) {
      return isAsc
        ? String(a[property]).localeCompare(String(b[property]))
        : String(b[property]).localeCompare(String(a[property]));
    } else {
      return isAsc ? Number(a[property]) - Number(b[property]) : Number(b[property]) - Number(a[property]);
    }
  };

  const toSort = (arr: StockCheck[], compareFn: (a: StockCheck, b: StockCheck) => number): StockCheck[] => {
    return arr.slice().sort(compareFn);
  };
  
  const handleSort = (property: keyof StockCheck) => {
    const isAsc = orderBy === property && order === 'asc';
    if(data){
      const sortedData = toSort(data, comparator(property));
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setData(sortedData);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent< StockCheckStatus>, child: ReactNode) => {
    setSelectedStatus(event.target.value as StockCheckStatus );
  };
  
  const clearAll = () => {
    setPage(0);
    setSearchTerm('');
    setSelectedDate('');
    setSelectedStatus(activeTab === ActiveTab.Inactive ? StockCheckStatusEnum.Completed : 0);
    setReset(!reset);
  };

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>, rowId: number, status: number) => {
    const id = rowId.toString();

    if (status === StockCheckStatusEnum.PendingApproval || status === StockCheckStatusEnum.Completed) {
      navigate(`/store/review-stock-count/${id}`);
    }
    if (status === StockCheckStatusEnum.Pending || status === StockCheckStatusEnum.Counting) {
      navigate(`/store/perform-stock-count/${id}`);
    }
  };
 
  const handleOnDelete = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, rowId: number) => {
    event.stopPropagation();

    setCurrentRowId(rowId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const maxPages = () => count ? Math.ceil(count / rowsPerPage) : 1;
  
  return (
    <Paper>
      <Grid
        container
        spacing={2}
        display='flex'
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        alignItems='self-end'
        p={6}
      >
        <Grid item
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'  }}>
          <Typography variant='textSM'>{t('stockcounts.search')}</Typography>
          <TextField
            fullWidth
            placeholder={t('searchByItemNumberOrDescription')}
            id="input-with-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon><SearchSm/></SvgIcon>
                </InputAdornment>
              ),
            }}
            value ={searchTerm}
            onChange={handleSearchChange}
          />  
        </Grid>
        <Grid item
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}> 
          <Typography variant='textSM'>{t('stockcounts.date')}</Typography>
          <DatePickerLocalized
            useCalendar = {false}
            value={selectedDate}
            translations={{
              clearButtonLabel: t('calendar.clearButtonLabel'),
              todayButtonLabel: t('calendar.todayButtonLabel'),
              cancelButtonLabel: t('calendar.cancelButtonLabel'),
              okButtonLabel: t('calendar.okButtonLabel'), 
              placeholder: t('calendar.placeholder'),
            }}
            onChange={(newValue) => {
              if (newValue) {
                setSelectedDate(newValue);
              } 
            }}
          />  
        </Grid>
        <Grid item
          md={12}
          sm ={12}
          xs={12}
          visibility={activeTab === ActiveTab.Active ? 'visible' : 'hidden'}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}> 
          <Typography variant='textSM'>{t('stockcounts.status')}</Typography>
          <FormControl>
            <Select
              value={selectedStatus}
              onChange={handleStatusChange}
              fullWidth
            >
              {allStatuses.filter(e => e.index !== 2).map((status) => (
                <MenuItem key={status.index}
                  value={status.index}>{status.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item
          xs={12}
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: isMobile ? 'space-between' : 'flex-end' }}>
          <Button
            variant="secondary"
            size="lg"
            onClick={clearAll}
            sx={{ px:10, mr: '10px' }}
          >
            {t('stockcounts.clearall')}
          </Button>
          <Button
            variant="primary"
            size="lg"
            onClick={searchStock}
            startIcon={<SearchSm/>}
            sx={{ px: 9 }}
          >
            {t('search')}
          </Button>
        </Grid>
      </Grid>
      {showAlert && (
        <Alert severity="success">{t('stockcounts.successalert')}</Alert>
      )}
      <Grid container
        item
        xs={12}> 
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('stockcounts.confirmation')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('stockcounts.message')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              variant="secondary"
              onClick={handleClose}> {t('stockcounts.cancel')}</Button>
            <Button 
              variant="bad"
              startIcon={<SvgIcon><Trash01/></SvgIcon>}
              onClick={(event) => onDeleteConfirm(event, currentRowId) }>
              {t('stockcounts.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
        {loading ? <LoadingWheel />:
          <Grid 
            sx={{ width:'100%' }}>
            <Box sx={{
              border: '1px solid',
              borderColor: theme.palette.custom.gray[200],
            }}>
              <TableContainer component={'div'}
              >
                <Table
                  size="small"
                  aria-label="a table"
                >
                  <TableHead>
                    <TableRow >
                      <TableCell>
                        {t('stockcounts.author')}
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'name'}
                          direction={order}
                          onClick={() => handleSort('name')}
                        >
                          {t('stockcounts.name')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'status'}
                          direction={order}
                          onClick={() => handleSort('status')}
                        >
                          {t('stockcounts.status')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell> 
                        <TableSortLabel
                          active={orderBy === 'plannedCountDate'}
                          direction={order}
                          onClick={() => handleSort('plannedCountDate')}
                        >
                          {t('stockcounts.dates')}
                        </TableSortLabel>
                      </TableCell>
                      {
                        hasPermissionTo(['StockCountDelete']) &&
                          <TableCell>
                            {t('stockcounts.remove')}
                          </TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row: StockCheck) => (
                      <TableRow
                        hover
                        key={row.id}
                        onClick={(event) => handleRowClick(event, row?.id ?? 0, row?.status ?? 0)}
                      >
                        <TableCell>
                          <AccountCircleIcon titleAccess={row.createdBy ?? ''}/>
                        </TableCell>
                        <TableCell>
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {getStatus(row.status)}
                        </TableCell>
                        <TableCell>
                          {(row.plannedCountDate === undefined ? '' : getFormattedDate(new Date(row.plannedCountDate)))}
                        </TableCell>
                        {
                          hasPermissionTo(['StockCountDelete']) &&
                          <TableCell> 
                            <Trash01
                              color={theme.palette.error[500]}
                              onClick={($event) => handleOnDelete($event, row.id ?? 0)} />
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!count  ? (
                <Box sx={{ pt:'20px', pb:'20px',display:'flex', justifyContent:'center' }}>
                  <Typography variant="textSM">
                    {t('stockcounts.noData')}
                  </Typography>
                </Box>
              ) : (
                <CustomPagination 
                  page={page}
                  setPage={setPage}
                  maxPages={maxPages()}
                  breakpointForChangeDisplay={120000} />
              )}
            </Box>
          </Grid>}
      </Grid>
    </Paper>
  );
};
export default StockCountPage;
