import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SubmitModalProps {
  openModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const SubmitModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, onCancel }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('stockCount');
  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleCancel = () => {
    onCancel();
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ p: 0 }}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {t('submitModal.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[200],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {t('submitModal.message')}
        </Typography>
        <Typography variant="textMD"
          sx={{ my: 4, mx: 6 }}>
          {t('submitModal.question')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='bad'
          size="lg"
          onClick={handleCancel}>
          {t('submitModal.cancel')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          onClick={handleConfirm}>
          {t('submitModal.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SubmitModal;

