import { Alert, Button, Grid, SvgIcon, TextField } from '@mui/material';
import ManageNotificationsTable from './ManageCategoriesTable';
import { PrimaryCategory, PrimaryCategoryStockCount } from '../../../app/services/api/generated';
import { useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { Plus, SearchSm } from '../../../assets';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';
import CreateEditModal from './CategoriesEditCreateModal';
import { theme } from '../../../theme';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { CompareArrows } from '@mui/icons-material';

const MainBox = (props: { isSalesConfigEnabled: boolean, isDailyWasteConfigEnabled: boolean, categories: PrimaryCategoryStockCount[], loadData: () => void }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { savePrimaryCategory, applyCategories } = useNswagClient();
  const { t } = useTranslation('common');
  const { t: ct }  = useTranslation('manageCategories');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [selectedStore, defaultStoreValue] =  useLocalStorage('selectedStore', null);
  const [transformedData, setTransformedData] = useState<PrimaryCategory[]>(props.categories ?? []);
  const [page, setPage] = useState(0);

  const handleCreate = (body: PrimaryCategoryStockCount) => {
    body.itemPrimaryCategoryOverrides = body.itemPrimaryCategoryOverrides ?? [];
    savePrimaryCategory({ ...body, franchiseId: selectedStore?.franchiseId ?? defaultStoreValue }).then(props.loadData);
  };

  const handleSearch = () => {
    setPage(0);
    const filteredData = props.categories.filter(x => 
      x.primaryCategoryName?.toLowerCase().includes(searchTerm.toLowerCase()));
    setTransformedData(filteredData);
  };

  const handleClear = () => {
    setPage(0);
    setSearchTerm('');
    props.loadData();
  };

  const apply = () => {
    applyCategories(selectedStore?.storeNumber ?? defaultStoreValue).then(props.loadData);
  };

  return <><Grid item
    xs={12}
    container
    justifyContent="flex-end">
    <Button variant="secondary"
      size="lg"
      startIcon={<SvgIcon> <CompareArrows fill='white' /> </SvgIcon>}
      onClick={() => apply()}>
      {ct('applyCategories')}
    </Button>
    <Button variant="primary"
      size="lg"
      startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
      sx={{ ml: { sm: 4 } }}
      onClick={() => setShowCreateModal(true)}>
      {t('createNew')}
    </Button>
  </Grid>
  <Grid item
    container
    mt={5}
    xs={12}
    sx={{
      p: '20px',
      border: '1px solid',
      borderColor: theme.palette.custom.gray[300],
    }}>
    <Grid item
      xs={12}
      sm={6}
      md={3}
      p={5}>
      <TextField
        fullWidth
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        size='small'
        label={t('search')} />
    </Grid>
    <Grid item
      xs={12}
      sm={6}
      md={3}
      p={5}>
      <Button fullWidth
        variant="secondary"
        size="lg"
        onClick={() => handleClear()}
      >
        {t('clearFilters')}
      </Button>
    </Grid>
    <Grid item
      xs={12}
      sm={6}
      md={3}
      p={5}>
      <Button fullWidth
        variant="primary"
        size="lg"
        onClick={() => handleSearch()}
        startIcon={<SearchSm />}
      >
        {t('search')}
      </Button>
    </Grid>
    <Grid item
      xs={12}
      sm={12}
      md={12}
      p={5}>
      <Alert severity='warning'>
        {ct('warningOne')}
      </Alert>
      <Alert severity='warning'>
        {ct('warningTwo')}
      </Alert>
    </Grid>
    <ManageNotificationsTable list={transformedData}
      categories={props.categories}
      setPage={setPage}
      isSalesConfigEnabled={props.isSalesConfigEnabled}
      isDailyWasteConfigEnabled={props.isDailyWasteConfigEnabled}
      page={page}
      loadData={props.loadData}></ManageNotificationsTable>
  </Grid>
  <CreateEditModal openModal={showCreateModal}
    onConfirm={(requestBody: PrimaryCategoryStockCount) => handleCreate(requestBody)}
    category={undefined}
    onCancel={() => setShowCreateModal(false)}></CreateEditModal></>;
};

const ManageCategories = (props: { isSalesConfigEnabled: boolean, isDailyWasteConfigEnabled: boolean, categories: PrimaryCategoryStockCount[], loadData: () => void, isLoading: boolean }) => {
  return props.isLoading ? <LoadingWheel></LoadingWheel> : <MainBox 
    isSalesConfigEnabled={props.isSalesConfigEnabled}
    isDailyWasteConfigEnabled={props.isDailyWasteConfigEnabled}
    categories={props.categories}
    loadData={props.loadData}></MainBox>;
};

export default ManageCategories;