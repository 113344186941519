import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  Box,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { LocationResponse, StockCheckDetails, StockCheckItem } from '../../../app/services/api/generated';
import { BarcodeScannerModal } from './BarcodeScanner/BarcodeScannerModal';
import StockCountQuantityInput from './StockCountQuantityInput';
import OnlineOfflineSnackbar from './OnlineOfflineSnackbar';
import { StockCheckStatus } from '../../../enums/StockCheckStatus';
import useNswagClient from '../../../hooks/api/useNswagClient';
import useLogError from '../../../hooks/useLogError';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';


interface StockCheckDetailsProps {
  stockCheckDetails: StockCheckDetails,
  isScannerModalOpen: boolean,
  zonesData: LocationResponse[],
  selectedZoneId: string,
  handleSelectedZoneChange: (event: SelectChangeEvent<string>) => void;
  allZonesEnabled: boolean,
  closeModal: () => void
  handleSavingDataUserBackOnline: () => void;
  // eslint-disable-next-line no-unused-vars
  handleAutosaveMessage: (changesSaved: boolean) => void;
  handleCloseSaveAlert: () => void;
  showSavedAlert: boolean
  onModifiedItemsChange: (modifiedItems: Set<number>) => void;
}

export interface TempCategoryItem {
  name: string;
  items: StockCheckItem[];
}

const StockCountDetails = forwardRef<{ getUpdatedValues: () => { scheduledDate: string, stockCheckItems: StockCheckItem[] } | null }, StockCheckDetailsProps>(({ stockCheckDetails, isScannerModalOpen, closeModal, zonesData, selectedZoneId, handleSelectedZoneChange, allZonesEnabled, handleSavingDataUserBackOnline, handleAutosaveMessage, handleCloseSaveAlert, showSavedAlert, onModifiedItemsChange }, ref) => {
  const { t } = useTranslation('common');
  const stockCheckDetail = stockCheckDetails.stockCheck;
  const [scheduledDate, setScheduledDate] = useState<string>(stockCheckDetail?.plannedCountDate?.split('T')[0] ?? '');
  const [stockCheckItems, setStockCheckItems] = useState<StockCheckItem[]>(stockCheckDetail?.items ?? []);
  const [modifiedItems, setModifiedItems] = useState<Set<number>>(new Set());
  const [lastFocusedItemId, setLastFocusedItemId] = useState<number | null>(null);
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);
  const { saveStockCheckItem, saveStockCheckItemAtLocation } = useNswagClient();
  const { logError } = useLogError();

  const INACTIVITY_TIMEOUT = 60000; // 1 min on milliseconds

  useEffect(() => {
    if (modifiedItems.size > 0) {
      handleAutosaveMessage(false);
      startInactivityTimer();
    }
  }, [modifiedItems]);

  const startInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    const timer = setTimeout(() => {
      if (modifiedItems.size > 0) {
        modifiedItems.forEach(itemId => {
          autosaveItem(itemId);
        });
      }
    }, INACTIVITY_TIMEOUT);
    setInactivityTimer(timer);
  };

  const handleItemFocus = (itemId: number) => {
    if (lastFocusedItemId !== null && lastFocusedItemId !== itemId && modifiedItems.has(lastFocusedItemId)) {
      autosaveItem(lastFocusedItemId);
    }
    setLastFocusedItemId(itemId);
  };

  const notifyParentFormChanged = (modifiedItemsTemp: Set<number>) => {
    onModifiedItemsChange(modifiedItemsTemp);
  };

  const autosaveItem = (itemId: number) => {
    // Find the item by id and perform autosave logic
    const itemToSave = stockCheckItems.find(item => item.id === itemId);

    if (itemToSave) {
      handleItemAutosave(itemToSave);

    }
    else {
      handleAutosaveMessage(false);
    }
  };

  const handleItemAutosave = (itemToSave: StockCheckItem) => {

    if (selectedZoneId == '-1') {
      saveStockCheckItem(stockCheckDetail?.id ?? 0, itemToSave)
        .then(() => {
          setModifiedItems(new Set());
          notifyParentFormChanged(new Set());
          handleAutosaveMessage(true);
        })
        .catch(err => {
          logError(err);
        });
    }
    else {
      saveStockCheckItemAtLocation(stockCheckDetail?.id ?? 0, parseInt(selectedZoneId), itemToSave)
        .then(() => {
          setModifiedItems(new Set());
          notifyParentFormChanged(new Set());
          handleAutosaveMessage(true);
        })
        .catch(err => {
          logError(err);
        });
    }
  };

  // to create a ref for the child component
  useImperativeHandle(ref, () => ({
    getUpdatedValues: () => ({
      scheduledDate,
      stockCheckItems,
    }),
  }), [scheduledDate, stockCheckItems]);

  const updateItemValues = (item: StockCheckItem, itemId: number | undefined, valueId: number | undefined, newQuantity: number | string | undefined) => {
    if (item.id === itemId) {
      return {
        ...item,
        itemValues: item.itemValues?.map(value => {
          if (value.id === valueId) {
            return {
              ...value,
              countQuantity: newQuantity as number,
            };
          }
          return value;
        }),
      };
    }
    return item;
  };

  const handleQuantityChange = (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => {
    if (/^\d*\.?\d*$/.test(newQuantityString) || newQuantityString === '') {

      setStockCheckItems(prevItems => {
        const updatedItems = prevItems.map(item => updateItemValues(item, itemId, valueId, newQuantityString));
        return updatedItems;
      });
    }
    if (itemId !== undefined && !modifiedItems.has(itemId)) {
      setModifiedItems(prev => {
        const updatedItems = new Set(prev);
        updatedItems.add(itemId); 
        notifyParentFormChanged(updatedItems);
        return updatedItems; 
      });
    }
    handleCloseSaveAlert();
  };

  const handleQuantityBlur = (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => {
    const value = Number(newQuantityString);
    const newQuantity = isNaN(value) ? 0 : value;
    setStockCheckItems(prevItems => {
      const updatedItems = prevItems.map(item => updateItemValues(item, itemId, valueId, newQuantity));
      return updatedItems;
    });
  };


  const itemsGroupedByCategory = (items: StockCheckItem[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const categories: { [key: string]: TempCategoryItem } = {};

    if (items) {
      // Group stock check items into categories to list them for display
      for (const item of items) {
        if (item.category) {

          let categoryKey = item.category;

          categoryKey = categoryKey.trim();
          categoryKey = categoryKey.toUpperCase();
          categoryKey = categoryKey.replace(/[\W]/gi, '');

          if (categories[categoryKey]?.items) {
            categories[categoryKey].items.push(item);
          } else {
            categories[categoryKey] = {
              name: item.category,
              items: [item],
            };
          }
        }
      }
    }
    const sortedCategories = Object.values(categories).sort((a, b) => a.name.localeCompare(b.name));
    return sortedCategories;
  };

  return ( <>
    <Grid container>
      <Grid item
        className='filter-pair-container'
        xs={12}
        sm={5}
        md={3}
        container
        spacing={2}
        sx={{ textAlign: 'left', ml: '0' }}>
        <Grid item
          xs={12}>
          <Typography variant='textLG'>
            {t('createdBy')}
          </Typography>
        </Grid>
        <Grid item
          xs={12}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={stockCheckDetail?.createdBy}
            disabled={true}
            sx={{
              width: '90%', backgroundColor: theme.palette.custom.gray[200],
            }}
          />
        </Grid>

      </Grid>

      {/* Scheduled date section */}
      <Grid item
        className='filter-pair-container'
        xs={12}
        sm={5}
        md={3}
        container
        spacing={2}
        sx={{ textAlign: 'left', ml: '0' }}>
        <Grid item
          xs={12}>
          <Typography variant='textLG'>
            {t('scheduledDate')}
          </Typography>
        </Grid>
        <Grid item
          xs={12}>
          <DatePickerLocalized
            useCalendar = {false} 
            value={scheduledDate}
            translations={{
              clearButtonLabel: t('calendar.clearButtonLabel'),
              todayButtonLabel: t('calendar.todayButtonLabel'),
              cancelButtonLabel: t('calendar.cancelButtonLabel'),
              okButtonLabel: t('calendar.okButtonLabel'), 
              placeholder: t('calendar.placeholder'),
            }}
            onChange={(newValue) => {
              if (newValue) { 
                setScheduledDate(newValue);  
              } 
            }}
          />   
        </Grid>
      </Grid>
      {/* Stock Count Zones */}
      {zonesData && zonesData.length > 0 &&
        <Grid item
          className='filter-pair-container'
          xs={12}
          sm={5}
          md={3}
          container
          spacing={2}
          sx={{ textAlign: 'left', ml: '0' }}>
          <Grid item
            xs={12}>
            <Typography variant='textLG'>
              {t('stockCountZonesDdl')}
            </Typography>
          </Grid>
          <Grid item
            xs={12}>
            <Select
              id="stock-count-zones-ddl"
              sx={{
                width: '90%',
              }}
              value={selectedZoneId}
              onChange={handleSelectedZoneChange}
            >
              <MenuItem value="-1">
                <em>{t('stockCountZonesDdlAll')}</em>
              </MenuItem>
              {zonesData.map((zone) => (
                <MenuItem key={zone.id}
                  value={zone.id}>{zone.name}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>}
      {stockCheckDetail?.status === StockCheckStatus.Counting && <Grid item
        xs={12}
        md={zonesData?.length > 0 ? 2 : 5}
        alignSelf={{ xs: 'center', md: 'end' }}
        textAlign={{ xs: 'center', md: 'end' }}
        sx={{ mt: '20px' }}>

        <OnlineOfflineSnackbar
          onOnline={handleSavingDataUserBackOnline}
        />
      </Grid>}
    </Grid>

    {itemsGroupedByCategory(stockCheckItems).map((category: TempCategoryItem) => (
      <Grid container
        key={category.name}
        className='stockcount-table-container'
        sx={{ border: '1px solid', 
          boxShadow: '0 1px 2px 1px #1018280D',
          paddingTop:'8px', pb:'4px', marginTop:'16px', borderRadius:'12px', borderColor: theme.palette.custom.gray[300] }}>
        <Grid item
          className="stock-count-categories"
          xs={12}
          sx={{ mt: '0px' }}>
          <Box
            key={category.name}
            display='flex'
            flexDirection='column'
            my={4}
            marginTop='0'
            className="category">
            <Box
              display="flex"
              flexDirection="row"
              p={4}
              className="title"
            >
              <Typography
                sx={{ mb:'4px', ml:'8px' }}
              >
                {category.name}
              </Typography>
            </Box>
            <TableContainer component={'div'}
              id={`stock-items-table-container-${category.name}`}
              className={'stockcount-table'}
              key={category.name}>
              <Table
                size="small"
                aria-label="a table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width:'8px', padding:'0' }}></TableCell>
                    <TableCell 
                      align="left"
                    >
                      {t('skuNumber')}
                    </TableCell>
                    <TableCell 
                      align="left"
                      className='category-table-cell'>
                      {t('skuDescription')}
                    </TableCell>
                    <TableCell 
                      align="left"
                    >
                      {t('quantity')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.items.sort((a, b) => a.description?.toLocaleLowerCase().localeCompare(b.description?.toLocaleLowerCase() ?? '') ?? -1)
                    .map((item) => (
                      <TableRow sx={{
                        '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '1px solid',
                        borderBottomColor: theme.palette.custom.gray[200],
                        backgroundColor: item.id && modifiedItems.has(item.id) && !showSavedAlert ? theme.palette.common.white : theme.palette.custom.gray[50],

                      }}
                      tabIndex={0} // Make row focusable
                      onFocus={() => handleItemFocus(item.id ?? 0)} // Handle focus
                      className='item'
                      key={item.id}>
                        <TableCell sx={{ width:'8px', padding:'0' }}></TableCell>
                        <TableCell >
                          {item.itemNumber}
                        </TableCell>
                        <TableCell 
                          className='category-table-cell'>
                          {item.description}
                        </TableCell>
                        {stockCheckDetails?.orderedUoms && stockCheckDetails?.orderedUoms?.length > 0 ? <TableCell className="quantity-column"
                          sx={{ width: '3x', height: '60px' }}>
                          {item.itemValues && (
                            <div className="quantity-fields-wrapper"
                              style={{ display: 'flex', flexWrap: 'wrap', height: '100%', gap: '8px' }}>
                              {stockCheckDetails?.orderedUoms?.map((uom, index) => (
                                <div key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',      
                                    minWidth: '90px',         
                                    justifyContent: 'flex-start', 
                                    gap: '4px',                 

                                  }}>
                                  <StockCountQuantityInput
                                    item={item}
                                    uom={uom} 
                                    stockCheckDetail={stockCheckDetail}
                                    selectedZoneId={selectedZoneId}
                                    allZonesEnabled={allZonesEnabled}
                                    handleQuantityChange={handleQuantityChange}
                                    handleQuantityBlur={handleQuantityBlur}
                                  
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </TableCell> :
                          <TableCell className="quantity-column"
                            sx={{ height: '60px' }}>
                            {
                              item.itemValues &&
                            <div className="quantity-fields-wrapper"
                              style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                              <StockCountQuantityInput item={item}
                                uom={'CS'}
                                stockCheckDetail={stockCheckDetail}
                                selectedZoneId={selectedZoneId}
                                allZonesEnabled={allZonesEnabled}
                                handleQuantityChange={handleQuantityChange}
                                handleQuantityBlur={handleQuantityBlur} />

                              <StockCountQuantityInput item={item}
                                uom={'IP'}
                                stockCheckDetail={stockCheckDetail}
                                selectedZoneId={selectedZoneId}
                                allZonesEnabled={allZonesEnabled}
                                handleQuantityChange={handleQuantityChange}
                                handleQuantityBlur={handleQuantityBlur} />

                              <StockCountQuantityInput item={item}
                                uom={'EA'}
                                stockCheckDetail={stockCheckDetail}
                                selectedZoneId={selectedZoneId}
                                allZonesEnabled={allZonesEnabled}
                                handleQuantityChange={handleQuantityChange}
                                handleQuantityBlur={handleQuantityBlur} />
                            </div>
                            }
                          </TableCell> }
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    ))}
    
    <BarcodeScannerModal isScannerOpen={isScannerModalOpen}
      closeModal={closeModal}

      stockCheckItems={stockCheckItems}
      setStockCheckItems={setStockCheckItems}
      stockCountName={stockCheckDetail?.name}></BarcodeScannerModal>

  </>);
});

StockCountDetails.displayName = 'StockCountDetails';
export default StockCountDetails;

