import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, SvgIcon, TableSortLabel, Tooltip } from '@mui/material';
import { Trash01 } from '../../../assets';
import { StockCheckScheduleDetail } from '../../../app/services/api/generated';
import useNswagClient from '../../../hooks/api/useNswagClient';
import SubmitModal from './ScheduleDeleteModal';
import { useNavigate } from 'react-router-dom'; 
import { UserContext } from '../../../components/shared/useUser';
import { useContext } from 'react';

export default function DenseTable(props: {
  readonly list: StockCheckScheduleDetail[], readonly storeID: string, readonly loadData: () => void, readonly searchTerm: string
}) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { deleteSchedule } = useNswagClient();
  const [page, setPage] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(-1);
  const [data, setData] = React.useState(props.list);
  const rowsPerPage = 10;
  const { hasPermissionTo } = useContext(UserContext);
  //Header sort
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('name'); // Set the default column to be sorted

  const hasRightToEdit: boolean = hasPermissionTo(['StockCountScheduleWrite']);
 
  const isAsc = order === 'asc';

  const handleSort = (property: string) => {
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleSearchChange();
  };

  const filteredData = props.list.filter(x => x.name?.toLowerCase().includes(props.searchTerm.toLowerCase()));

  const sortedData = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const comparator = (a: any, b: any) => {
      if (typeof a[orderBy] === 'string' || a[orderBy] instanceof Date) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (isAsc ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (isAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]) as any;
      }
    };
    return filteredData.sort(comparator);
  };

  const handleSearchChange = () => {
    setData(sortedData());
  };

  React.useEffect(() => handleSearchChange(), [props.searchTerm]);

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const submitButtonHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    event.stopPropagation();
    setDeleteId(id);
    setOpenModal(true);
  };

  const handleConfirmModal = () => {
    deleteSchedule(props.storeID, deleteId).then(props.loadData);
  };

  const maxPages = () => {
    return Math.ceil(data.length / rowsPerPage);
  };

  const visibleRows = () => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  return (
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[200],
    }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={order}
                  onClick={() => handleSort('name')}
                >
                  {t('name')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'isActive'}
                  direction={order}
                  onClick={() => handleSort('isActive')}
                >
                  {t('active')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                {t('remove')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <TableRow
                hover
                key={row.id}
                onClick={() => navigate(`/create-edit-schedule-stock-count/${row.id}`)}
              >
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.isActive ? t('true') : t('false')}
                </TableCell>
                <TableCell>
                  <Tooltip title='Delete stock count schedule'
                    color='dark'
                    placement='top'>
                    <IconButton
                      disabled = {!hasRightToEdit}
                      size='small'
                      onClick={(event) => submitButtonHandler(event, row.id ?? -1)}>
                      <SvgIcon color= {!hasRightToEdit ? 'action' : 'error'}><Trash01></Trash01></SvgIcon>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        maxPages={maxPages()}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>

      <SubmitModal openModal={openModal}
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}></SubmitModal>
    </Box>
  );
}
