import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { X } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { theme } from '.././../../theme';
import { ItemPreview } from '../interfaces/ItemsPreview';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data: ItemPreview[];
  isSubmitting: boolean;
}

const SubmitWasteChecksheetModal = ({ open, onClose, onConfirm, data, isSubmitting }: Props) => {
  const { t } = useTranslation('wasteChecksheet');

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
          {t('create.submitData') }                  
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 10,
          color: () => theme.palette.custom.gray[500],
        }}
      >
        <X />
      </IconButton>
      <DialogContent>
        {
          data && (
            <TableContainer
              component={'div'}
              id='waste-items-modal-container'>
              <Table
                size="small"
                aria-label="a table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '1x' }}
                      align="left">
                      {t('itemPreview.itemNumber') }                  
                    </TableCell>
                    <TableCell sx={{ width: '2x' }}
                      align="left">
                      {t('itemPreview.itemDescription') }                  
                    </TableCell>
                    <TableCell sx={{ width: '3x' }}
                      align="left"
                    >
                      {t('itemPreview.wasteQty') }                  
                    </TableCell>
                    <TableCell sx={{ width: '2x' }}
                      align="left"
                    >
                      {t('itemPreview.wasteReason') }                  
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, itemIndex) => (
                    <TableRow sx={{
                      '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '1px solid',
                      borderBottomColor: theme.palette.custom.gray[200],
                      backgroundColor: theme.palette.custom.gray[50],
                    }}
                    key={item.itemNumber}>
                      <TableCell>
                        {item.itemNumber}
                      </TableCell>
                      <TableCell sx={{ width: '2x' }}>
                        {item.description}
                      </TableCell>
                      <TableCell sx={{ width: '2x' }}>
                        {item.qty}
                      </TableCell>
                      <TableCell sx={{ width: '2x' }}>
                        {item.type}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={onClose}
          autoFocus
        >
          {t('create.cancel')}        
        </Button>
        <Button variant="primary"
          onClick={onConfirm}
          disabled={isSubmitting}
          autoFocus
        >
          {t('create.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitWasteChecksheetModal;