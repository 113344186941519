import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getAppInsightsInfo } from '../../app/services/environment';

const appInfo = getAppInsightsInfo();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appInfo.instrumentationKey,
    enableAutoRouteTracking: false,
  },
});

if(appInfo.instrumentationKey){
  appInsights.loadAppInsights();
}

export default appInsights;