import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Link, TableSortLabel, Tooltip, Typography } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import InfoIcon from '@mui/icons-material/Info';
import { TransactionRecord } from '../types/TransactionRecord';
import SubmitModal from './TransactionsDeleteModal';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import { useContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NoteModal } from './TransactionNoteModal';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import { Minus, Plus } from '../../../assets';
import { theme } from '../../../theme';
import { UserContext } from '../../../components/shared/useUser';
import VoidedTransactionModal from '../VoidedTransactionModal';
import { TransactionType } from '../Enums/TransactionType';
import { TransactionSource } from '../Enums/TransactionSource';

const HeaderTableCell = (props: { dataCol: string, colDisplay: string, sort: boolean, fieldToSort:string|undefined, direction: 'desc'|'asc'|undefined, handleSort: (a: string) => void, align?: 'center' | 'left' }) => {
  return (
    <TableCell
      align={props.align ?? 'left'}
    >
      {props.sort ? <TableSortLabel
        active={props.fieldToSort=== props.dataCol}
        direction={props.direction}
        onClick={() => props.handleSort(props.dataCol)}
      >
        {props.colDisplay}
      </TableSortLabel>
        :
        <>{props.colDisplay}</>}
    </TableCell>
  );
};

const BodyTableCell = (props: { children?: React.ReactNode, align?: 'center' | 'left' }) => {
  return (
    <TableCell
      scope="row"
      sx={{ textAlign: props.align ?? 'left' }}
    >
      {props.children}
    </TableCell>
  );
};

const DenseTable = (props: {
  readonly list: TransactionRecord[],
  readonly reloadData: () => void,
  readonly page: number,
  readonly setPage: (newPage: number) => void,
  readonly totalCount: number,
  readonly fieldToSort?: string,
  readonly setFieldToSort: Dispatch<SetStateAction<string>>,
  readonly sortDirection: 'asc' | 'desc' | undefined,
  readonly setSortDirection: Dispatch<SetStateAction<'asc' | 'desc'>>
}) => {
  const { t } = useTranslation('common');
  const { voidproduct } = useNswagClient();
  const [openModal, setOpenModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(-1);
  const [note, setNote] = useState('');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const [openMaterials, setOpenMaterials] = useState<{ [index: number]: boolean }>({});
  const [showVoidedTransactionModal, setShowVoidedTransactionModal] = useState(false);
  const [errorVoidTransaction, setErrorVoidTransaction] = useState('');
  const { hasPermissionTo } = useContext(UserContext);
  const canVoidTransactions = hasPermissionTo(['TransactionAdmin']) || hasPermissionTo(['TransactionWrite']); 
  const voidableTransactions = [TransactionType.SALE, TransactionType.WASTE, TransactionType.RECEIPT, TransactionType.TRANSFER_IN, TransactionType.TRANSFER_OUT, TransactionType.TRANSFER];


  const isAsc = props.sortDirection === 'asc';
  const BORDER_STYLE = `2px solid ${theme.palette.custom.gray[400]}`;

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  const handleSort = (property: string) => {
    props.setSortDirection(isAsc ? 'desc' : 'asc');
    props.setFieldToSort(property);
  };

  const submitButtonHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    event.stopPropagation();
    setDeleteId(id);
    setOpenModal(true);
  };

  const isTransactionVoidable = (transaction: TransactionRecord) =>{
    const isNonTransactionAdmin = canVoidTransactions && !hasPermissionTo(['TransactionAdmin']);
    const isVoidableTransaction = voidableTransactions.includes(transaction.transactionType as TransactionType) && transaction.source !== TransactionSource.SYSTEM; 
    const isManualReceipt = isVoidableTransaction && transaction.source === TransactionSource.MANUAL && transaction.reason !== 'Purchase Order Adjustment';
    const isStockTransfer = transaction.transactionType === TransactionType.TRANSFER && !transaction.sourceID?.startsWith('M');
    if(transaction.reason === 'Void' || transaction.reason === 'Purchase Order Adjustment' || isStockTransfer)
    {
      return false;
    }
    return isNonTransactionAdmin ? isManualReceipt : (isVoidableTransaction || isManualReceipt || transaction.transactionType === TransactionType.MADE_IN_STORE);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const handleConfirmModal = async () => {
    const transaction = props.list.find(x => x.productID == deleteId);
    if (transaction) {
      const data = {
        id: transaction.productID,
        transactionDay: transaction.transactionDay,
      };

      setErrorVoidTransaction('');
      setShowVoidedTransactionModal(false);

      voidproduct(data).then(() => {
        setErrorVoidTransaction(t('transactions.transactionVoidedSuccessfully'));
        setShowVoidedTransactionModal(true);
        transaction.isVoided = true;
        setOpenModal(false);
      }).catch((error) => {
        const errorMessage = typeof error === 'string' ? error : 'An error occurred';
        setErrorVoidTransaction(errorMessage);
        setShowVoidedTransactionModal(true);
        setOpenModal(false);
      });
    }
  };  

  const handleNoteButtonClick = (note: string) => {
    setNote(note);
    setOpenNoteModal(!openNoteModal);
  };

  const OpenNoteModalRevert = () => {
    setOpenNoteModal(!openNoteModal);
  };

  const maxPages = () => {
    return Math.ceil(props.totalCount / 10);
  };

  const handlePlusButtonClick = (index: number) => {
    // Close all open material sections
    const updatedOpenMaterials: { [index: number]: boolean } = {};
    Object.keys(openMaterials).forEach((key) => {
      updatedOpenMaterials[parseInt(key)] = false;
    });

    // Open the clicked material section
    setOpenMaterials((prevState) => ({
      ...updatedOpenMaterials,
      [index]: !prevState[index],
    }));
  };

  const viewRawTransactionData = (transaction: TransactionRecord) => {

    if (transaction.sourceID === '') {
      return;
    }
    else if (transaction.source === 'pos') {
      return `/store/transactions/source/${transaction.sourceID}/${transaction?.transactioDate?.toISOString().substring(0, 10)}`;
    }
    else if (transaction.transactionType === 'Transfer') {
      if ((transaction?.sourceID?.match(/-/g) || []).length <= 1) {
        const source = transaction?.sourceID?.substring(transaction.sourceID.indexOf('-') + 1);
        return `/store/stock-transfer/${source}`;
      }
    }
    else if (transaction.transactionType === 'Stock Adjustment') {
      if ((transaction?.sourceID?.match(/-/g) || []).length === 1) {
        const source = transaction?.sourceID?.substring(transaction.sourceID.indexOf('-') + 1);
        return `/store/review-stock-count/${source}`;
      }
    }
  };

  const renderTransactionType = (transactionType : string|undefined) => {
    const translationKey = `transactions.types.${transactionType}`;
    const translation = t(translationKey);
    return translation === translationKey ? transactionType : translation;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table size="small"
          aria-label="a table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <HeaderTableCell dataCol='itemNumber'
                colDisplay={t('transactions.sku')}
                sort={true}
                handleSort={handleSort}
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='description'
                colDisplay={t('transactions.itemName')}
                sort={true}
                handleSort={handleSort}
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='type'
                colDisplay={t('transactions.transactionTypes')}
                sort={true}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='date'
                colDisplay={t('transactions.transactionDate')}
                sort={true}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='quantity'
                colDisplay={t('transactions.quantity')}
                sort={false}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='store'
                colDisplay={t('transactions.store')}
                sort={false}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='reason'
                colDisplay={t('transactions.transactionReasons')}
                sort={true}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='source'
                colDisplay={t('transactions.source')}
                sort={true}
                handleSort={handleSort}
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='sourceID'
                colDisplay={t('transactions.sourceID')}
                sort={false}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='costPrice'
                colDisplay={t('transactions.actualCost')}
                sort={true}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              <HeaderTableCell dataCol='note'
                colDisplay={t('transactions.note')}
                align='center'
                sort={true}
                handleSort={handleSort} 
                direction= {props.sortDirection}
                fieldToSort={props.fieldToSort}
              />
              {canVoidTransactions && (
                <HeaderTableCell
                  dataCol="x"
                  colDisplay={t('transactions.void')}
                  align="center"
                  sort={false}
                  handleSort={handleSort}
                  direction={props.sortDirection}
                  fieldToSort={props.fieldToSort}
                />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map((row, index) => (
              <React.Fragment key={row.id?.toString() + '-' + row.SkU}>
                <TableRow
                  hover
                  
                  sx={openMaterials[index ?? -1] ? { backgroundColor: theme.palette.custom.gray[100], borderTop: BORDER_STYLE, borderLeft: BORDER_STYLE, borderRight: BORDER_STYLE } : {}}>
                  <BodyTableCell>
                    <IconButton onClick={(event) => { event.stopPropagation(); handlePlusButtonClick(index ?? 0); }}>
                      {openMaterials[index ?? -1] ? <Minus /> : <Plus />}
                    </IconButton>
                  </BodyTableCell>
                  <BodyTableCell>{row.SkU}</BodyTableCell>
                  <BodyTableCell>{row.itemName}</BodyTableCell>
                  <BodyTableCell>{renderTransactionType(row.transactionType)}</BodyTableCell>
                  <BodyTableCell>{row.transactioDate?.toLocaleString()}</BodyTableCell>
                  <BodyTableCell>{row.quantity}</BodyTableCell>
                  <BodyTableCell>{row.store}</BodyTableCell>
                  <BodyTableCell>{row.reason}</BodyTableCell>
                  <BodyTableCell>{row.source}</BodyTableCell>
                  <BodyTableCell>   
                    <Tooltip title={row.sourceID}>
                      <Link
                        href={viewRawTransactionData(row)}  
                        color="primary"
                        underline="hover"
                        style={{ cursor: 'pointer' }}
                      >{row.sourceID?.split('-')[row.sourceID?.split('-').length - 1]}</Link></Tooltip>
                  </BodyTableCell>
                  <BodyTableCell>
                    <RoundAndFormatValue value={row.actualCost ?? 0}
                      currencyCode={currencyCode} />
                  </BodyTableCell>
                  <BodyTableCell align='center'>
                    {row.note && (
                      <Tooltip title={row.note}>
                        <IconButton onClick={() => handleNoteButtonClick(row.note ?? '')}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </BodyTableCell>
                  {canVoidTransactions && (
                    <BodyTableCell align="center">
                      {isTransactionVoidable(row) && (
                        <Tooltip title={row.isVoided ? t('transactions.transactionAlreadyVoided') : ''}>
                          <span>
                            <Button
                              size="md"
                              variant="primary"
                              disabled={row.isVoided}
                              onClick={(event) => submitButtonHandler(event, row.productID ?? -1)}
                            >
                              {t('transactions.voidTransaction')}
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </BodyTableCell>
                  )}
                </TableRow>
                {openMaterials[index ?? -1] && (
                  <>
                    <TableRow 
                      sx={{
                        borderLeft: openMaterials[index ?? -1] ? BORDER_STYLE : 'none',
                        borderRight: openMaterials[index ?? -1] ? BORDER_STYLE : 'none',
                      }}>
                      <TableCell colSpan={13}> <Typography variant='textLG'
                        ml='60px'>{t('transactions.transactionMaterials')}</Typography></TableCell></TableRow>
                    {row.materials?.map((rowMaterial, index) => (
                      <React.Fragment key={`${row.id}_${index}`}>
                        <TableRow 
                          sx={{
                            borderBottom: index === (row.materials && row.materials.length - 1) ? BORDER_STYLE : 'none',
                            borderLeft: index === (row.materials && row.materials.length - 1)? BORDER_STYLE : 'none',
                            borderRight: index === (row.materials && row.materials.length - 1) ? BORDER_STYLE : 'none',
                          }}>                    
                          <BodyTableCell></BodyTableCell>
                          <BodyTableCell>{rowMaterial.itemNumber}</BodyTableCell>
                          <BodyTableCell>{rowMaterial.itemDescription}</BodyTableCell>
                          <BodyTableCell>{renderTransactionType(row.transactionType)}</BodyTableCell>
                          <BodyTableCell>{row.transactioDate?.toLocaleString()}</BodyTableCell>
                          <BodyTableCell>{rowMaterial.quantity}</BodyTableCell>
                          <BodyTableCell>{row.store}</BodyTableCell>
                          <BodyTableCell>{row.reason}</BodyTableCell>
                          <BodyTableCell>{row.source}</BodyTableCell>
                          <BodyTableCell>
                            <Tooltip title={row.sourceID}>
                              <Box>{row.sourceID?.split('-')[row.sourceID?.split('-').length - 1]}</Box>
                            </Tooltip>
                          </BodyTableCell>
                          <BodyTableCell>
                            <RoundAndFormatValue value={rowMaterial.costPrice ?? 0}
                              currencyCode={currencyCode} />
                          </BodyTableCell>
                          <BodyTableCell align='center'>
                            {rowMaterial.note && (
                              <Tooltip title={rowMaterial.note}>
                                <IconButton onClick={() => handleNoteButtonClick(rowMaterial.note ?? '')}>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </BodyTableCell>
                          <BodyTableCell align='center'></BodyTableCell>
                        </TableRow>
                      </React.Fragment> 
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPagination
        page={props.page}
        setPage={props.setPage}
        maxPages={maxPages()}
        breakpointForChangeDisplay={120000}
      />
      <SubmitModal
        openModal={openModal}
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}
      />
      <NoteModal
        note={note}
        open={openNoteModal}
        onClose={OpenNoteModalRevert}
      />
      {showVoidedTransactionModal && <VoidedTransactionModal message={errorVoidTransaction}/>}
     
    </Box>
  );
};

export default DenseTable;