import { ReactElement, ReactNode } from 'react';
import { File06 } from './../../../../assets';
import { ReportType } from './../../../../enums/ReportType';
import ActualVsTheoryReportForm from './../forms/ActualVsTheoryReportForm';
import StockOnHandReportForm from './../forms/StockOnHandReportForm';
import WasteReportForm from './../forms/WasteReportForm';
import ItemActivityReportForm from './../forms/ItemActivityReportForm';
import TransfersReportForm from './../forms/TransfersReportForm';
import ExtendedStockCountReportForm from './../forms/ExtendedStockCountReportForm';
import StockCountSummaryReportForm from './../forms/StockCountSummaryReportForm';
import ItemTimeoutReportForm from './../forms/ItemTimeoutReportForm';
import CostOfSalesReportForm from './../forms/CostOfSalesReportForm';
import StockDaysReportForm from './../forms/StockDaysReportForm';
import InvoiceReportForm from './../forms/InvoiceReportForm';
import MarginReportForm from './../forms/MarginReportForm';
import CreditPurchaseReportForm from './../forms/CreditPurchaseReportForm';
import StockCountVsUsageReportForm from './../forms/StockCountVsUsageReportForm';
import DcShortageReportForm from './../forms/DcShortageReportForm';
import ConstrainedItemReportForm from './../forms/ConstrainedItemReportForm';
import ItemStockOutReportForm from './../forms/ItemStockOutReportForm';
import ReceiptsReportForm from './../forms/ReceiptsReportForm';
import MadeInStoreReportForm from './../forms/MadeInStoreReportForm';
import CogsReportForm from './../forms/CogsReportForm';
import SalesCheckReportForm from './../forms/SalesCheckReportForm';
import ItemUsageReportForm from '../forms/ItemUsageReportForm';
import ForecastOrdersReportForm from '../forms/ForecastOrdersReportForm';
import ForecastPerformanceReportForm from '../forms/ForecastPerformanceReportForm';

export interface ReportNavItem {
    title: ReportType,
    icon: ReactNode,
    info: string,
    form: ReactElement
}

export const ReportTypesData: ReportNavItem[] = [
  {
    title: ReportType.ActualVsTheory,
    icon: <File06 />,
    info: 'reporting.actual-theory-report.pre-generation-message',
    form: <ActualVsTheoryReportForm />,
  },
  {
    title: ReportType.StockCountVsUsage,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <StockCountVsUsageReportForm />,
  },
  {
    title: ReportType.Transfers,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <TransfersReportForm />,
  },
  {
    title: ReportType.StockOnHand,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message-one-date',
    form: <StockOnHandReportForm />,
  },
  {
    title: ReportType.Waste,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <WasteReportForm />,
  },
  {
    title: ReportType.ItemActivity,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ItemActivityReportForm />,
  },
  {
    title: ReportType.ItemTimeout,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ItemTimeoutReportForm />,
  },
  {
    title: ReportType.CostOfSales,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message-no-date',
    form: <CostOfSalesReportForm />,
  },
  {
    title: ReportType.StockDays,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message-no-date',
    form: <StockDaysReportForm />,
  },
  {
    title: ReportType.Invoice,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <InvoiceReportForm />,
  },
  {
    title: ReportType.StockCountSummary,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message-one-date',
    form: <StockCountSummaryReportForm />,
  },
  {
    title: ReportType.Margin,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <MarginReportForm />,
  },
  {
    title: ReportType.CreditPurchase,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <CreditPurchaseReportForm />,
  },
  {
    title: ReportType.ExtendedStockCount,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message-one-date',
    form: <ExtendedStockCountReportForm />,
  },
  {
    title: ReportType.DcShortage,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message-one-date',
    form: <DcShortageReportForm />,
  },
  {
    title: ReportType.ConstrainedItem,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ConstrainedItemReportForm />,
  },
  {
    title: ReportType.ItemStockOut,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ItemStockOutReportForm />,
  },
  {
    title: ReportType.Receipt,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ReceiptsReportForm />,
  },
  {
    title: ReportType.MadeInStore,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <MadeInStoreReportForm />,
  },
  {
    title: ReportType.Cogs,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <CogsReportForm />,
  },
  {
    title: ReportType.SalesCheck,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <SalesCheckReportForm />,
  },
  {
    title: ReportType.ItemUsage,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ItemUsageReportForm />,
  },
];

export const AIOrderingReports = [
  {
    title: ReportType.ForecastPerformance,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ForecastPerformanceReportForm />,
  },
  {
    title: ReportType.ForecastOrders,
    icon: <File06 />,
    info: 'reporting.generic.pre-generation-message',
    form: <ForecastOrdersReportForm />,
  },
];