import { useState, useEffect } from 'react';
import { LocalizationProvider, DatePicker, DateCalendar, DateTimePicker  } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18next';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ro';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const localeMap = {
  'en-GB': 'en-gb',
  'de-DE': 'de',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'ro-RO': 'ro',
  'pl-PL': 'pl',
};

type Language = keyof typeof localeMap;

const setDayjsLocale = (language: Language): string => {
  const dayjsLocale = localeMap[language] ?? 'en-gb';
  dayjs.locale(dayjsLocale);
  return dayjsLocale;
};

const setDayjsLocaleFromLocalStorage = (): string => {
  const storedLanguage = (localStorage.getItem('i18nextLng') ?? 'en-GB') as Language;
  const dayjsLocale = localeMap[storedLanguage] ?? 'en-gb';
  dayjs.locale(dayjsLocale);
  return dayjsLocale;
};

interface DatePickerLocalizedProps {
  label?: string | null;
  value?: string | null;
  valueForDateCalendar?: Dayjs | null;
  disabled?: boolean,
  disableHighlightToday? : boolean,
  format?: string;
  useCalendar: boolean;
  error?: boolean;  
  helperText?: string;  
  onChange?: (date: string | null) => void;
  onChangeDateCalendar?: (date: Dayjs | null) => void;
  shouldDisableDateCalendar?: (date: Dayjs) => boolean;
  shouldDisableDate?: (date: string) => boolean;
   translations?: {
    clearButtonLabel: string;
    todayButtonLabel: string;
    cancelButtonLabel: string;
    okButtonLabel: string;
    datePickerToolbarTitle?: string; 
    placeholder?: string;
  };
  isTimePicker?: boolean;
  hasTooltip?: boolean;
  tooltipMessage? : string;
}

const DatePickerLocalized: React.FC<DatePickerLocalizedProps> = ({
  label,
  value,
  valueForDateCalendar,
  disabled,
  disableHighlightToday,
  format = 'MM/DD/YYYY',
  useCalendar,
  error,
  helperText,
  onChange,
  shouldDisableDate,
  onChangeDateCalendar,
  shouldDisableDateCalendar,
  translations,
  isTimePicker,
  hasTooltip,
  tooltipMessage,
}) => {
  const [locale, setLocale] = useState<string>(setDayjsLocaleFromLocalStorage()); 
   
  useEffect(() => {
    const currentLanguage = i18n.language as Language;
    setDayjsLocale(currentLanguage);
    setLocale(localeMap[currentLanguage] ?? 'en-gb');
    const handleLanguageChange = (lng: Language) => {
      const newLocale = setDayjsLocale(lng);
      setLocale(newLocale);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const handleDateChange = (newValue: string | null) => { 

    if(onChange)
    {
      onChange(newValue);
    }
  }; 
  const handleDateChangeDateCalendar = (newValue: Dayjs | null) => {
    if(onChangeDateCalendar)
    {
      onChangeDateCalendar(newValue);
    }
  };

  const handleClear = () => { 
    handleDateChange('');
  }; 

  const renderInputLabel = () => {
    if (!label) return null;
    return (
      <>
        {label}
        {hasTooltip && (
          <Tooltip title={tooltipMessage}>
            <InfoIcon fontSize='small'
              sx={{ ml: 2, verticalAlign: 'sub' }} />
          </Tooltip>
        )}
      </>
    );
  };

  const renderDateTimePicker = () => (
    <DateTimePicker
      label={label ? renderInputLabel() : undefined}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => {
        const formattedDate = newValue ? dayjs(newValue).format('MM/DD/YYYY hh:mm A') : '';
        onChange?.(formattedDate);
      }}
      shouldDisableDate={(date) => {
        const dayjsDate = dayjs(date);
        return shouldDisableDate ? shouldDisableDate(dayjsDate.format('YYYY-MM-DD')) : false;
      }}
      format="MM/DD/YYYY hh:mm A"
      sx={{ width: '100%' }}
      slotProps={{
        textField: {
          sx: { width: '100%' }, 
          error: false,
          helperText: '',
          InputLabelProps: { shrink: true },
          InputProps: { placeholder: translations?.placeholder ?? 'mm/dd/yyyy --:-- --' },
        },
        field: { clearable: true, onClear: handleClear },
      }}
      timeSteps={{ minutes: 1 }}
    />
  );
  const renderDatePicker = () => ( 
    <DatePicker
      label={renderInputLabel()}
      value={value ? dayjs(value, 'YYYY-MM-DD') : null}
      onChange={(newValue) => {
        const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
        onChange?.(formattedDate);
      }}
      shouldDisableDate={(date) => {
        const dayjsDate = dayjs(date);
        const dateString = dayjsDate.format('YYYY-MM-DD');
        return shouldDisableDate ? shouldDisableDate(dateString) : false;
      }}
      format={format}
      sx={{ width: '100%' }}
      slotProps={{
        textField: {
          error: error,
          helperText: helperText,
          InputLabelProps: label ? { shrink: true } : {},
          InputProps: { placeholder: translations?.placeholder ?? 'mm/dd/yyyy' }, 
        },
        field: { clearable: true, onClear: handleClear },
      }}
    />
  );

  const renderDateOrTimePicker = () => { 
    if (isTimePicker) {
      return renderDateTimePicker();
    } else {
      return renderDatePicker();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}
      adapterLocale={locale}
      localeText={
        !useCalendar
          ? {
            clearButtonLabel: translations!.clearButtonLabel,
            todayButtonLabel: translations!.todayButtonLabel,
            cancelButtonLabel: translations!.cancelButtonLabel,
            okButtonLabel: translations!.okButtonLabel,
            datePickerToolbarTitle: translations!.datePickerToolbarTitle ?? '',
          }
          : undefined
      }
    >
      {useCalendar ? (
        <DateCalendar
          value={valueForDateCalendar || (value ? dayjs(value) : null)}
          onChange={(newValue) => {
            handleDateChangeDateCalendar(newValue);
          }}
          sx={{
            maxWidth: '100%',
            '& .Mui-selected': {
              borderRadius: '4px', 
              width: '40px',       
              height: '32px',     
              display: 'flex',     
              justifyContent: 'center', 
              alignItems: 'center',     
            },
            '& .MuiPickersDay-root': {
              fontWeight: 'bold',
            },
            '& .Mui-disabled': {
              fontWeight: 'normal',
              color: 'lightgrey',
            },
            '& .MuiYearCalendar-root':{
              width: 'auto',
            },
          }}
          shouldDisableDate={shouldDisableDateCalendar
            ? (date) => shouldDisableDateCalendar(dayjs(date))
            : undefined}
          disabled = {disabled}
          disableHighlightToday = {disableHighlightToday}
          showDaysOutsideCurrentMonth
        />
      ) : renderDateOrTimePicker()
      }
    </LocalizationProvider>
  );
};

export default DatePickerLocalized;
