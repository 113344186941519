import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { ChangeEvent, FormEvent, SyntheticEvent, useContext, useState } from 'react';
import { Store } from '../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { base64ToBlob, exportFile } from '../../../../utils';
import { UserContext } from '../../../../components/shared/useUser';
import useLogError from '../../../../hooks/useLogError';
import { ForecastOrdersReportRequest, client } from './../../../../app/services/api/orderManagementClient';

type IProps = {
  onClose?: () => void;
}

const ForecastOrdersReportForm = (props: IProps) => {
  const { user, selectedStore } = useContext(UserContext);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [checkedAllStores, setCheckedAllStores] = useState<boolean>(false);
  const { logError } = useLogError();
  const { t } = useTranslation('common');

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectedStores([]);
    setCheckedAllStores(checked);
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const yesterday = today.toISOString().split('T')[0];
    return yesterday;
  };

  const getMinDate = () => {
    const minDate = new Date('1900-01-01').toISOString().split('T')[0];
    return minDate;
  };

  const handleFromDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };

  const handleSelectChange = (event: SyntheticEvent, value: Store[]) => {
    setSelectedStores(value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsGenerating(true);
    setErrorMessage('');
    setSuccessMessage('');

    const storeNumbers = checkedAllStores ? user?.stores?.map(x => x.storeNumber as string) : selectedStores.map(x => x.storeNumber as string);

    const request: ForecastOrdersReportRequest = {
      storeNumbers: storeNumbers ?? [],
      fromDate: fromDate,
      toDate: toDate,
    };

    client.generateForecastOrdersReport(selectedStore?.storeNumber ?? '', request).then((result) => {
      if (!result.success) {
        setErrorMessage(result.message);
        return;
      }
      exportFile(base64ToBlob(result.data.fileData), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', result.data.fileName);
      props.onClose?.();
    }).catch((error) => {
      if (typeof(error) === 'string') {
        setErrorMessage(error);
      }
      logError(error);
    }).finally(() => {
      setIsGenerating(false);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container
        spacing={10}
        sx={{ pt: '10px' }}>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={user?.stores ?? []}
              value={selectedStores}
              onChange={handleSelectChange}
              disabled={checkedAllStores}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedStores.length === 0 && !checkedAllStores}
                  label={t('reporting.select-stores')}
                  placeholder={t('reporting.select-stores')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox
                checked={checkedAllStores}
                onChange={handleSelectAllChange} />}
              label={t('reporting.all-stores')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <TextField
            label={t('reporting.start-date')}
            type="date"
            required
            value={fromDate}
            onChange={handleFromDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: getMinDate(), max: toDate != '' ? toDate : getMaxDate() } }}
            fullWidth
          />
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <TextField
            label={t('reporting.end-date')}
            type="date"
            required
            value={toDate}
            onChange={handleToDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: fromDate != '' ? fromDate : getMinDate(), max: getMaxDate() } }}
            fullWidth
          />
        </Grid>
        {
          errorMessage && 
          <Grid item
            xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        }
        {
          successMessage && 
          <Grid item
            xs={12}>
            <Alert severity="success">{successMessage}</Alert>
          </Grid>
        }
        <Grid item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', columnGap: '10px' }}>
          <Button size='lg'
            variant='secondary' 
            onClick={props.onClose}>{t('cancel')}</Button>
          <Button size='lg'
            disabled={isGenerating}
            type='submit'>{t('reporting.generate')}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ForecastOrdersReportForm;