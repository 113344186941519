export enum TransactionType {
    MADE_IN_STORE = 'Made in Store',
    RECEIPT = 'Receipt',
    RETURN_TO_STOCK = 'Return to Stock',
    RETURN_TO_VENDOR = 'Return to Vendor',
    SALE = 'Sale',
    STOCK_ADJUSTMENT = 'Stock Adjustment',
    TRANSFER = 'Transfer',
    TRANSFER_IN = 'Transfer In',
    TRANSFER_OUT = 'Transfer Out',
    WASTE = 'Waste'
}