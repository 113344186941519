
import  { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface VoidedTransactionModalProps {
  readonly message: string;
}

function VoidedTransactionModal({ message }: VoidedTransactionModalProps) {
  const [open, setOpen] = useState<boolean>(true);
  const { t } = useTranslation('common');
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open}
        onClose={handleClose}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h4 style={{ margin: 0, fontWeight: 'normal' }}>{t('transactions.transactionVoid')}</h4>
            <IconButton aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: '600' }}>{message}</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default VoidedTransactionModal;
