import { useContext, useState } from 'react';
import { DrawerContext, DrawerValues } from '../../contexts/DrawerContext';
import { SelectStoreModal } from './SelectStoreModal';
import { DesktopFooter } from './DesktopFooter';
import { MobileFooter } from './MobileFooter';
import { useNavigate } from 'react-router-dom';
import { getApiConfig } from '../.,/../../../../../app/services/environment';

export const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobile } = useContext(DrawerContext) as DrawerValues;
  const apiConfig = getApiConfig(); 
  const navigate = useNavigate();

  const onLogout = () => {  
    localStorage.clear();
    sessionStorage.clear(); 
    window.location.href =  `${apiConfig.auth0LogoutBaseUrl}?federated&client_id=${apiConfig.auth0ClientId}&returnTo=${window.location.origin}/login`;
  };

  const onStoreSelected = () => {
    setModalOpen(false);
    navigate('/');
  };

  return <>
    {isMobile
      ?
      <MobileFooter onSwitchStore={() => setModalOpen(true)}
        onLogOut={onLogout}/>
      : <DesktopFooter onSwitchStore={() => setModalOpen(true)}
        onLogOut={onLogout}/>
    }
    <SelectStoreModal
      open={modalOpen}
      onConfirm={onStoreSelected}
      onClose={() => setModalOpen(false)}/>
  </>;
};
