import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { MadeInStoreItem, MadeInStoreItemRequest } from '../../../../app/services/api/generated';
import { useContext, useState } from 'react';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import useLogError from '../../../../hooks/useLogError';
import { UserContext } from '../../../../components/shared/useUser';
import { useTranslation } from 'react-i18next';

interface CreateFinishedGoodsItemDialogProps {
  showDialog: boolean;
  madeInStoreItem?: MadeInStoreItem;
  onConfirm: (submittedSuccessfully: boolean) => void;
  onCancel: () => void;
}

const CreateFinishedGoodsItemDialog: React.FC<CreateFinishedGoodsItemDialogProps> = ({ showDialog, madeInStoreItem, onConfirm, onCancel }) => {
  const { updateMadeInStore } = useNswagClient();
  const { logError } = useLogError();
  const { selectedStore } = useContext(UserContext);
  const [ quantity, setQuantity ] = useState<number>();
  const [ loading, setLoading ] = useState(false);
  const { t } = useTranslation('recipe');

  const updateMadeInStoreItem = () => {
    if (madeInStoreItem) {
      setLoading(true);

      const entity: MadeInStoreItemRequest = {
        id: madeInStoreItem.id ?? 0,
        store: selectedStore?.storeNumber ?? '',
        itemNumber: madeInStoreItem.itemNumber ?? '',
        description: madeInStoreItem.description,
        primaryUOM: madeInStoreItem.primaryUom,
        stockOnHandQuantity: madeInStoreItem.stockOnHandQuantity ?? 0,
        maximumQuantity: madeInStoreItem.maximumQuantity ?? 0,
        quantity: quantity ?? 0,
      };
      updateMadeInStore(entity)
        .then(() => {
          onConfirm(true);
        })
        .catch((error) => {
          logError(error);
          onConfirm(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      onClose={onCancel}
      open={showDialog}
      sx={{ p: 5 }}
      maxWidth={'md'}
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        Create Finished Goods Item
      </DialogTitle>
      <DialogContent
        sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
        <Box display='flex'
          flexDirection='row'
          p={4}>
          <Typography variant="textMD">{t('itemNumber')}: </Typography>
          <Typography variant="textMD"
            fontWeight='bold'>{madeInStoreItem?.itemNumber}</Typography>
        </Box>
        <Box display='flex'
          flexDirection='row'
          p={4}>
          <Typography variant="textMD">{t('description')}: </Typography>
          <Typography variant="textMD"
            fontWeight='bold'>{madeInStoreItem?.description}</Typography>
        </Box>
        <FormControl sx={{ p: 4 }}>
          <TextField
            label={t('amountToCreate')}
            type="number"
            required
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            fullWidth
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          size="lg"
          disabled={!quantity || loading}
          onClick={updateMadeInStoreItem}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateFinishedGoodsItemDialog;