import { Box, Drawer as MuiDrawer, Stack } from '@mui/material';
import { useContext } from 'react';
import { Header } from './Header';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { DrawerMobileAppBar } from './DrawerMobileAppBar';
import { SlidingSubMenu } from './SlidingSubMenu';
import { LayeredNavGroup } from './LayeredNavGroup';
import { Footer } from './Footer/Footer';

export const DrawerMain = () => {
  const { appNavItemsToUse, isMobile, open, selectedNavItem, slidingSubMenuWidth } = useContext(DrawerContext) as DrawerValues;
  const drawerWidth = 300;

  return (
    <>
      <DrawerMobileAppBar />
      <MuiDrawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor='left'
        open={open}
        ModalProps={{
          hideBackdrop: true,
        }}
        PaperProps={{ sx: { flexDirection: 'row' } }}
        sx={{
          flexShrink: 0,
          width: {
            md: selectedNavItem
              ? drawerWidth + slidingSubMenuWidth
              : drawerWidth,
          },
          border:'none',
          boxShadow:'none',
          '& .MuiPaper-root': {
            borderRadius: {
              xs: '0 25px 25px 0',
              md: '0',
            },
            border:'none',
            boxShadow:'none',
          },
        }}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width={drawerWidth}
          paddingLeft='12px'
          paddingRight='12px'
        >
          <Header />
          <Box 
            flexGrow={1} 
            flexShrink={1} 
            overflow={'auto'}>
            <LayeredNavGroup navItemList={appNavItemsToUse} />
          </Box>
          <Footer />
        </Box>
        <SlidingSubMenu />
      </MuiDrawer >
    </>);
};
export default DrawerMain;
