import {  SelectChangeEvent, useMediaQuery } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { Item, PrimaryCategoryStockCount, StockCheckCategory, StockCheckItem, User } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { IStockCheckScheduleCategory ,IStockCheckItem , StockCheck } from '../interfaces/StockInterfaces';
import { useNavigate, useBlocker } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../hooks/useLogError';
import { ConfigurationName } from '../../../enums/ConfigurationName';

export const useStockCountCreate = () => {
  //use hooks
  const { t } = useTranslation('createStockCount');
  const { selectedStore } = useContext(UserContext);
  const { getConfiguration, getStoreUsersWithRole, createStockCounts } = useNswagClient();
  const navigate = useNavigate();
  const { logError } = useLogError();

  //consts
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const today = new Date().toISOString().split('T')[0];

  //modal open state
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCategoryDeleteModalOpen, setIsCategoryDeleteModalOpen] = useState(false);

  //snackbar modal state
  const [snackBarDuration, setSnackBarDuration] = useState<number | null>(6000);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');
  
  //other state
  const [categoryData, setCategoryData] = useState<PrimaryCategoryStockCount[]>([]);
  const [itemsData, setItemsData] = useState<Item[]>([]);
  const [categoryId, setCategoryId] = useState<number>();
  const [itemNumber, setItemNumber] = useState<number>();
  const [convertedItems, setConvertedItems] = useState<StockCheckItem[]>([]);
  const [convertedCategories, setConvertedCategories] = useState<StockCheckCategory[]>([]);
  const [hasCreated, setHasCreated] = useState(false);

  //main box values
  const [name, setName] = useState('');
  const [selectedDate, setSelectedDate] = useState<string>(today);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [approvalValue, setApprovalValue] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<number>();
  const [isTimeLocked, setIsTimeLocked] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [isSmartCount, setIsSmartCount] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const hasItems = itemsData.length > 0;
  const hasCategories = categoryData.length > 0;

  const blocker = useBlocker(() => (categoryData.length > 0 || itemsData.length > 0) && !hasCreated);

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  const handleLeavePageModalConfirm = () => {
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };
  
  useEffect(() => {
    getUsers();
    getCurrencyCode();
  }, []);

  useEffect(() => {
    convertAndSetStockCheckItems();
    convertAndSetStockCheckCategories();
  }, [categoryData ,itemsData]);

  const getCurrencyCode = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.CurrencyCode, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      const code =  new Intl.NumberFormat('en', { style: 'currency', currency: res.data?.value }).formatToParts(1)[0].value;
      setCurrencySymbol(code);
    });
  };
  
  const handleSmartCountChange = (event: SelectChangeEvent) => {
    setIsSmartCount(!isSmartCount);
  };

  const handleIsTimeLockedChanged = (event: SelectChangeEvent) => {
    setIsTimeLocked(!isTimeLocked);
  };

  const getUsers = () => {
    if (!selectedStore?.storeNumber) return;

    getStoreUsersWithRole(24, selectedStore.storeNumber).then((response) => {
      if (response?.data) {
        setAllUsers(response.data);
      }
    })
      .catch((error) => {
        logError(error); 
      });
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSliderValue(newValue);
    }
  };

  const handleCategories = (data: PrimaryCategoryStockCount[]) => {
    setCategoryData(data);
    setIsCategoriesModalOpen(false);
  }; 

  const deleteCategories = (data: PrimaryCategoryStockCount[]) => {
    if(data.length > 0){
      setCategoryData(data);
    }
    setCategoryId(undefined);
    setCategoryData(data);
    setIsCategoryDeleteModalOpen(false);
  }; 

  const deleteItems = (data: Item[]) => {
    if(data.length > 0){
      setItemsData(data);
    }
    setItemNumber(undefined);
    setItemsData(data);
    setIsDeleteModalOpen(false);
  }; 

  const deleteCategoryRow = (id :number) => {
    setCategoryId(id);
    setIsCategoryDeleteModalOpen(true);
  }; 

  const deleteItemRow = (id :number) => {
    setItemNumber(id);
    setIsDeleteModalOpen(true);
  }; 

  const handleItems = (data: Item[], ticked : boolean) => {
    const reducedItemData = itemsData.filter(x => !data.some(y => {return y.itemNumber === x.itemNumber;}));
    const items = Array.from(new Set(data.concat(reducedItemData)));
    setItemsData(items);
    setIsItemsModalOpen(ticked);
  }; 

  const handleBulkItems = (data: Item[]) => {
    const reducedItemData = itemsData.filter(x => !data.some(y => {return y.itemNumber === x.itemNumber;}));
    const items = Array.from(new Set(data.concat(reducedItemData)));
    setItemsData(items);
  }; 

  const handleClose = () => {
    setIsCategoriesModalOpen(false);
    setIsItemsModalOpen(false);
    setIsBulkModalOpen(false);
    setIsCategoryDeleteModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleClickOpen = () => {
    setIsCategoriesModalOpen(true);
  };

  const deleteCategory = () => {
    setIsCategoryDeleteModalOpen(true);
  };

  const deleteItem = () => {
    setIsDeleteModalOpen(true);
  };

  const handleClick = () => {
    setIsItemsModalOpen(true);
  };

  const handleClickBulkClick = () => {
    setIsBulkModalOpen(true);
  };

  const convertAndSetStockCheckItems = () => {
    const convertedStockCheckItems = itemsData.map((item: Item) => {
      const stockCheckItem: IStockCheckItem = {
        id:item.id ?? 0,
        stockCheckId :0,
        itemNumber: item.itemNumber ?? '',
        description: item.description ?? '',
        category: item.primaryCategory ?? '',
        primaryUom: item.uom ?? '',
        tolerancePercentage:0,
        toleranceValue :0,
        quantityOnHand: 0, 
        costPriceOnHand:item.costPrice ?? 0,
      };
      return stockCheckItem;
    });
    const items = convertedStockCheckItems;
    setConvertedItems(items);
  };

  const convertAndSetStockCheckCategories = () => {
    const stockCheckCategories = categoryData.map((category: PrimaryCategoryStockCount) => {
      const stockCheckCategory: IStockCheckScheduleCategory = {
        primaryCategoryId: category.id ?? 0,  
        primaryCategoryName: category.primaryCategoryName ?? '', 
      };
      return stockCheckCategory;
    });
    const categories = stockCheckCategories;
    setConvertedCategories(categories);
  };

  const submitStock =  () => {
    const body : StockCheck = {
      id: 0,
      name: name,
      storeNumber: selectedStore?.storeNumber,
      status: 3,
      isSmartCount : isSmartCount,
      plannedCountDate: selectedDate,
      startDate: '',
      lockTillCountDay: isTimeLocked,
      lockTillHour: selectedTime,
      approver: selectedUser,
      tolerancePercentage: sliderValue,
      toleranceValue : approvalValue,
      updateSmartCountItems: false,
      canMergeCount: false,
      canStartCount: false,
      documentDetailsId: undefined,
      unlockTimeUtc: '',
      parentId: undefined,
      createdBy: '',
      endDate: '',
      approvedBy: '',
      approvedDate: '',
      countedBy: '',
      items: convertedItems,
      categories: convertedCategories,
    };

    setHasCreated(true);
    setIsSubmitting(true);
    setSnackBarProps('info', 'Saving Stock Count');
    createStockCounts(body)
      .then(() => {
        setSnackBarProps('success', 'Stock Count Saved');
        navigate('/store/stock-count');
      })
      .catch((error) => {
        setSnackBarProps('error', `${t('errorMessage')}: ${error}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  
  const handleInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setApprovalValue(newQuantity);
    }else{
      setApprovalValue(0);
    }
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedTime = hour; 
      options.push({
        value: formattedTime,
        label: `${String(hour).padStart(2, '0')}:00`,
      });
    }
    return options;
  };

  const handleSelectChange = (event: SelectChangeEvent< number>) => {
    setSelectedTime(event.target.value as number | undefined );
  };

  const handleUserSelect = (event: SelectChangeEvent< string>) => {
    setSelectedUser(event.target.value);
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleLinkClick = () => {
    navigate('/store/stock-count');
  };

  const handleValueChange = (id: number, newValue: string) => {   
    let newNum = Number(newValue) || 0;
    newNum = newNum < 0 ? 0 : newNum;

    const updatedItems = convertedItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          toleranceValue: newNum, 
        };
      }
      return item;
    });
    setConvertedItems(updatedItems);
  };

  const handleQtyChange = (id: number, newValue: string) => {
    let newNum = Number(newValue) || 0;
    newNum = newNum < 0 ? 0 : newNum;

    const updatedItems = convertedItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          tolerancePercentage: newNum, 
        };
      }
      return item;
    });
    setConvertedItems(updatedItems);
  };

  const setSnackBarProps = (severity: 'error' | 'warning' | 'info' | 'success', message: string) => {
    severity === 'success' ? setSnackBarDuration(6000) : setSnackBarDuration(null);
    setIsSnackBarOpen(true);
    setSnackBarSeverity(severity);
    setSnackbarMessage(message);
  };

  const closeSnackBar = () => {
    setIsSnackBarOpen(false);
  };

  return {
    t,
    isCategoriesModalOpen,
    isItemsModalOpen,
    isBulkModalOpen,
    hasCategories,
    isCategoryDeleteModalOpen,
    isDeleteModalOpen,
    categoryId,
    itemNumber,
    hasItems,
    isMobile,
    allUsers,
    handleSmartCountChange,
    handleQtyChange,
    handleValueChange,
    handleLinkClick,
    handleDateChange,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    submitStock,
    handleClickBulkClick,
    handleClick,
    deleteItem,
    deleteCategory,
    handleClickOpen,
    handleClose,
    handleBulkItems,
    handleItems,
    deleteItemRow,
    deleteCategoryRow,
    deleteItems,
    deleteCategories,
    handleCategories,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    selectedDate,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    categoryData,
    convertedItems,
    itemsData,
    closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    isSmartCount,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm,
    isSubmitting,
    currencySymbol,
    setSelectedDate,
  };
};
