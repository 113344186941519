import React, { useEffect, useState, memo, useRef } from 'react';
import { TextField, Box, IconButton, CircularProgress } from '@mui/material';
import { theme } from '../../../theme';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import './OrderForm.css';
import { MagicWand01 } from '../../../assets';
import { OrderFormItem } from '../../../app/services/api/orderManagementClient';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useTranslation } from 'react-i18next';

type SaveStatus = 'idle' | 'saving' | 'saved' | 'error';

interface QuantityCellProps {
  item: OrderFormItem;
  dateIndex: number;
  currentValue: string;
  handleSingleQuantityChange: (
    newQuantity: number,
    sku: string,
    itemId: number,
    dateIndex: number
  ) => void;
  autosaveItem: () => void;
  canSave: boolean;
  isError: boolean;
  helperText: string | null;
  showPrediction?: boolean;
  predictedQuantity?: number;
  onMagicWandClick?: () => void;
  borderColor?: string;
  maxOrderQuantity?: number;
  predictedDemandQuantity?: number;
  safetyStock?: number;
  primaryUom?: string;
}

const QuantityCell: React.FC<QuantityCellProps> = memo(function QuantityCell({
  item,
  dateIndex,
  currentValue,
  handleSingleQuantityChange,
  canSave,
  isError,
  helperText,
  showPrediction,
  predictedQuantity,
  onMagicWandClick,
  borderColor,
  maxOrderQuantity,
  predictedDemandQuantity,
  safetyStock,
  primaryUom,
}) {
  const [localValue, setLocalValue] = useState(currentValue);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>('idle');
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const originalFocusValue = useRef(currentValue);
  const { t } = useTranslation('orderForm');

  useEffect(() => {
    setLocalValue(currentValue);
  }, [currentValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || '0';
    let numeric = Number(value);
    if (numeric < 0) numeric = 0;
    if (value.includes('.')) numeric = Math.round(numeric);

    setLocalValue(numeric.toString());

    if (item.minQuantity != null && numeric < item.minQuantity && numeric > 0) {
      setWarningMessage(`${t('minQuantityAllowedIs')} ${item.minQuantity}`);
    } else if (item.maxQuantity != null && numeric > item.maxQuantity && numeric > 0) {
      setWarningMessage(`${t('maxQuantityAllowedIs')} ${item.maxQuantity}`);
    } else {
      setWarningMessage(null);
    }
  };

  const onBlur = async () => {
    const originalQuantity = Number(originalFocusValue.current);
    const newQuantity = Number(localValue);

    if (newQuantity === originalQuantity) return;

    if (warningMessage) {
      return;
    }

    setSaveStatus('saving');
    try {
      await handleSingleQuantityChange(newQuantity, item.sku, item.id, dateIndex);
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus('idle'), 2000);
    } catch (err) {
      setSaveStatus('error');
    }
  };


  const handleMagicWandClick = () => {
    if (predictedQuantity != null && Number(localValue) !== predictedQuantity) {
      setLocalValue(predictedQuantity.toString());

      if (item.minQuantity != null && predictedQuantity < item.minQuantity) {
        setWarningMessage(`${t('minQuantityAllowedIs')} ${item.minQuantity}`);
      } else if (item.maxQuantity != null && predictedQuantity > item.maxQuantity) {
        setWarningMessage(`${t('maxQuantityAllowedIs')} ${item.maxQuantity}`);
      } else {
        setWarningMessage(null);
        onMagicWandClick?.();
      }

      setSaveStatus('saved');
      setTimeout(() => setSaveStatus('idle'), 2000);
    }
  };

  return (
    <Box
      className="quantity-input-container"
      sx={{
        position: 'relative',
        width: '100%',
        minWidth: 30,
        maxWidth: 120,
      }}
    >
      <TextField
        type="number"
        label={
          showPrediction
            ? t('predictedQuantity', { value: predictedQuantity?.toString() ?? '' })
            : null
        }

        value={localValue}
        onChange={handleInputChange}
        onBlur={onBlur}
        disabled={!canSave}
        error={isError}
        style={{ width: '100%', minWidth: 50, height: 50, textAlign: 'center' }}
        helperText={helperText}
        autoComplete="off"
        onFocus={(e) => {
          e.target.addEventListener('wheel', (evt) => evt.preventDefault(), { passive: false });
          originalFocusValue.current = localValue;
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: borderColor,
            },
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'block',
              opacity: 1,
            },
          },
          '& .MuiInputLabel-root': {
            color: borderColor,
          },
          width: '100%',
          '& input': {
            textAlign: 'center',
            padding: '8.5px 14px',
          },
        }}
        size="small"
      />

      {showPrediction && onMagicWandClick && (
        <Tooltip
          title={
            <span>
              {predictedDemandQuantity != null && (
                <>
                  {t('predictedDemandQuantity', {
                    value: predictedDemandQuantity,
                    uom: item.primaryUom,
                  })}
                  <br />
                </>
              )}
              {maxOrderQuantity != null && (
                <>
                  {t('maxOrderQuantity', { value: maxOrderQuantity, uom: item.primaryUom })}
                  <br />
                </>
              )}
              {safetyStock != null &&
                t('safetyStock', { value: safetyStock, uom: item.primaryUom })}
            </span>
          }
        >
          <IconButton onClick={handleMagicWandClick}>
            <MagicWand01
              color={borderColor ? theme.palette.success[700] : theme.palette.custom.gray[400]}
            />
          </IconButton>
        </Tooltip>
      )}

      {/* Status indicators */}
      {saveStatus === 'saving' && (
        <Box sx={{ position: 'absolute', right: -5, top: -5 }}>
          <CircularProgress size={16} />
        </Box>
      )}
      {saveStatus === 'saved' && (
        <Box sx={{ position: 'absolute', right: -5, top: -5, color: theme.palette.success.main }}>
          <CloudDoneIcon sx={{ fontSize: 16 }} />
        </Box>
      )}
      {saveStatus === 'error' && (
        <Box sx={{ position: 'absolute', right: -5, top: -5, color: theme.palette.error.main }}>
          <ErrorIcon sx={{ fontSize: 16 }} />
        </Box>
      )}
      {/* Warning icon for out-of-range input */}
      {saveStatus === 'idle' && warningMessage && (
        <Tooltip title={warningMessage}>
          <Box sx={{ position: 'absolute', right: -5, top: -5, color: theme.palette.warning.main }}>
            <WarningIcon sx={{ fontSize: 18 }} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
});

export default QuantityCell;
