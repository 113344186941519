import { useContext } from 'react';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Autocomplete, Switch, FormControlLabel } from '@mui/material';
import DatePickerLocalized from '../../../../../components/shared/DatePickerLocalized';

export const CreateTransactionsTopForms = () => {
  const { transactionTypesToShow,
    handleTypeChange,
    handleSearchTermChange,
    handleItemSelect,
    dropDownItems,
    transactionType,
    createDate, 
    value,
    searchTerm,
    itemsToCreate,
    isItemsLoading,
    isDisableActions,
    handleRecentActivity,
    hasRecentActivity,
    setCreateDate,
    setHasNewChanges,
    t } = useContext(CreateTransactionsContext) as CreateTransactionsValues;

  return (
    <>
      <Grid item
        xs={6}>
        <FormControl fullWidth
          size='small'>
          <InputLabel id="transaction-types-label">{t('transactionTypes')}</InputLabel>
          <Select
            labelId="transaction-types-label"
            id="transaction-types-select"
            disabled={isDisableActions}
            value={transactionType?.id ?? ''}
            label={t('transactionTypes')}
            onChange={handleTypeChange}
          >
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
            {transactionTypesToShow().map((type) => {
              return <MenuItem key={type.id}
                value={type.id}>{type.displayName}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid><Grid item
        xs={6}>
        <FormControl fullWidth
          size='small'> 
          <DatePickerLocalized
            useCalendar={false}
            isTimePicker={true}
            label={t('Date')}
            value={new Date(createDate.getTime() - createDate.getTimezoneOffset() * 60000).toISOString().slice(0, 16)}
            translations={{
              clearButtonLabel: t('calendar.clearButtonLabel'),
              todayButtonLabel: t('calendar.todayButtonLabel'),
              cancelButtonLabel: t('calendar.cancelButtonLabel'),
              okButtonLabel: t('calendar.okButtonLabel'),
              placeholder: t('calendar.placeholder'),
            }}
            onChange={(newValue) => { 
              if (newValue) {
                const selectedDateTime = new Date(newValue);
                const today = new Date();
                if (selectedDateTime > today) { 
                  return;
                }
                if (newValue === '') {
                  setCreateDate(new Date());
                }
                else {
                  setCreateDate(new Date(newValue));
                }
                setHasNewChanges(true); 
              } 
            }} 
            disabled={isDisableActions}
   
            shouldDisableDate={(date) => {
              const maxDate = new Date().toISOString().slice(0, 16);
              return   date > maxDate;
            }} 
          /> 
        </FormControl>
      </Grid>
      <Grid item
        xs={12}>
        <Autocomplete
          size='small'
          loading={isItemsLoading}
          value={value}
          disabled={transactionType == undefined || isDisableActions}
          onChange={(event, newValue: string | null | undefined) => {
            if (newValue) {
              handleItemSelect(newValue);
            }
          }}
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            handleSearchTermChange(newInputValue);
          }}
          isOptionEqualToValue={(x, y) => true}

          options={dropDownItems}
          filterOptions={(x) => x}

          getOptionDisabled={(option) => itemsToCreate.some(x => x.itemNumber + ' - ' + x.description == option)}
          id="controllable-states-demo"
          renderInput={(params) => <TextField {...params}
            label={t('itemNumberOrDescription')} />} />
      </Grid>
      <Grid xs= {12}>
        <FormControlLabel
          control={<Switch
            checked={hasRecentActivity}
            onChange={handleRecentActivity}
          />}
          label={t('hasRecentActivity')}
          labelPlacement="start"
        />
      </Grid>
    </>
  );
};

